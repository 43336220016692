// PriceChangeModal.tsx
import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalContent,
  ModalFooter,
  ModalTitle,
  SelectBox,
  InputBox,
  CheckBox
} from "@components";
import { adminAPI, styleUtils } from "@utils";
import moment, { Moment } from "moment";
import "moment-timezone";
import styles from "../ItemPage/style.module.scss";
import { toast } from "react-toastify";
import { COLOR } from "../../utils/styleUtils";
import { IDBProduct } from "@data";

interface FormData {
  promotion_price: number | null;
  item_name: string;
  start_date: string;
  end_date: string;
  limit_count: number | null;
  item_no: number | null;
  is_fixed_event_product_yn: string;
  is_coupon_product_yn: string;
  expired_yn: string;
  discount_rate: number | null;
  default_price: number | null;
  title: string;
}

interface IPriceModalProps {
  isVisible: boolean;
  close: () => void;
  refresh: () => void;
  date?: Moment | Date;
  data?: any; // 수정 시에만 데이터가 있음
  mode: "create" | "edit";
  onItemDetail?: (itemNo: number) => void; // 상품 상세 조회를 위한 콜백 함수 추가
  onDelete?: (id: number) => void; // 삭제 기능을 위한 콜백 함수 추가
}

const INITIAL_FORM_DATA: FormData = {
  promotion_price: null,
  item_name: "",
  start_date: "",
  end_date: "",
  limit_count: null,
  item_no: null,
  is_fixed_event_product_yn: "Y",
  is_coupon_product_yn: "Y",
  expired_yn: "N",
  discount_rate: null,
  default_price: null,
  title: ""
};

type ChangedFields = Partial<FormData>;

const getChangedFields = (
  original: FormData | null | undefined,
  current: FormData
): ChangedFields => {
  if (!original) return current;

  return (Object.keys(current) as Array<keyof FormData>)
    .filter(key => original[key] !== current[key])
    .reduce(
      (acc, key) => ({
        ...acc,
        [key]: current[key]
      }),
      {} as ChangedFields
    );
};

const validateForm = (
  fields: FormData
): { isValid: boolean; errors: string[] } => {
  const errors: string[] = [];

  if (fields.item_no === null) {
    errors.push("상품 번호가 필요합니다.");
  }
  // if (fields.promotion_price === null || fields.promotion_price <= 0) {
  //   errors.push("변경 가격은 0보다 커야 합니다.");
  // }
  if (!fields.start_date) {
    errors.push("시작 날짜가 필요합니다.");
  }
  if (!fields.end_date) {
    errors.push("종료 날짜가 필요합니다.");
  }
  if (
    fields.start_date &&
    fields.end_date &&
    new Date(fields.start_date) >= new Date(fields.end_date)
  ) {
    errors.push("시작 날짜는 종료 날짜보다 이전이어야 합니다.");
  }
  if (
    fields.discount_rate !== null &&
    (fields.discount_rate < 0 || fields.discount_rate > 100)
  ) {
    errors.push("할인율은 0에서 100 사이의 값이어야 합니다.");
  }

  return { isValid: errors.length === 0, errors };
};

export function PriceModal({
  isVisible,
  close,
  refresh,
  date,
  data,
  mode,
  onItemDetail
}: IPriceModalProps) {
  const [formData, setFormData] = useState<FormData>(INITIAL_FORM_DATA);
  const [itemList, setItemList] = useState<IDBProduct[]>([]);
  const [itemNo, setItemNo] = useState<string>("");
  const [selectedItem, setSelectedItem] = useState<IDBProduct | null>();

  const isEditMode = mode === "edit";

  async function getItemList() {
    try {
      const { data, success } = await adminAPI.item.apiGetAllItems({});
      if (success) {
        setItemList(data.result);
      }
    } catch (error) {
      console.error("getItemList error", error);
    }
  }

  async function getItemOne(itemNo: string) {
    try {
      const { data: result, success } = await adminAPI.item.getDetail({
        no: Number(itemNo)
      });
      if (success) {
        setSelectedItem(result);
        return result;
      }
    } catch (error) {
      console.error("getItemOne error", error);
    }
  }

  useEffect(() => {
    getItemList();

    // 모달이 열릴 때 폼 초기화
    if (isVisible) {
      if (isEditMode && data) {
        setFormData({
          promotion_price: data.promotion_price,
          item_name: data.item_name,
          start_date: data.start_date,
          end_date: data.end_date,
          limit_count: data.limit_count,
          item_no: data.item_no,
          is_fixed_event_product_yn: data.is_fixed_event_product_yn,
          is_coupon_product_yn: data.is_coupon_product_yn,
          expired_yn: data.expired_yn,
          discount_rate: data.discount_rate,
          default_price: data.default_price,
          title: data.title || ""
        });
        setItemNo(data.item_no.toString());

        // 편집 모드에서 아이템 상세 정보 새로고침
        if (data.item_no) {
          getItemOne(data.item_no.toString());
        }
      } else {
        setFormData({
          ...INITIAL_FORM_DATA,
          start_date: date?.toISOString().slice(0, 16) || ""
        });
        setItemNo("");
        setSelectedItem(null);
      }
    } else {
      // 모달이 닫힐 때 아이템 상태 초기화
      setSelectedItem(null);
    }
  }, [isVisible, data, mode, date]);

  // 아이템 번호 변경 시 아이템 정보 새로고침
  useEffect(() => {
    if (itemNo) {
      getItemOne(itemNo).then(value => {
        if (value && value.item_name) {
          if (value.no) {
            setFormData(prev => ({
              ...prev,
              item_no: Number(value.no),
              item_name: value.item_name || prev.item_name
            }));
          }
        }
      });
    } else {
      // 아이템 번호가 없는 경우 선택된 아이템 초기화
      setSelectedItem(null);
    }
  }, [itemNo]);

  // 할인율 변경 시 변동 가격 자동 계산
  useEffect(() => {
    if (
      selectedItem &&
      formData.discount_rate !== null &&
      formData.discount_rate >= 0 &&
      formData.discount_rate <= 100
    ) {
      // 표시가에 할인율을 적용한 가격 계산
      const viewPrice = selectedItem.view_price
        ? Number(selectedItem.view_price)
        : 0;
      const discountedPrice =
        Math.floor((viewPrice * (100 - formData.discount_rate)) / 100 / 10) *
        10;

      // 계산된 가격으로 변동 가격 업데이트
      setFormData(prev => ({
        ...prev,
        promotion_price: discountedPrice
      }));
    }
  }, [formData.discount_rate, selectedItem]);

  const handleChange =
    (key: keyof FormData) => (value: string | number | null) => {
      setFormData(prev => ({
        ...prev,
        [key]: value
      }));
    };

  const handleSave = async () => {
    try {
      const { isValid, errors } = validateForm(formData);
      if (!isValid) {
        toast.error(errors.join(" "));
        return;
      }

      // 날짜 포맷 변경
      const formattedStartDate = moment(formData.start_date)
        .utc()
        .format("YYYY-MM-DD HH:mm:ss");
      const formattedEndDate = moment(formData.end_date)
        .utc()
        .format("YYYY-MM-DD HH:mm:ss");

      const dataToSave = {
        ...formData,
        start_date: formattedStartDate,
        end_date: formattedEndDate
      };

      if (isEditMode) {
        const changedFields = getChangedFields(data, dataToSave);
        if (Object.keys(changedFields).length === 0) {
          close();
          return;
        }

        console.log(changedFields);

        const result = await adminAPI.promotion.updatePromotion(
          data.no,
          changedFields
        );

        if (result.success) {
          toast.success("성공적으로 수정되었습니다.");
          refresh();
          close();
        }
      } else {
        const result = await adminAPI.promotion.createPromotion(dataToSave);

        if (result.success) {
          toast.success("성공적으로 등록되었습니다.");
          refresh();
          close();
        }
      }
    } catch (error) {
      toast.error("저장 중 오류가 발생했습니다.");
      console.error("Save error:", error);
    }
  };

  const handleDelete = async () => {
    if (isEditMode && data && data.no) {
      if (window.confirm("정말로 삭제하시겠습니까?")) {
        try {
          await adminAPI.promotion.deletePromotion(data.no);
          toast.success("성공적으로 삭제되었습니다.");
          refresh();
          close();
        } catch (error) {
          toast.error("삭제 중 오류가 발생했습니다.");
          console.error("Delete error:", error);
        }
      }
    }
  };

  const hasChanges = () => {
    if (!isEditMode) return true;
    const changedFields = getChangedFields(data, formData);
    return Object.keys(changedFields).length > 0;
  };

  return (
    <Modal width={"500px"} isVisible={isVisible} close={close}>
      <ModalTitle
        title={isEditMode ? data?.item.item_name : "새로운 가격 변동"}
        isClose
        close={close}
      />

      <ModalContent>
        <>
          {isEditMode && (
            <div className="row">
              <div className="col title">상태</div>
              <div className="col">
                <div>
                  {(() => {
                    // 현재 시간이 프로모션 기간 내에 있는지 체크
                    const now = moment();
                    const startDate = moment(formData.start_date);
                    const endDate = moment(formData.end_date);

                    // expired_yn이 'Y'면 종료됨
                    if (formData.expired_yn === "Y") {
                      return (
                        <span className="px-1.5 py-0.5 bg-red-100 text-red-700 text-xs rounded">
                          종료됨
                        </span>
                      );
                    }

                    // 시작일이 미래인 경우 '대기중'
                    if (now.isBefore(startDate)) {
                      return (
                        <span className="px-1.5 py-0.5 text-white text-xs rounded bg-blue-500">
                          대기중
                        </span>
                      );
                    }

                    // 현재 시간이 프로모션 기간 내에 있는 경우 '진행중'
                    if (now.isBetween(startDate, endDate, null, "[]")) {
                      return (
                        <span className="px-1.5 py-0.5 text-white text-xs rounded bg-green">
                          진행중
                        </span>
                      );
                    }

                    // 그 외에는 종료됨
                    return (
                      <span className="px-1.5 py-0.5 bg-red-100 text-red-700 text-xs rounded">
                        종료됨
                      </span>
                    );
                  })()}
                </div>
              </div>
            </div>
          )}
        </>

        <div className="row">
          <div className="col title">시작일</div>
          <div className="col">
            <InputBox
              value={formData.start_date || ""}
              onValue={handleChange("start_date")}
              placeholder={"날짜를 선택해주세요"}
              type={"datetime-local"}
            />
          </div>
        </div>

        <div className="row">
          <div className="col title">종료일</div>
          <div className="col">
            <InputBox
              type="datetime-local"
              value={formData.end_date}
              onValue={handleChange("end_date")}
              placeholder="종료일"
            />
          </div>
        </div>
        <div className={"row"}>
          <div className={"col title"}>노출 상품</div>
          <div className={"col"}>
            {isEditMode ? (
              <InputBox
                value={data?.item?.item_name || formData.item_name}
                disabled
                placeholder="상품을 선택해주세요."
              />
            ) : (
              <div
                style={{ display: "flex", alignItems: "center", gap: "15px" }}
              >
                <div style={{ flex: "1", minWidth: "300px" }}>
                  <SelectBox
                    list={itemList
                      .filter(item => item.item_name && item.no)
                      .map(item => ({
                        label: item.item_name as string,
                        value: item.no ? item.no.toString() : ""
                      }))}
                    value={itemNo}
                    singleSelect={setItemNo}
                    placeholder={"상품을 선택해주세요"}
                  />
                </div>
                {formData.item_no && onItemDetail && (
                  <Button
                    text="조회"
                    onClick={() =>
                      formData.item_no && onItemDetail(formData.item_no)
                    }
                    backgroundColor={styleUtils.COLOR.baige}
                    fontColor={styleUtils.COLOR.black}
                    paddingVertical={1}
                    paddingHorizontal={8}
                    fontSize={12}
                  />
                )}
              </div>
            )}
          </div>
        </div>

        {selectedItem ? (
          <>
            <div className={"row"}>
              <div className={"col title"}>이미지</div>
              <div className={"col"}>
                <img src={selectedItem.item_img} className={styles.logoImg} />
              </div>
            </div>
            <div className={"row"}>
              <div className={"col title"}>현재 표시가</div>
              <div className={"col"}>{selectedItem.view_price}</div>
            </div>
            <div className={"row"}>
              <div className={"col title"}>현재 행사가</div>
              <div className={"col"}>{selectedItem.promotion_price}</div>
            </div>
            <div className={"row"}>
              <div className={"col title"}>현재 재고</div>
              <div className={"col"}>{selectedItem.stock}</div>
            </div>
            <div className="row">
              <div className="col title">원복예정 금액</div>
              <div className={"col"}>{formData.default_price}</div>
            </div>
          </>
        ) : (
          <></>
        )}

        <div className="row">
          <div className="col title">프로모션 타이틀</div>
          <div className="col">
            <InputBox
              value={formData.title}
              onValue={handleChange("title")}
              placeholder="프로모션 타이틀을 입력하세요"
            />
          </div>
        </div>

        <div className={"row"}>
          <div className={"col title"}>변동 가격</div>
          <div className={"col content"}>
            <InputBox
              value={formData.promotion_price?.toString() || ""}
              disabled={!isEditMode && !selectedItem}
              onValue={value =>
                handleChange("promotion_price")(
                  value === "" ? null : Number(value)
                )
              }
              placeholder={"가격을 입력해주세요"}
              type={"number"}
              useEmptyValue={true}
            />
          </div>
        </div>

        <div className={"row"}>
          <div className={"col title"}>할인율 (%)</div>
          <div className={"col content"}>
            <InputBox
              value={formData.discount_rate?.toString() || ""}
              disabled={!isEditMode && !selectedItem}
              onValue={value =>
                handleChange("discount_rate")(
                  value === "" ? null : Number(value)
                )
              }
              placeholder={"할인율을 입력해주세요"}
              type={"number"}
              useEmptyValue={true}
            />
          </div>
        </div>

        <div className="row">
          <div className="col title">한정 수량</div>
          <div className="col">
            <InputBox
              type="number"
              value={formData.limit_count?.toString() || ""}
              onValue={value =>
                handleChange("limit_count")(value === "" ? null : Number(value))
              }
              placeholder="수량"
              useEmptyValue={true}
            />
          </div>
        </div>

        <div className="row">
          <div className="col title">대체상품 적용여부</div>
          <div className="col">
            <div>
              <CheckBox
                checked={formData.is_fixed_event_product_yn === "Y"}
                text={formData.is_fixed_event_product_yn === "Y" ? "O" : "X"}
                isToggle
                onChange={e =>
                  setFormData(prev => ({
                    ...prev,
                    is_fixed_event_product_yn:
                      prev.is_fixed_event_product_yn === "N" ? "Y" : "N"
                  }))
                }
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col title">쿠폰상품 적용여부</div>
          <div className="col">
            <div>
              <CheckBox
                checked={formData.is_coupon_product_yn === "Y"}
                text={formData.is_coupon_product_yn === "Y" ? "O" : "X"}
                isToggle
                onChange={e =>
                  setFormData(prev => ({
                    ...prev,
                    is_coupon_product_yn:
                      prev.is_coupon_product_yn === "N" ? "Y" : "N"
                  }))
                }
              />
            </div>
          </div>
        </div>
      </ModalContent>

      <ModalFooter>
        <>
          {isEditMode && (
            <>
              <Button
                text="삭제"
                onClick={handleDelete}
                backgroundColor={COLOR.danger}
                fontColor={COLOR.white}
                marginHorizontal={10}
              />
              <Button
                text={formData.expired_yn === "Y" ? "상태 복구" : "만료 처리"}
                onClick={() => {
                  setFormData(prev => ({
                    ...prev,
                    expired_yn: prev.expired_yn === "Y" ? "N" : "Y"
                  }));
                }}
                backgroundColor={
                  formData.expired_yn === "Y" ? COLOR.success : COLOR.gray
                }
                fontColor={COLOR.white}
                marginHorizontal={10}
              />
            </>
          )}
          <Button
            text={isEditMode ? "수정" : "등록"}
            onClick={handleSave}
            disabled={!hasChanges()}
            backgroundColor={COLOR.success}
            fontColor={COLOR.white}
          />
        </>
      </ModalFooter>
    </Modal>
  );
}
