import {Button, FileUploadButton, InputBox, TextAreaBox} from "@components";
import {states} from "@recoils";
import {adminAPI, styleUtils} from "@utils";
import {IProductDetailNotice} from "@data";
import _ from "lodash";
import React, {useEffect, useState} from "react";
import {useSetRecoilState} from "recoil";
import styles from "../../style.module.scss";

interface IProps {
  item_no: number;
  detailData: IProductDetailNotice;
  refresh?: () => void;
}

export function NoticeItem({detailData, refresh, item_no}: IProps) {
  const setAlertModal = useSetRecoilState(states.alertState);
  const [data, setData] = useState<IProductDetailNotice>({
    type: "notice",
    data_json: {
      title: "",
      data: [],
    },
  });

  async function save() {
    try {
      const params = {
        ...data,
        data_json: {...data.data_json},
        item_no,
      };
      const {success, message} = detailData.no
        ? await adminAPI.item.updateItemDetail({...params, no: detailData.no})
        : await adminAPI.item.createItemDetail(params);

      if (success) {
        refresh?.();
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다.",
        });
      }
    } catch (error) {
      console.error("save error", error);
    }
  }

  useEffect(() => {
    setData({...detailData});
  }, [detailData]);

  return (
    <>
      <div style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}>
        <div className="row">
          <div className="col flex-end">
            <div>
              <Button
                backgroundColor={styleUtils.COLOR.success}
                fontColor={styleUtils.COLOR.white}
                text="저장"
                onClick={save}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col title">타이틀</div>
          <div className="col">
            <InputBox
              value={data.data_json?.title}
              onValue={value =>
                setData({
                  ...data,
                  data_json: {...data.data_json, title: value},
                })
              }
            />
          </div>
        </div>
        {(data.data_json?.data || []).map((item, index) => (
          <div key={index}>
            <div className="row">
              <div className="col title" style={{fontWeight: "400"}}>
                내용
              </div>
              <div className="col">
                <TextAreaBox
                  value={item}
                  onValue={value =>
                    setData({
                      ...data,
                      data_json: {
                        ...data.data_json,
                        data: (data.data_json?.data || []).map((_item, j) =>
                          index === j ? value : _item,
                        ),
                      },
                    })
                  }
                  height={120}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}
