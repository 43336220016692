import { IDeeplinkType, TDeliveryEntranceType, TDeliveryType } from "@data";

export const MOBILE_WIDTH = 768;
export const ZOOTOPIA_WEB_URL = "https://zootopia.co.kr";
export const ZOOTOPIA_LINK_URL = "https://link.zootopia.shop";
export const ZOOTOPIA_APP_LINK = "https://deeplink.zootopia.shop";
export const ADMIN_PASSWORD = "zootopia1212";

export const deliveryTypeList: TDeliveryType[] = [
  "새벽",
  /* '당일', */
  "일반",
  "도서산간"
];

export const deeplinkArr: IDeeplinkType[] = [
  { value: "product", label: "상품 상세", requireno: true },
  { value: "banner", label: "이벤트 상세", requireno: true },
  { value: "coupon", label: "쿠폰함", requireno: false },
  { value: "point", label: "포인트페이지", requireno: false },
  { value: "post", label: "한상차림탭", requireno: false },
  { value: "postDetail", label: "한상차림상세", requireno: true },
  { value: "noticeDetail", label: "공지사항", requireno: true },
  { value: "orderList", label: "주문내역탭", requireno: false },
  { value: "likeList", label: "찜목록탭", requireno: false },
  { value: "recentList", label: "최근본상품탭", requireno: false },
  { value: "alarm", label: "알림함", requireno: false },
  { value: "cart", label: "장바구니", requireno: false },
  { value: "attendance", label: "출석체크이벤트", requireno: false },
  { value: "categoryList", label: "카테고리탭", requireno: false },
  { value: "blackfriday", label: "블랙프라이데이", requireno: false },
  {
    value: "blackfriday_teaser",
    label: "블랙프라이데이 티징",
    requireno: false
  },
  { value: "LAST2024", label: "연말 프로모션", requireno: false },
  { value: "MEMBERSHIP_OPEN", label: "멤버쉽", requireno: false },
  { value: "COOKED_LULU", label: "화식가이드", requireno: false },
  { value: "NEWYEAR_2025", label: "설프로모션", requireno: false },
  { value: "ADD_PET", label: "아이추가", requireno: false },
  {
    value: "NATIONAL_PUPPY_DAY_TEASER",
    label: "국제 강아지의날 티저",
    requireno: false
  },
  {
    value: "NATIONAL_PUPPY_DAY",
    label: "국제 강아지의날 본편",
    requireno: false
  }
];

export const defaultEntranceList: {
  entrance_type: TDeliveryEntranceType;
  title: string;
  isRequire?: boolean;
}[] = [
  { entrance_type: "password", title: "공동현관 비밀번호", isRequire: true },
  { entrance_type: "free", title: "자유 출입 가능" },
  { entrance_type: "security", title: "경비실 호출" },
  { entrance_type: "etc", title: "기타", isRequire: true }
];

export const GRADE_LIST: { label: string; value: string }[] = [
  { label: "등급선택", value: "" },
  { label: "올리브", value: "1" },
  { label: "시트러스", value: "2" },
  { label: "피오니", value: "3" },
  { label: "라벤더", value: "4" },
  { label: "로즈힙", value: "5" },
  { label: "오션", value: "6" },
  { label: "캐비어", value: "19" }
];
