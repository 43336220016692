import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalContent,
  ModalFooter,
  ModalTitle,
  SelectBox,
  InputBox,
  CheckBox
} from "@components";
import { adminAPI, styleUtils } from "@utils";
import { toast } from "react-toastify";
import { COLOR } from "../../utils/styleUtils";
import styles from "../ItemPage/style.module.scss";
import { IDBProduct } from "@data";
import moment from "moment";

interface FormData {
  promotion_no: number | null;
  item_no: number | null;
  spare_price: number | null;
  spare_limit: number | null;
  start_date: string;
  end_date: string;
  spare_sort_order: number | null;
  active_yn: string;
  discount_rate: number | null;
  item_name?: string;
  is_coupon_product_yn: string;
}

interface ISpareItemModalProps {
  isVisible: boolean;
  close: () => void;
  refresh: () => void;
  mode: "create" | "edit";
  data?: any; // 수정 시에만 데이터가 있음
}

const INITIAL_FORM_DATA: FormData = {
  promotion_no: null,
  item_no: null,
  spare_price: null,
  spare_limit: null,
  start_date: "",
  end_date: "",
  spare_sort_order: null,
  active_yn: "N",
  discount_rate: null,
  item_name: "",
  is_coupon_product_yn: "Y"
};

type ChangedFields = Partial<FormData>;

const getChangedFields = (
  original: FormData | null | undefined,
  current: FormData
): ChangedFields => {
  if (!original) return current;

  return (Object.keys(current) as Array<keyof FormData>)
    .filter(key => original[key] !== current[key])
    .reduce(
      (acc, key) => ({
        ...acc,
        [key]: current[key]
      }),
      {} as ChangedFields
    );
};

const validateForm = (
  fields: FormData
): { isValid: boolean; errors: string[] } => {
  const errors: string[] = [];

  if (fields.item_no === null) {
    errors.push("상품 번호가 필요합니다.");
  }
  if (fields.spare_price === null || fields.spare_price <= 0) {
    errors.push("대체 가격은 0보다 커야 합니다.");
  }
  if (!fields.start_date) {
    errors.push("시작 날짜가 필요합니다.");
  }
  if (!fields.end_date) {
    errors.push("종료 날짜가 필요합니다.");
  }
  if (
    fields.start_date &&
    fields.end_date &&
    new Date(fields.start_date) >= new Date(fields.end_date)
  ) {
    errors.push("시작 날짜는 종료 날짜보다 이전이어야 합니다.");
  }
  if (
    fields.discount_rate !== null &&
    (fields.discount_rate < 0 || fields.discount_rate > 100)
  ) {
    errors.push("할인율은 0에서 100 사이의 값이어야 합니다.");
  }

  return { isValid: errors.length === 0, errors };
};

export function SpareItemModal({
  isVisible,
  close,
  refresh,
  mode,
  data
}: ISpareItemModalProps) {
  const [formData, setFormData] = useState<FormData>(INITIAL_FORM_DATA);
  const [itemList, setItemList] = useState<IDBProduct[]>([]);
  const [itemNo, setItemNo] = useState<string>("");
  const [selectedItem, setSelectedItem] = useState<IDBProduct | null>();

  const isEditMode = mode === "edit";

  async function getItemList() {
    try {
      const { data, success } = await adminAPI.item.apiGetAllItems({});
      if (success) {
        setItemList(data.result);
      }
    } catch (error) {
      console.error("getItemList error", error);
    }
  }

  async function getItemOne(itemNo: string) {
    try {
      const { data: result, success } = await adminAPI.item.getDetail({
        no: Number(itemNo)
      });
      if (success) {
        setSelectedItem(result);
        return result;
      }
    } catch (error) {
      console.error("getItemOne error", error);
    }
  }

  useEffect(() => {
    getItemList();

    // 모달이 열릴 때 폼 초기화
    if (isVisible) {
      if (isEditMode && data) {
        setFormData({
          promotion_no: data.promotion_no,
          item_no: data.item_no,
          spare_price: data.spare_price,
          spare_limit: data.spare_limit,
          start_date: data.start_date,
          end_date: data.end_date,
          spare_sort_order: data.spare_sort_order,
          active_yn: data.active_yn,
          discount_rate: data.discount_rate,
          item_name: data.item_name,
          is_coupon_product_yn: data.is_coupon_product_yn || "Y"
        });
        setItemNo(data.item_no.toString());
        // 수정 모드에서 모달이 열릴 때마다 상품 정보 업데이트
        if (data.item_no) {
          getItemOne(data.item_no.toString());
        }
      } else {
        setFormData({
          ...INITIAL_FORM_DATA,
          start_date: new Date().toISOString().slice(0, 16),
          end_date: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000)
            .toISOString()
            .slice(0, 16)
        });
        setItemNo("");
        setSelectedItem(null);
      }
    }
  }, [isVisible, data, mode]);

  useEffect(() => {
    if (itemNo) {
      getItemOne(itemNo);
    } else {
      setSelectedItem(null);
    }
  }, [itemNo]);

  // 할인율 변경 시 대체 가격 자동 계산
  useEffect(() => {
    if (
      selectedItem &&
      formData.discount_rate !== null &&
      formData.discount_rate >= 0 &&
      formData.discount_rate <= 100
    ) {
      // 표시가에 할인율을 적용한 가격 계산
      const viewPrice = selectedItem.view_price
        ? Number(selectedItem.view_price)
        : 0;
      const discountedPrice =
        Math.floor((viewPrice * (100 - formData.discount_rate)) / 100 / 10) *
        10;

      // 계산된 가격으로 대체 가격 업데이트
      setFormData(prev => ({
        ...prev,
        spare_price: discountedPrice
      }));
    }
  }, [formData.discount_rate, selectedItem]);

  const handleChange =
    (key: keyof FormData) => (value: string | number | null) => {
      setFormData(prev => {
        if (key === "spare_limit" && (value === "" || value === 0)) {
          return {
            ...prev,
            [key]: null
          };
        }
        return {
          ...prev,
          [key]: value
        };
      });
    };

  const handleActiveYnChange = (value: string) => {
    setFormData(prev => ({
      ...prev,
      active_yn: value
    }));
  };

  const handleItemNoChange = (value: string) => {
    setItemNo(value);
    setFormData(prev => ({
      ...prev,
      item_no: value ? Number(value) : null
    }));
  };

  const handleSave = async () => {
    try {
      const { isValid, errors } = validateForm(formData);
      if (!isValid) {
        toast.error(errors.join(" "));
        return;
      }

      // 날짜를 UTC로 변환
      const formattedStartDate = moment(formData.start_date)
        .utc()
        .format("YYYY-MM-DD HH:mm:ss");
      const formattedEndDate = moment(formData.end_date)
        .utc()
        .format("YYYY-MM-DD HH:mm:ss");

      const dataToSave = {
        ...formData,
        start_date: formattedStartDate,
        end_date: formattedEndDate
      };

      if (isEditMode) {
        const changedFields = getChangedFields(data, dataToSave);
        if (Object.keys(changedFields).length === 0) {
          close();
          return;
        }

        const result = await adminAPI.promotion.updateSpareItem(
          data.no,
          changedFields
        );

        if (result.success) {
          toast.success("성공적으로 수정되었습니다.");
          refresh();
          close();
        }
      } else {
        const result = await adminAPI.promotion.createSpareItem(dataToSave);

        if (result.success) {
          toast.success("성공적으로 등록되었습니다.");
          refresh();
          close();
        }
      }
    } catch (error) {
      toast.error("저장 중 오류가 발생했습니다.");
      console.error("Save error:", error);
    }
  };

  const hasChanges = () => {
    if (!isEditMode) return true;
    const changedFields = getChangedFields(data, formData);
    return Object.keys(changedFields).length > 0;
  };

  const handelRemove = (no: number) => {
    if (window.confirm("정말 삭제하시겠습니까?")) {
      adminAPI.promotion.deleteSpareItem(no).then(result => {
        if (result.success) {
          toast.success("삭제되었습니다.");
          refresh();
          close();
        }
      });
    }
  };

  return (
    <Modal width={"500px"} isVisible={isVisible} close={close}>
      <ModalTitle
        title={
          isEditMode
            ? data?.item_name || selectedItem?.item_name
            : "새로운 대체 상품"
        }
        isClose
        close={close}
      />

      <ModalContent>
        <>
          {isEditMode && (
            <div className="row">
              <div className="col title">상태</div>
              <div className="col">
                <div>
                  {formData.active_yn === "Y" ? (
                    <span className="px-1.5 py-0.5 bg-red-100 text-red-700 text-xs rounded">
                      사용됨
                    </span>
                  ) : (
                    <span className="px-1.5 py-0.5 bg-green text-white text-xs rounded">
                      대기중
                    </span>
                  )}
                </div>
              </div>
            </div>
          )}
        </>

        <div className="row">
          <div className="col title">시작일</div>
          <div className="col">
            <InputBox
              type="datetime-local"
              value={formData.start_date || ""}
              onValue={handleChange("start_date")}
              placeholder="시작일"
            />
          </div>
        </div>

        <div className="row">
          <div className="col title">종료일</div>
          <div className="col">
            <InputBox
              type="datetime-local"
              value={formData.end_date || ""}
              onValue={handleChange("end_date")}
              placeholder="종료일"
            />
          </div>
        </div>

        <div className={"row"}>
          <div className={"col title"}>상품</div>
          <div className={"col content"}>
            {isEditMode ? (
              <InputBox
                value={data?.item_name || formData.item_name}
                disabled
                placeholder="상품을 선택해주세요."
              />
            ) : (
              <SelectBox
                list={itemList
                  .filter(item => item.item_name && item.no)
                  .map(item => ({
                    label: item.item_name as string,
                    value: item.no ? item.no.toString() : ""
                  }))}
                value={itemNo}
                singleSelect={handleItemNoChange}
                placeholder={"상품을 선택해주세요"}
              />
            )}
          </div>
        </div>

        {selectedItem ? (
          <>
            <div className={"row"}>
              <div className={"col title"}>이미지</div>
              <div className={"col"}>
                <img src={selectedItem.item_img} className={styles.logoImg} />
              </div>
            </div>
            <div className={"row"}>
              <div className={"col title"}>현재 표시가</div>
              <div className={"col"}>{selectedItem.view_price}</div>
            </div>
            <div className={"row"}>
              <div className={"col title"}>현재 행사가</div>
              <div className={"col"}>{selectedItem.promotion_price}</div>
            </div>
            <div className={"row"}>
              <div className={"col title"}>현재 재고</div>
              <div className={"col"}>{selectedItem.stock}</div>
            </div>
          </>
        ) : (
          <></>
        )}

        <div className="row">
          <div className="col title">노출 상품명</div>
          <div className="col">
            <InputBox
              value={formData.item_name || ""}
              onValue={handleChange("item_name")}
              placeholder="상품명"
            />
          </div>
        </div>

        <div className={"row"}>
          <div className={"col title"}>변동가격</div>
          <div className={"col content"}>
            <InputBox
              value={formData.spare_price?.toString() || ""}
              disabled={!isEditMode && !selectedItem}
              onValue={value =>
                handleChange("spare_price")(value === "" ? null : Number(value))
              }
              placeholder={"가격을 입력해주세요"}
              type={"number"}
              useEmptyValue={true}
            />
          </div>
        </div>

        <div className={"row"}>
          <div className={"col title"}>할인율 (%)</div>
          <div className={"col content"}>
            <InputBox
              value={formData.discount_rate?.toString() || ""}
              disabled={!isEditMode && !selectedItem}
              onValue={value =>
                handleChange("discount_rate")(
                  value === "" ? null : Number(value)
                )
              }
              placeholder={"할인율을 입력해주세요"}
              type={"number"}
              useEmptyValue={true}
            />
          </div>
        </div>

        <div className={"row"}>
          <div className={"col title"}>한정 수량</div>
          <div className={"col content"}>
            <InputBox
              value={formData.spare_limit?.toString() || ""}
              onValue={value =>
                handleChange("spare_limit")(value === "" ? null : Number(value))
              }
              placeholder={"수량 제한 없음"}
              type={"number"}
              useEmptyValue={true}
            />
          </div>
        </div>

        <div className="row">
          <div className="col title">순서</div>
          <div className="col">
            <InputBox
              type="number"
              value={formData.spare_sort_order?.toString() || ""}
              onValue={value =>
                handleChange("spare_sort_order")(
                  value === "" ? null : Number(value)
                )
              }
              placeholder="순서"
              useEmptyValue={true}
            />
          </div>
        </div>

        <div className="row">
          <div className="col title">쿠폰상품 적용여부</div>
          <div className="col">
            <div>
              <CheckBox
                checked={formData.is_coupon_product_yn === "Y"}
                text={formData.is_coupon_product_yn === "Y" ? "O" : "X"}
                isToggle
                onChange={e =>
                  setFormData(prev => ({
                    ...prev,
                    is_coupon_product_yn:
                      prev.is_coupon_product_yn === "N" ? "Y" : "N"
                  }))
                }
              />
            </div>
          </div>
        </div>
      </ModalContent>

      <ModalFooter>
        <Button
          text={isEditMode ? "수정" : "등록"}
          onClick={handleSave}
          disabled={!hasChanges()}
          backgroundColor={COLOR.success}
          fontColor={COLOR.white}
        />
        <Button
          text="삭제"
          backgroundColor={styleUtils.COLOR.danger}
          onClick={() => (isEditMode ? handelRemove(data.no) : undefined)}
          disabled={!isEditMode}
        />
      </ModalFooter>
    </Modal>
  );
}
