export enum COLOR {
  green = "#003824",
  black = "#000000",
  gray = "#bebebe",
  gray1 = "#222222",
  gray2 = "#333333",
  gray3 = "#4f4f4f",
  gray4 = "#828282",
  gray5 = "#bdbdbd",
  gray6 = "#e0e0e0",
  gray7 = "#f2f2f2",
  gray8 = "#f9f9f9",
  grayWarm = "#9b9b94",
  white = "#ffffff",
  ivory = "#fff4e8",

  subBasicColor = "#F9F1DC",
  subPointColor4 = "#EEF3F1",
  lightGreenColor = "#EAF2E3",

  gray7A7A7A = "#7A7A7A",
  gray888888 = "#888888",
  grayE1E1E1 = "#E1E1E1",

  redLight = "#FF8E89",
  blueDark = "#4143DF",
  blue = "#007AFF",
  blueLight = "#EFF4FF",
  yellow = "#F8CF3E",
  ivory2 = "#FFFBF1",
  kakaoColor = "#FFDE00",

  delivery = "#39DF8F",
  red = "#FF3737",
  red400 = "#FF5D5D",
  red500 = "#F24147",
  redDD1616 = "#DD1616",
  redFFF4F4 = "#FFF4F4",
  baige = "#F5EEE8",
  orange = "#FC845E",
  orangeSub = "#FF6A3C",
  orangeSub2 = "#FF6534",

  primary = "#007bff",
  secondary = "#6c757d",
  success = "#28a745",
  danger = "#dc3545",
  warning = "#ffc107",
  info = "#17a2b8",
  excel = "#007bff"
}
