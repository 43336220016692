import React, { useState, useRef } from "react";
import { Modal, Button } from "@components";
import Cropper from "react-cropper";
// CSS 파일 임포트 제거 - 인라인 스타일 사용
// import "cropperjs/dist/cropper.css";
import type { ReactCropperElement } from "react-cropper";
// CSS 직접 임포트 대신 스타일을 컴포넌트에 추가
// import "react-cropper/node_modules/cropperjs/dist/cropper.css";

interface ImageCropModalProps {
  isVisible: boolean;
  imageFile: File | null;
  onCropComplete: (croppedFile: File) => void;
  onClose: () => void;
}

// 크로퍼 스타일을 페이지에 추가하는 함수
const addCropperStyles = () => {
  if (
    typeof document !== "undefined" &&
    !document.getElementById("cropper-styles")
  ) {
    const styleSheet = document.createElement("style");
    styleSheet.id = "cropper-styles";
    styleSheet.textContent = `
      .cropper-container {
        direction: ltr;
        font-size: 0;
        line-height: 0;
        position: relative;
        touch-action: none;
        user-select: none;
      }
      .cropper-container img {
        display: block;
        height: 100%;
        image-orientation: 0deg;
        max-height: none !important;
        max-width: none !important;
        min-height: 0 !important;
        min-width: 0 !important;
        width: 100%;
      }
      .cropper-wrap-box, .cropper-canvas, .cropper-drag-box, .cropper-crop-box, .cropper-modal {
        bottom: 0;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
      }
      .cropper-wrap-box, .cropper-canvas {
        overflow: hidden;
      }
      .cropper-drag-box {
        background-color: #fff;
        opacity: 0;
      }
      .cropper-modal {
        background-color: #000;
        opacity: 0.5;
      }
      .cropper-view-box {
        display: block;
        height: 100%;
        outline: 1px solid #39f;
        outline-color: rgba(51, 153, 255, 0.75);
        overflow: hidden;
        width: 100%;
      }
      .cropper-dashed {
        border: 0 dashed #eee;
        display: block;
        opacity: 0.5;
        position: absolute;
      }
      .cropper-dashed.dashed-h {
        border-bottom-width: 1px;
        border-top-width: 1px;
        height: calc(100% / 3);
        left: 0;
        top: calc(100% / 3);
        width: 100%;
      }
      .cropper-dashed.dashed-v {
        border-left-width: 1px;
        border-right-width: 1px;
        height: 100%;
        left: calc(100% / 3);
        top: 0;
        width: calc(100% / 3);
      }
      .cropper-center {
        display: block;
        height: 0;
        left: 50%;
        opacity: 0.75;
        position: absolute;
        top: 50%;
        width: 0;
      }
      .cropper-center::before, .cropper-center::after {
        background-color: #eee;
        content: ' ';
        display: block;
        position: absolute;
      }
      .cropper-center::before {
        height: 1px;
        left: -3px;
        top: 0;
        width: 7px;
      }
      .cropper-center::after {
        height: 7px;
        left: 0;
        top: -3px;
        width: 1px;
      }
      .cropper-face, .cropper-line, .cropper-point {
        display: block;
        height: 100%;
        opacity: 0.1;
        position: absolute;
        width: 100%;
      }
      .cropper-face {
        background-color: #fff;
        left: 0;
        top: 0;
      }
      .cropper-line {
        background-color: #39f;
      }
      .cropper-line.line-e {
        cursor: ew-resize;
        right: -3px;
        top: 0;
        width: 5px;
      }
      .cropper-line.line-n {
        cursor: ns-resize;
        height: 5px;
        left: 0;
        top: -3px;
      }
      .cropper-line.line-w {
        cursor: ew-resize;
        left: -3px;
        top: 0;
        width: 5px;
      }
      .cropper-line.line-s {
        bottom: -3px;
        cursor: ns-resize;
        height: 5px;
        left: 0;
      }
      .cropper-point {
        background-color: #39f;
        height: 5px;
        opacity: 0.75;
        width: 5px;
      }
      .cropper-point.point-e {
        cursor: ew-resize;
        margin-top: -3px;
        right: -3px;
        top: 50%;
      }
      .cropper-point.point-n {
        cursor: ns-resize;
        left: 50%;
        margin-left: -3px;
        top: -3px;
      }
      .cropper-point.point-w {
        cursor: ew-resize;
        left: -3px;
        margin-top: -3px;
        top: 50%;
      }
      .cropper-point.point-s {
        bottom: -3px;
        cursor: s-resize;
        left: 50%;
        margin-left: -3px;
      }
      .cropper-point.point-ne {
        cursor: nesw-resize;
        right: -3px;
        top: -3px;
      }
      .cropper-point.point-nw {
        cursor: nwse-resize;
        left: -3px;
        top: -3px;
      }
      .cropper-point.point-sw {
        bottom: -3px;
        cursor: nesw-resize;
        left: -3px;
      }
      .cropper-point.point-se {
        bottom: -3px;
        cursor: nwse-resize;
        right: -3px;
      }
      .cropper-point.point-se::before {
        background-color: #39f;
        bottom: -50%;
        content: ' ';
        display: block;
        height: 200%;
        opacity: 0;
        position: absolute;
        right: -50%;
        width: 200%;
      }
      .cropper-invisible {
        opacity: 0;
      }
      .cropper-bg {
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQAQMAAAAlPW0iAAAAA3NCSVQICAjb4U/gAAAABlBMVEXMzMz////TjRV2AAAACXBIWXMAAArrAAAK6wGCiw1aAAAAHHRFWHRTb2Z0d2FyZQBBZG9iZSBGaXJld29ya3MgQ1M26LyyjAAAABFJREFUCJlj+M/AgBVhF/0PAH6/D/HkDxOGAAAAAElFTkSuQmCC');
      }
      .cropper-hide {
        display: block;
        height: 0;
        position: absolute;
        width: 0;
      }
      .cropper-hidden {
        display: none !important;
      }
      .cropper-move {
        cursor: move;
      }
      .cropper-crop {
        cursor: crosshair;
      }
      .cropper-disabled .cropper-drag-box, .cropper-disabled .cropper-face, .cropper-disabled .cropper-line, .cropper-disabled .cropper-point {
        cursor: not-allowed;
      }
    `;
    document.head.appendChild(styleSheet);
  }
};

export const ImageCropModal: React.FC<ImageCropModalProps> = ({
  isVisible,
  imageFile,
  onCropComplete,
  onClose
}) => {
  // 크로퍼 인스턴스를 직접 참조할 수 있도록 ref 변경
  const cropperRef = useRef<ReactCropperElement>(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState<string | null>(null);

  React.useEffect(() => {
    addCropperStyles();
  }, []);

  React.useEffect(() => {
    if (imageFile) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreviewUrl(reader.result as string);
      };
      reader.readAsDataURL(imageFile);
    }
  }, [imageFile]);

  const getCroppedFile = async () => {
    // cropper 인스턴스에 접근하는 방식 수정
    if (cropperRef.current) {
      const cropper = cropperRef.current.cropper;
      if (cropper) {
        const canvas = cropper.getCroppedCanvas();
        if (canvas) {
          canvas.toBlob((blob: Blob | null) => {
            if (blob) {
              const croppedFile = new File(
                [blob],
                imageFile?.name || "cropped-image.jpg",
                {
                  type: imageFile?.type || "image/jpeg",
                  lastModified: new Date().getTime()
                }
              );
              onCropComplete(croppedFile);
            }
          }, imageFile?.type || "image/jpeg");
        }
      }
    }
  };

  return (
    <Modal isVisible={isVisible} close={onClose} width={"800px"}>
      <div className="image-crop-modal-content">
        <h2 style={{ textAlign: "center", marginBottom: "20px" }}>
          이미지 크롭
        </h2>
        {imagePreviewUrl && (
          <div
            style={{ maxWidth: "100%", maxHeight: "500px", margin: "0 auto" }}
          >
            <Cropper
              src={imagePreviewUrl}
              style={{ height: 400, width: "100%" }}
              // Cropper.js options
              initialAspectRatio={16 / 9}
              guides={true}
              viewMode={1}
              responsive={true}
              ref={cropperRef}
              minCropBoxHeight={10}
              minCropBoxWidth={10}
              background={false}
              autoCropArea={1}
              checkOrientation={false}
            />
          </div>
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "10px",
            marginTop: "20px"
          }}
        >
          <Button text="취소" onClick={onClose} />
          <Button text="크롭 완료" onClick={getCroppedFile} />
        </div>
      </div>
    </Modal>
  );
};
