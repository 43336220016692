import React, { useEffect, useState } from "react";
import { Calendar, Content } from "@components";
import { adminAPI } from "@utils";
import moment from "moment";
import { Event as TCalendarEvent, momentLocalizer } from "react-big-calendar";
import { PriceModal } from "./PriceChangeModal";
import { IPromotionResponse } from "./index";
import { ItemDetailModal } from "pages/ItemPage/ItemDetailModal";

interface CalendarPricingViewProps {
  promotions: any[];
  setPromotions: (promotions: any[]) => void;
}

export default function CalendarPricingView({
  promotions,
  setPromotions
}: CalendarPricingViewProps) {
  const [selectedDate, setSelectedDate] = useState<moment.Moment>();
  const [focusMonth, setFocusMonth] = useState<moment.Moment>(
    moment().startOf("month")
  );
  const [eventList, setEventList] = useState<TCalendarEvent[]>([]);
  const localizer = momentLocalizer(moment);
  const [selectedData, setSelectedData] = useState<any>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalMode, setModalMode] = useState<"create" | "edit">("create");
  const [isItemDetailModalVisible, setIsItemDetailModalVisible] =
    useState(false);
  const [itemDetailData, setItemDetailData] = useState<any>();

  async function getList() {
    try {
      const result = (await adminAPI.promotion.getPromotionList({
        month: focusMonth.format("YYYY-MM")
      })) as unknown as IPromotionResponse;

      if (result?.data) {
        setPromotions(result.data.result);

        setEventList(
          result.data.result
            .map((item: any) => ({
              ...item,
              start: new Date(item.start_date),
              end: new Date(item.end_date),
              title: `${item.item_name || item?.item?.item_name} (${
                item.promotion_price
              }원)`
            }))
            .sort((a, b) =>
              b.is_active === a.is_active ? 0 : b.is_active ? 1 : -1
            ) // is_active가 true인 이벤트를 위로 정렬
        );
      }
    } catch (error) {
      console.error("getList error", error);
    }
  }

  // 상품 상세 조회 함수 추가
  async function handleItemDetail(itemNo: number) {
    try {
      const { success, message, data } = await adminAPI.item.getDetail({
        no: itemNo
      });
      if (success) {
        setIsItemDetailModalVisible(true);
        setItemDetailData(data);
      } else {
        console.error("상품 조회 실패:", message);
      }
    } catch (error) {
      console.error("상품 조회 에러:", error);
    }
  }

  useEffect(() => {
    getList();
  }, [focusMonth]);

  return (
    <>
      <Content>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%"
          }}
        >
          <Calendar
            localizer={localizer}
            selectable={true} // 날짜 선택 가능하게 설정
            defaultDate={moment().toDate()}
            views={["month"]}
            startAccessor="start"
            endAccessor="end"
            allDayAccessor={() => true}
            eventList={eventList}
            onNavigate={(newDate, view, action) => {
              if (action === "DATE" && !selectedDate) {
                setSelectedDate(moment(newDate));
                return;
              } else if (
                moment(newDate).format("YYYYMM") !==
                focusMonth?.format("YYYYMM")
              ) {
                setFocusMonth(moment(newDate).startOf("month"));
              }
            }}
            onSelectSlot={slotInfo => {
              setSelectedDate(moment(slotInfo.start));
              setModalMode("create");
              setIsModalVisible(true);
            }}
            onSelectEvent={event => {
              setSelectedDate(moment(event.start));
              setSelectedData(event);
              setModalMode("edit");
              setIsModalVisible(true);
            }}
            eventPropGetter={(event: any) => {
              let backgroundColor = "#3174ad"; // 기본 색상

              // event의 특정 속성에 따라 다른 색상 지정
              if (event.is_active === false) {
                backgroundColor = "#868686";
              } else if (event.is_active === true) {
                backgroundColor = "#6f8bf8";
              }

              return {
                style: {
                  backgroundColor,
                  borderRadius: "0px",
                  opacity: 0.8,
                  color: "white",
                  border: "0px",
                  display: "block"
                }
              };
            }}
          />
        </div>
      </Content>

      <PriceModal
        isVisible={isModalVisible}
        close={() => {
          setIsModalVisible(false);
          setSelectedDate(undefined);
          setSelectedData(undefined);
        }}
        refresh={() => getList()}
        date={selectedDate}
        data={selectedData}
        mode={modalMode}
        onItemDetail={handleItemDetail}
      />

      <ItemDetailModal
        isVisible={isItemDetailModalVisible}
        detailData={itemDetailData}
        close={() => setIsItemDetailModalVisible(false)}
      />
    </>
  );
}
