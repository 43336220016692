import {
  faMagnifyingGlass,
  faSearch,
  faShareFromSquare
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  CheckBox,
  Content,
  ContentLayout,
  InputBox,
  SearchFilter
} from "@components";
import { ApiResponse, IContentResponse, IPage } from "@data";
import React, { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { states } from "@recoils";
import { adminAPI, styleUtils } from "@utils";
import { ContentCreateModal } from "./ContentCreateModal";
import { ContentViewModal } from "./ContentViewModal";
import { ShadListTable } from "../../components/ShadListTable";
import { formatPhoneNumber } from "utils/utils";
import moment from "moment";
import styles from "./style.module.scss";

export default function ContentsPage() {
  const setAlertModal = useSetRecoilState(states.alertState);
  const [searchText, setSearchText] = useState("");
  const [isOpenCreatetModal, setIsOpenCreateModal] = useState(false);
  const [isOpenViewModal, setIsOpenViewModal] = useState<number>();
  const [listData, setListData] = useState<
    ApiResponse<{
      contentList: IContentResponse[];
      page: IPage;
    }>
  >();
  const [templateData, setTemplateData] = useState<{
    template_no?: number;
    member_no_list?: number[];
  }>();

  async function getList({ page = 1 }: { page?: number } = {}) {
    try {
      const data = await adminAPI.content.getContentList({
        page,
        limit: 10,
        searchtxt: searchText,
        searchtype: searchText ? "content" : undefined,
        status: undefined
      });
      if (data.success) {
        setListData(data);
      } else {
        setAlertModal({
          isVisible: true,
          title: "오류가 발생했습니다"
        });
      }
    } catch (error) {
      console.error("getContentList error", error);
    }
  }

  async function updateExpose({ no, expose }: { no: number; expose: string }) {
    const data = await adminAPI.content.updateContentDetail({
      no,
      expose_yn: expose
    });
    getList();
  }

  const handleCopyClipBoard = (text: string) => {
    const $textarea = document.createElement("textarea");
    document.body.appendChild($textarea);
    $textarea.value = text;
    $textarea.select();
    document.execCommand("copy");
    document.body.removeChild($textarea);
    alert("링크가 복사되었습니다.");
  };

  async function getAccessLink({ link, no }: { link: string; no: number }) {
    if (link) {
      handleCopyClipBoard(
        "https://magazine.zootopia.co.kr/addcontents/" + link
      );
    } else {
      const data = await adminAPI.content.getAccessLink({
        no
      });
      if (data.success) {
        handleCopyClipBoard(
          "https://magazine.zootopia.co.kr/addcontents/" + data.data
        );
      }
    }
  }

  useEffect(() => {
    getList();
  }, []);

  return (
    <>
      <ContentLayout title="컨텐츠 관리">
        <SearchFilter
          search={
            <InputBox
              key={6}
              placeholder="검색"
              rightContent={
                <div
                  style={{
                    display: "flex"
                  }}
                  onClick={() => getList()}
                >
                  <FontAwesomeIcon icon={faSearch} />
                </div>
              }
              value={searchText}
              onValue={setSearchText}
              onEnter={getList}
            />
          }
          right={[
            <Button
              key={0}
              text="등록"
              fontSize={14}
              fontWeight={600}
              paddingHorizontal={25}
              backgroundColor={styleUtils.COLOR.white}
              onClick={() => setIsOpenCreateModal(true)}
            />
          ]}
        />
        <Content>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%"
            }}
          >
            <ShadListTable
              data={listData?.data.contentList || []}
              paging={listData?.data.page}
              showExport={true}
              exportFileName="contentList"
              columns={[
                {
                  header: "번호",
                  accessorKey: "no"
                },
                {
                  header: "템플릿명",
                  accessorKey: "template_title"
                },
                {
                  header: "글제목",
                  accessorKey: "title"
                },
                {
                  header: "대상자",
                  accessorKey: "creator_grade",
                  cell: row => (
                    <div
                      className="row"
                      style={{ alignItems: "center", justifyContent: "center" }}
                    >
                      <div>
                        <img
                          src={row.creator_grade_img}
                          style={{
                            width: "30px"
                          }}
                        />
                      </div>
                      <div>
                        <div>{row.creator_name}</div>
                      </div>
                    </div>
                  ),
                  csv: row => row.creator_name
                },
                {
                  header: "전화번호",
                  accessorKey: "creator_phone_number",
                  csv: row => formatPhoneNumber(row.creator_phone_number)
                },
                {
                  header: "공유",
                  accessorKey: "link_code",
                  cell: row => (
                    <div
                      onClick={() => {
                        getAccessLink({ link: row.link_code, no: row.no });
                      }}
                    >
                      <div>
                        <FontAwesomeIcon icon={faShareFromSquare} />
                      </div>
                    </div>
                  ),
                  csv: row =>
                    "https://magazine.zootopia.co.kr/addcontents/" +
                    row.link_code
                },
                {
                  header: "작성상태",
                  accessorKey: "status",
                  cell: row => (
                    <span
                      className={[
                        styles.badge,
                        row.status === "new"
                          ? styles.info
                          : row.status == "editing"
                          ? styles.b_gray3
                          : row.status === "done"
                          ? styles.b_blue
                          : styles.error
                      ].join(" ")}
                    >
                      <div>
                        <div
                          className={
                            row.status === "new"
                              ? styles.gray5
                              : row.status == "editing"
                              ? styles.white
                              : row.status === "done"
                              ? styles.white
                              : styles.error
                          }
                        >
                          {row.status === "new"
                            ? "배정완료"
                            : row.status == "editing"
                            ? "작성중"
                            : row.status === "done"
                            ? "작성완료"
                            : "오류"}
                        </div>
                      </div>
                    </span>
                  )
                },
                {
                  header: "공유일자",
                  accessorKey: "createdAt",
                  cell: row => (
                    <div>{moment(row.createdAt).format("YYYY-MM-DD")}</div>
                  )
                },
                {
                  header: "보기",
                  accessorKey: "null",
                  cell: row => (
                    <div
                      onClick={() => {
                        setIsOpenViewModal(row.no);
                      }}
                    >
                      <div>
                        <FontAwesomeIcon icon={faMagnifyingGlass} />
                      </div>
                    </div>
                  )
                },
                {
                  header: "게시여부",
                  accessorKey: "expose_yn",
                  cell: row => (
                    <CheckBox
                      checked={row.expose_yn === "Y"}
                      text={"노출"}
                      isToggle
                      onChange={e => {
                        updateExpose({
                          no: row.no,
                          expose: row.expose_yn === "Y" ? "N" : "Y"
                        });
                      }}
                    />
                  )
                }
              ]}
              getList={getList}
              className="w-full"
            />
          </div>
        </Content>
      </ContentLayout>
      <ContentCreateModal
        isVisible={isOpenCreatetModal}
        close={() => setIsOpenCreateModal(false)}
        templateData={templateData}
        setTemplateData={setTemplateData}
        refresh={getList}
      />
      <ContentViewModal
        isVisible={!!isOpenViewModal}
        content_no={isOpenViewModal}
        close={() => setIsOpenViewModal(undefined)}
      />
    </>
  );
}
