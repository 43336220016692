import * as React from "react";

import { cn } from "@/utils/shadUtils";

const Table = React.forwardRef<
  HTMLTableElement,
  React.HTMLAttributes<HTMLTableElement>
  // eslint-disable-next-line react/prop-types
>(({ className, ...props }, ref) => (
  <div className="relative w-full max-w-full overflow-y-auto overflow-x-auto">
    <table
      ref={ref}
      className={cn(
        "w-full text-sm text-center border border-gray-300 bg-white mb-4 border-collapse",
        className
      )}
      {...props}
    />
  </div>
));
Table.displayName = "Table";

const TableHeader = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement>
  // eslint-disable-next-line react/prop-types
>(({ className, ...props }, ref) => (
  <thead
    ref={ref}
    className={cn("[&_tr]:border-b-2 [&_tr]:border-gray-300", className)}
    {...props}
  />
));
TableHeader.displayName = "TableHeader";

const TableBody = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement>
  // eslint-disable-next-line react/prop-types
>(({ className, ...props }, ref) => (
  <tbody
    ref={ref}
    className={cn(
      "[&_tr]:border-t [&_tr]:border-gray-300 [&_tr:hover]:bg-gray-100",
      className
    )}
    {...props}
  />
));
TableBody.displayName = "TableBody";

const TableFooter = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement>
  // eslint-disable-next-line react/prop-types
>(({ className, ...props }, ref) => (
  <tfoot
    ref={ref}
    className={cn("font-medium border-t border-gray-300", className)}
    {...props}
  />
));
TableFooter.displayName = "TableFooter";

const TableRow = React.forwardRef<
  HTMLTableRowElement,
  React.HTMLAttributes<HTMLTableRowElement>
  // eslint-disable-next-line react/prop-types
>(({ className, ...props }, ref) => (
  <tr
    ref={ref}
    className={cn(
      "transition-colors data-[state=selected]:bg-gray-200 data-[state=summary]:font-bold data-[state=summary]:bg-gray-200 data-[state=titleContent]:text-center data-[state=titleContent]:font-bold data-[state=titleContent]:bg-gray-200 border-t border-gray-300",
      className
    )}
    {...props}
  />
));
TableRow.displayName = "TableRow";

const TableHead = React.forwardRef<
  HTMLTableCellElement,
  React.ThHTMLAttributes<HTMLTableCellElement>
  // eslint-disable-next-line react/prop-types
>(({ className, ...props }, ref) => (
  <th
    ref={ref}
    className={cn(
      "align-middle font-semibold text-center border border-gray-300 border-x",
      className
    )}
    {...props}
  />
));
TableHead.displayName = "TableHead";

const TableCell = React.forwardRef<
  HTMLTableCellElement,
  React.TdHTMLAttributes<HTMLTableCellElement>
  // eslint-disable-next-line react/prop-types
>(({ className, ...props }, ref) => (
  <td
    ref={ref}
    className={cn(
      "p-2 md:p-3 align-middle border border-gray-300 border-x data-[title]:text-left data-[content]:text-right",
      className
    )}
    {...props}
  />
));
TableCell.displayName = "TableCell";

const TableCaption = React.forwardRef<
  HTMLTableCaptionElement,
  React.HTMLAttributes<HTMLTableCaptionElement>
  // eslint-disable-next-line react/prop-types
>(({ className, ...props }, ref) => (
  <caption
    ref={ref}
    className={cn("mt-4 text-sm text-gray-600", className)}
    {...props}
  />
));
TableCaption.displayName = "TableCaption";

export {
  Table,
  TableHeader,
  TableBody,
  TableFooter,
  TableHead,
  TableRow,
  TableCell,
  TableCaption
};
