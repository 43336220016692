import { useRecoilState } from "recoil";
import { states } from "@recoils";
import { adminAPI, axiosUtil, cookieUtil } from "@utils";

export default function useLogin() {
  const [adminData, setAdminData] = useRecoilState(states.adminDataState);

  async function login({ id, pw }: { id: string; pw: string }) {
    const response = {
      success: false,
      message: ""
    };

    try {
      const { data, success, message } = await adminAPI.admin.apiAdminLogin({
        id,
        pw
      });
      console.log("useLogin login data", data, success, message);
      if (success) {
        cookieUtil.setCookie({
          name: axiosUtil.cookie_token_key,
          value: data.token.accessToken,
          expire: 0.5
        });
        getAdminData();
      } else {
        response.message = message || "오류가 발생했습니다.";
      }
    } catch (error) {
      response.message = "오류가 발생했습니다.";
      console.error("useLogin login error", error);
    }
    return response;
  }

  async function getAdminData() {
    try {
      const { data, success, message } = await adminAPI.admin.apiGetAdminData();
      if (success) {
        cookieUtil.setCookie({
          name: axiosUtil.cookie_token_key,
          value: data.token.accessToken,
          expire: 0.5
        });
        setAdminData(data.result);
      } else {
        throw Error(message);
      }
    } catch (error) {
      logout();
    }
  }

  function logout() {
    cookieUtil.deleteCookie({ name: axiosUtil.cookie_token_key });
    setAdminData(undefined);
  }

  return {
    login,
    logout,
    adminData,
    getAdminData,
    token: cookieUtil.getCookie({ name: axiosUtil.cookie_token_key })
  };
}
