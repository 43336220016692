import React, { useState } from "react";
import { ContentLayout, Tabs } from "@components";
import { IPage } from "@data";

import ListPricingTab from "./ListPricingTab";
import CalendarPricingView from "./CalendarPricingTab";
import { SpareItemsTab } from "./SpareItemsTab";

export interface IPromotionResponse {
  data: {
    result: any[];
    page: IPage;
  };
  success: boolean;
  message?: string;
}

export default function DynamicPricingPage() {
  const [activeTab, setActiveTab] = useState("list"); // 'list' or 'calendar' or 'spare'
  const [promotions, setPromotions] = useState<any[]>([]);
  const [refreshTrigger, setRefreshTrigger] = useState<number>(0);

  const handleRefresh = () => {
    setRefreshTrigger(prev => prev + 1);
  };

  return (
    <ContentLayout title="타임딜관리">
      <Tabs
        tabs={[
          { id: "list", label: "리스트 보기" },
          { id: "spare", label: "대체상품 보기" },
          { id: "calendar", label: "캘린더 보기" }
        ]}
        activeTab={activeTab}
        onTabChange={setActiveTab}
      />

      {activeTab === "list" ? (
        <ListPricingTab promotions={promotions} setPromotions={setPromotions} />
      ) : activeTab === "calendar" ? (
        <CalendarPricingView
          promotions={promotions}
          setPromotions={setPromotions}
        />
      ) : (
        <SpareItemsTab promotions={promotions} refresh={handleRefresh} />
      )}
    </ContentLayout>
  );
}
