import React, { useMemo, useState, useRef, useEffect } from "react";
import {
  ColumnDef,
  useReactTable,
  getCoreRowModel
} from "@tanstack/react-table";
import { IPage } from "@data";

import { Download, Settings } from "lucide-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDown,
  faArrowUp,
  faExchangeAlt,
  faSort
} from "@fortawesome/free-solid-svg-icons";
import { DataTable } from "ui/data-table";
import { Button } from "@/ui/button";
import styles from "../ListTable/style.module.scss"; // ListTable의 스타일 파일 임포트

interface ShadListTableProps<T extends object> {
  data?: T[];
  columns?: {
    header: string;
    accessorKey: Extract<keyof T, string> | string;
    enableSorting?: boolean;
    enableHiding?: boolean;
    cell?: (info: any) => React.ReactNode;
    className?: string;
    csv?: (info: any) => string; // CSV 출력용 포맷팅 함수 (선택 사항)
    csvOnly?: boolean; // CSV에만 포함하고 테이블에는 표시하지 않는 컬럼
    csvExclude?: boolean; // CSV에서 제외하고 테이블에만 표시하는 컬럼
  }[];
  paging?: IPage;
  getList?: ({ page }: { page: number }) => void;
  className?: string;
  children?: React.ReactNode;
  onRowClick?: (row: T) => void;
  showExport?: boolean;
  exportFileName?: string;
  getRowIsActive?: (row: T) => boolean; // 행 활성화 상태 확인 함수 추가
}

export function ShadListTable<T extends object>({
  data = [],
  columns = [],
  paging,
  getList,
  className,
  children,
  onRowClick,
  showExport = false,
  exportFileName = "export",
  getRowIsActive
}: ShadListTableProps<T>) {
  const [open, setOpen] = useState(false);
  const [isPinned, setIsPinned] = useState(false);
  const [visibleColumnIds, setVisibleColumnIds] = useState<string[]>(
    columns.filter(col => !col.csvOnly).map(col => col.accessorKey)
  );

  // 컬럼 가시성 토글 함수
  const toggleColumnVisibility = (columnId: string) => {
    setVisibleColumnIds(prev => {
      if (prev.includes(columnId)) {
        // 이미 표시 중인 컬럼이면 제거 (숨김)
        return prev.filter(id => id !== columnId);
      } else {
        // 숨겨진 컬럼이면 추가 (표시)
        return [...prev, columnId];
      }
    });
  };

  // Convert to TanStack Table column definition (standard mode)
  const tableColumns = useMemo<ColumnDef<T>[]>(() => {
    return columns
      .filter(col => !col.csvOnly)
      .map(col => ({
        id: col.accessorKey, // 명시적으로 id 설정
        accessorKey: col.accessorKey,
        header: ({ column }) => {
          // Default sorting is enabled for all columns unless explicitly disabled
          if (col.enableSorting !== false) {
            return (
              <p
                className="flex items-center justify-center w-full bg-transparent hover:bg-gray-50 cursor-pointer"
                onClick={() =>
                  column.toggleSorting(column.getIsSorted() === "asc")
                }
              >
                <span
                  className={
                    column.getIsSorted()
                      ? "text-green-600 font-bold"
                      : "font-bold"
                  }
                >
                  {col.header}
                </span>
                {column.getIsSorted() && (
                  <FontAwesomeIcon
                    icon={
                      column.getIsSorted() === "asc" ? faArrowUp : faArrowDown
                    }
                    className="ml-1 h-3.5 w-3.5 text-green-600"
                  />
                )}
              </p>
            );
          }
          return col.header;
        },
        // 커스텀 헤더 텍스트를 컬럼 메타데이터에 저장 (컬럼 선택에서 사용)
        meta: {
          ...(col.className ? { className: col.className } : {}),
          header: col.header
        },
        cell: col.cell
          ? ({ row }) => col.cell?.(row.original) // 옵셔널 체이닝 사용
          : ({ row }) => {
              // Handle nested properties using dot notation (e.g., "user.name")
              const keys = col.accessorKey.split(".");
              let value = row.original as any;

              for (const key of keys) {
                value = value?.[key];
                if (value === undefined || value === null) break;
              }

              return value;
            },
        enableSorting: col.enableSorting !== false, // Default to true
        enableHiding: col.enableHiding !== false
      }));
  }, [columns]);

  // 테이블 참조 - 컬럼 가시성 상태를 위한 테이블 인스턴스
  const table = useReactTable({
    data,
    columns: tableColumns,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    state: {
      pagination: paging
        ? {
            pageIndex: (paging.cur || 1) - 1,
            pageSize: paging.limit || 10
          }
        : undefined,
      columnVisibility: Object.fromEntries(
        columns
          .filter(col => !col.csvOnly)
          .map(col => [
            col.accessorKey,
            visibleColumnIds.includes(col.accessorKey)
          ])
      )
    },
    onColumnVisibilityChange: updater => {
      // 테이블의 컬럼 가시성이 변경될 때 visibleColumnIds 상태 업데이트
      const newState =
        typeof updater === "function"
          ? updater(table.getState().columnVisibility)
          : updater;

      const newVisibleColumnIds = Object.entries(newState)
        .filter(([_, isVisible]) => isVisible)
        .map(([columnId]) => columnId);

      setVisibleColumnIds(newVisibleColumnIds);
    }
  });

  // CDN으로 SheetJS 로드 및 Excel 내보내기
  const exportToExcel = () => {
    // 내보낼 컬럼 필터링 - csvExclude가 true인 컬럼은 제외
    const exportColumns = columns.filter(
      col =>
        (col.csvOnly || visibleColumnIds.includes(col.accessorKey)) &&
        !col.csvExclude
    );

    // 헤더 준비
    const headers = exportColumns.map(col => col.header);

    // 데이터 준비
    const excelRows = data.map(item => {
      return exportColumns.map(col => {
        // CSV 함수가 있는 경우 우선 사용
        if (col.csv) {
          return col.csv(item);
        }

        // 점 표기법으로 중첩 속성 접근
        const keys = col.accessorKey.split(".");
        let value = item as any;

        for (const key of keys) {
          value = value?.[key];
          if (value === undefined || value === null) break;
        }

        // 객체나 배열인 경우 JSON 문자열로 변환
        if (value && typeof value === "object") {
          return JSON.stringify(value);
        }

        return value;
      });
    });

    // 헤더와 데이터 합치기
    const excelData = [headers, ...excelRows];

    // CDN을 통해 SheetJS 로드
    const loadSheetJS = () => {
      return new Promise((resolve, reject) => {
        if ((window as any).XLSX) {
          resolve((window as any).XLSX);
          return;
        }

        const script = document.createElement("script");
        script.src =
          "https://cdn.jsdelivr.net/npm/xlsx@0.18.5/dist/xlsx.full.min.js";
        script.async = true;
        script.onload = () => resolve((window as any).XLSX);
        script.onerror = reject;
        document.head.appendChild(script);
      });
    };

    // SheetJS를 로드하고 Excel 파일 생성
    loadSheetJS()
      .then((XLSX: any) => {
        // 워크시트 생성
        const worksheet = XLSX.utils.aoa_to_sheet(excelData);

        // 워크북 생성 및 워크시트 추가
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "데이터");

        // 엑셀 파일 다운로드
        XLSX.writeFile(workbook, `${exportFileName}.xlsx`);
      })
      .catch(error => {
        console.error("SheetJS 로드 실패:", error);
        // 오류 발생 시 대체 방법으로 HTML 테이블 다운로드
        exportToExcelFallback();
      });
  };

  // SheetJS 로드 실패 시 HTML 테이블 방식으로 폴백
  const exportToExcelFallback = () => {
    // 내보낼 컬럼 필터링 - csvExclude가 true인 컬럼은 제외
    const exportColumns = columns.filter(
      col =>
        (col.csvOnly || visibleColumnIds.includes(col.accessorKey)) &&
        !col.csvExclude
    );

    // HTML 테이블 생성
    let tableHtml = "<table><thead><tr>";

    // 헤더 행 생성
    exportColumns.forEach(col => {
      tableHtml += `<th>${col.header}</th>`;
    });
    tableHtml += "</tr></thead><tbody>";

    // 데이터 행 생성
    data.forEach(item => {
      tableHtml += "<tr>";
      exportColumns.forEach(col => {
        let cellValue = "";

        // CSV 함수가 있는 경우 우선 사용
        if (col.csv) {
          cellValue = col.csv(item);
        } else {
          // 점 표기법으로 중첩 속성 접근
          const keys = col.accessorKey.split(".");
          let value = item as any;

          for (const key of keys) {
            value = value?.[key];
            if (value === undefined || value === null) break;
          }

          // 객체나 배열인 경우 JSON 문자열로 변환
          if (value && typeof value === "object") {
            cellValue = JSON.stringify(value);
          } else if (value !== undefined && value !== null) {
            cellValue = String(value);
          }
        }

        // HTML 이스케이프 처리
        const escapedValue = String(cellValue)
          .replace(/&/g, "&amp;")
          .replace(/</g, "&lt;")
          .replace(/>/g, "&gt;")
          .replace(/"/g, "&quot;")
          .replace(/'/g, "&#039;");

        tableHtml += `<td>${escapedValue}</td>`;
      });
      tableHtml += "</tr>";
    });

    tableHtml += "</tbody></table>";

    // Excel로 열 수 있는 HTML 문서 생성
    const html = `
      <html>
        <head>
          <meta charset="UTF-8">
          <style>
            table, th, td {
              border: 1px solid black;
              border-collapse: collapse;
            }
            th, td {
              padding: 5px;
            }
          </style>
        </head>
        <body>
          ${tableHtml}
        </body>
      </html>
    `;

    // Blob 생성 및 다운로드
    const blob = new Blob([html], { type: "application/vnd.ms-excel" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `${exportFileName}.xls`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  // 컬럼 가시성 설정 UI 컴포넌트
  const ColumnVisibilityToggle = () => {
    // State to control the open/closed state of the dropdown

    // 외부 클릭 감지를 위한 ref
    const dropdownRef = useRef<HTMLDivElement>(null);

    // 버튼 클릭 핸들러 - 드롭다운 토글
    const handleButtonClick = () => {
      setOpen(!open);
      // 닫을 때는 고정 상태도 해제
      if (open && isPinned) {
        setIsPinned(false);
      }
    };

    // 외부 클릭 감지
    useEffect(() => {
      function handleClickOutside(event: MouseEvent) {
        if (
          dropdownRef.current &&
          !dropdownRef.current.contains(event.target as Node)
        ) {
          // 고정 상태가 아닐 때만 닫기
          if (!isPinned) {
            setOpen(false);
          }
        }
      }

      // 드롭다운이 열려있을 때만 이벤트 리스너 추가
      if (open) {
        document.addEventListener("mousedown", handleClickOutside);
      }

      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [open, isPinned]);

    // 체크박스 클릭 이벤트 핸들러 - 이벤트 전파 중지 및 상태 변경
    const handleCheckboxClick = (columnId: string, event: React.MouseEvent) => {
      // 이벤트 전파 중지
      event.stopPropagation();
      // 기본 동작 방지
      event.preventDefault();
      // 컬럼 가시성 토글
      toggleColumnVisibility(columnId);
      // 고정 상태일 때는 드롭다운 열린 상태 유지
      // setOpen(false);
    };

    // 고정 상태 토글 핸들러
    const togglePinned = (event: React.MouseEvent) => {
      event.stopPropagation();
      event.preventDefault();
      setIsPinned(!isPinned);
    };

    return (
      <div className={`${styles.columnToggle} relative`} ref={dropdownRef}>
        <Button
          variant="outline"
          size="sm"
          className={`${styles.toggleButton} h-8 gap-1 text-xs`}
          onClick={handleButtonClick}
        >
          <Settings className="h-3 w-3.5" />
          <span>컬럼 설정</span>
        </Button>

        {open && (
          <div
            className={`${styles.dropdownMenu} absolute right-0 mt-1 w-56 rounded-md shadow-lg bg-white z-50 py-1`}
            onClick={e => {
              e.stopPropagation();
            }}
          >
            {/* 고정 토글 버튼 추가 */}

            {columns
              .filter(col => !col.csvOnly)
              .map(column => {
                // 각 컬럼에 대한 체크박스 아이템
                const isVisible = visibleColumnIds.includes(column.accessorKey);

                return (
                  <div
                    key={column.accessorKey}
                    className="capitalize flex items-center px-2 py-1.5 text-sm cursor-pointer hover:bg-gray-100 w-full"
                    onClick={e => {
                      handleCheckboxClick(column.accessorKey, e);
                    }}
                  >
                    <div className="flex items-center gap-2 w-full">
                      <div className="h-4 w-4 border rounded flex items-center justify-center">
                        {isVisible && (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="h-3 w-3"
                          >
                            <polyline points="20 6 9 17 4 12"></polyline>
                          </svg>
                        )}
                      </div>
                      <span>{column.header}</span>
                    </div>
                  </div>
                );
              })}
          </div>
        )}
      </div>
    );
  };

  // Setup pagination props if paging is provided
  const paginationProps = useMemo(() => {
    if (!paging) return undefined;

    return {
      pageIndex: (paging.cur || 1) - 1,
      pageSize: paging.limit || 10,
      pageCount: paging.maxpage || 1,
      onPageChange: (page: number) => {
        if (getList) {
          getList({ page: page + 1 }); // +1 because UI is 0-indexed but API is 1-indexed
        }
      }
    };
  }, [paging, getList]);

  // 필터링된 컬럼 (표시할 컬럼만)
  const filteredColumns = tableColumns.filter(col =>
    visibleColumnIds.includes(col.id as string)
  );

  return (
    <div className={`${styles.listTable} ${className}`}>
      <div className={`${styles.tableHeader} flex justify-between mb-2`}>
        <div className={`${styles.tableTools} flex gap-2`}>
          {showExport && (
            <Button
              variant="outline"
              size="sm"
              onClick={exportToExcel}
              className={`${styles.exportButton} h-8 gap-1 text-xs`}
            >
              <Download className="h-3.5 w-3.5" />
              <span>Excel 다운로드</span>
            </Button>
          )}
          <ColumnVisibilityToggle />
        </div>
        <div></div>
      </div>

      <DataTable
        columns={filteredColumns}
        data={data}
        pagination={paginationProps}
        onRowClick={onRowClick}
        showExport={false}
        getRowIsActive={getRowIsActive}
        className={styles.dataTable}
      />
    </div>
  );
}
