import {
  Content,
  ContentLayout,
  InputBox,
  ListTable,
  SearchFilter,
  SelectBox,
} from "@components";
import { ICohortMonthlyResponse } from "@data";
import { adminAPI } from "@utils";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import styles from "./style.module.scss";
import _ from "lodash";
import { getPercent } from "utils/utils";
import { COLOR } from "utils/styleUtils";

const MONTH_FORMAT = "YYYY-MM";

type FilterOption = "" | "ALL" | "TTOTTO" | "VITAMIN";

export default function CohortMonthlyPage() {
  const [cohortData, setCohortData] = useState<ICohortMonthlyResponse[]>([]);
  const [startMonth, setStartMonth] = useState(
    moment("2023-11-01").format(MONTH_FORMAT),
  );
  const [endMonth, setEndMonth] = useState(moment().format(MONTH_FORMAT));
  const [excludeType, setExcludeType] = useState<FilterOption>("");

  const filterOptions = [
    { value: "", label: "없음"},
    // { value: "ALL", label: "또또우유&영양제" },
    { value: "TOTO", label: "또또우유" },
    { value: "NUTRITION", label: "영양제" },
  ];

  const columns = useMemo(() => {
    return Object.keys(cohortData[0] || {})
      .map(key => key)
      .filter(key => !["signupMonth"].includes(key));
  }, [cohortData]);

  async function getData() {
    try {
      const { success, data } = await adminAPI.kpi.getCohortMonthly({
        startMonth,
        endMonth,
        excludeType,
      });
      if (success) {
        setCohortData(data);
      }
    } catch (error) {
      console.error("getData error", error);
    }
  }

  useEffect(() => {
    getData();
  }, [startMonth, endMonth, excludeType]);

  return (
    <>
      <ContentLayout title="코호트분석">
        <SearchFilter
          left={[
            <div key={0} className={styles.row} style={{ marginBottom: 0 }}>
              <div className={styles.col}>
                <div className={styles.row}>
                  <div className="col">
                    <InputBox
                      type={"month"}
                      placeholder="시작일"
                      value={startMonth}
                      onValue={setStartMonth}
                      onEnter={getData}
                    />
                  </div>
                  <div className="col">~</div>
                  <div className="col">
                    <InputBox
                      type={"month"}
                      placeholder="종료일"
                      value={endMonth}
                      onValue={setEndMonth}
                      onEnter={getData}
                    />
                  </div>
                  <div className="col">
                    <SelectBox
                        list={filterOptions}
                        value={excludeType}
                        singleSelect={(value) => setExcludeType(value as FilterOption)}
                        placeholder="제외 옵션"
                    />
                  </div>
                </div>
              </div>
            </div>,
          ]}
        />
        <Content>
          <ListTable>
            <thead>
              <tr>
                <td>가입 기간(월)</td>
                {columns.map((column, index) => (
                  <td key={column}>
                    {index === 0 ? `구매자수(M${column})` : `M${column}`}
                  </td>
                ))}
              </tr>
            </thead>
            <tbody>
              {cohortData.map(item => {
                const initData = Number(item[columns[0]] || 0);
                return (
                  <>
                    <tr key={_.uniqueId()}>
                      <td rowSpan={2}>{item.signupMonth}</td>
                      {columns.map(column => (
                        <td
                          key={_.uniqueId()}
                          style={{
                            backgroundColor: COLOR.lightGreenColor,
                            textAlign: "right",
                          }}>
                          {Number(item[column] || 0).toLocaleString()}
                        </td>
                      ))}
                    </tr>
                    <tr
                      key={_.uniqueId() + "___"}
                      style={{ fontSize: 12, textAlign: "right" }}>
                      {columns.map((column, i) => {
                        const percent = getPercent({
                          part: Number(item[column] || 0),
                          total: initData,
                          floatNum: 2,
                        });
                        return (
                          <td key={_.uniqueId()}>
                            {i === 0 ? `재구매 비율` : `${percent}%`}
                          </td>
                        );
                      })}
                    </tr>
                  </>
                );
              })}
            </tbody>
          </ListTable>
        </Content>
      </ContentLayout>
    </>
  );
}