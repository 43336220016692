export enum ScreenId {
  Dashboard = "dashboard",
  Kpi = "kpi",
  Partner = "partner",
  OrderLog = "orderLog",
  SmsLog = "smsLog",
  Marketing = "marketing",
  PaymentLog = "paymentLog",
  ItemQnA = "itemQnA",
  MemberQnA = "memberQnA",
  HomeTab = "homeTab",
  Notice = "notice",
  Review = "review",
  Faq = "faq",
  Order = "order",
  Banner = "banner",
  Coupon = "coupon",
  EventPopup = "eventPopup",
  Member = "member",
  Section = "section",
  Attendance = "attendance",
  Item = "item",
  DeliveryDate = "deliveryDate",
  Frequency = "frequency",
  Post = "post",
  ItemPriceChange = "itemPriceChange",
  OrderProfit = "orderProfit",
  MemberOutRequest = "memberOutRequest",
  StockExcelImport = "stockExcelImport",
  ReOrderCountPage = "reOrderCountPage",
  CohortMonthly = "cohortMonthly",
  Grade = "grade",
  DynamicPricing = "dynamicPricing",
  Contents = "contents"
}

export enum APP_NAVIGATE {
  PRODUCT = "product",
  PRODUCT_DETAIL = "productdetail", // same product
  REVIEW_LIST = "reviewList",
  REVIEW_DETAIL = "reviewDetail",
  ORDER_DETAIL = "orderDetail",
  COUPON = "coupon",
  POINT = "point",
  ITEM_QNA = "itemQnA",
  MEMBER_QNA = "memberQnA",
  BANNER = "banner",
  SECTION_DETAIL = "sectionDetail",
  NOTICE_DETAIL = "noticeDetail",
  ORDER_LIST = "orderList",
  ORDER_LIST_SUBSCRIBE = "orderListSubscribe",
  LIKE_LIST = "likeList",
  RECENT_LIST = "recentList",
  ALARM = "alarm",
  MY_REVIEW = "myReview",
  CART = "cart",
  PARTNER = "partner",
  ATTENDANCE = "attendance",
  FREQUENCY = "frequency",
  POST = "post",
  POST_DETAIL = "postDetail",
  LOGIN = "login",
  PHOTO_REVIEW_LIST = "photoReviewList",
  CREATE_DECLARATION_REVIEW = "createDeclarationReview",
  WRITE_REVIEW = "writeReview",
  WRITE_PRODUCT_QNA = "writeProductQnA",
  PRODUCT_QNA_LIST = "productQnaList",
  CATEGORY_LIST = "categoryList"
}
