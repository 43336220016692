import React from "react";
import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalTitle,
  Button,
  InputBox,
  FileUploadButton,
  TextAreaBox,
  CheckBox,
} from "@components";
import styles from "./style.module.scss";
import {IDBHomeTabList} from "@data";

interface IProps {
  isVisible?: boolean;
  data?: IDBHomeTabList;
  setData?: (data?: IDBHomeTabList) => void;
  save?: () => void;
  close?: () => void;
}

export function HomeTabDetailModal({
  isVisible,
  data = {},
  setData,
  save,
  close,
}: IProps) {
  function setClose() {
    setData?.(undefined);
    close?.();
  }

  return (
    <Modal
      isVisible={isVisible}
      className={styles.modal}
      width={"500px"}
      close={setClose}>
      <ModalTitle title="홈탭상세" isClose close={setClose} />
      <ModalContent>
        <div className="row">
          <div className="col title">
            <div className="buttonCol">
              이미지
              <div>
                <FileUploadButton
                  text={"업로드"}
                  height={32}
                  uploadUrl={"/admin/utils/uploadHomeTabImg"}
                  fileUpload={({files}) =>
                    setData?.({...data, thumbnail_img: files?.[0]})
                  }
                />
              </div>
            </div>
          </div>
          <div className="col">
            {!!data?.thumbnail_img && <img src={data?.thumbnail_img} />}
          </div>
        </div>
        <div className="row">
          <div className="col title">탭 타이틀</div>
          <div className="col">
            <InputBox
              value={data?.name}
              onValue={value => setData?.({...data, name: value})}
            />
          </div>
        </div>
        <div className="row top">
          <div className="col title">제목</div>
          <div className="col">
            <div style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}>
              <div className="row">
                <div className="col">
                  <TextAreaBox
                    placeholder="회색 제목"
                    value={data?.gray_title}
                    className={styles.grayTitle}
                    onValue={value => setData?.({...data, gray_title: value})}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <TextAreaBox
                    placeholder="제목"
                    value={data?.title}
                    onValue={value => setData?.({...data, title: value})}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <TextAreaBox
                    placeholder="굵은 제목"
                    value={data?.bold_title}
                    className={styles.boldTitle}
                    onValue={value => setData?.({...data, bold_title: value})}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row top">
          <div className="col title">소제목</div>
          <div className="col">
            <TextAreaBox
              placeholder="소 제목"
              value={data?.sub_title}
              onValue={value => setData?.({...data, sub_title: value})}
            />
          </div>
        </div>
        <div className="row">
          <div className="col title">노출여부</div>
          <div className="col">
            <div>
              <CheckBox
                checked={data?.hide_yn === "N"}
                text={data?.hide_yn === "N" ? "노출" : "숨김"}
                isToggle
                onChange={e =>
                  setData?.({
                    ...data,
                    hide_yn: data?.hide_yn === "N" ? "Y" : "N",
                  })
                }
              />
            </div>
          </div>
        </div>
      </ModalContent>
      <ModalFooter>
        <div></div>
        <div>
          <Button text="저장" onClick={save} />
        </div>
      </ModalFooter>
    </Modal>
  );
}
