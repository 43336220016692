import {
  Button,
  CheckBox,
  Content,
  ContentLayout,
  InputBox,
  ListTable,
  SearchFilter,
  SelectBox
} from "@components";
import {
  faDownload,
  faFileExcel,
  faSearch
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { states } from "@recoils";
import { adminAPI, commonUtil, excelUtil, styleUtils, utils } from "@utils";
import {
  ResultPagingResponse,
  TItemFilterSortType,
  TypeYN,
  IDBPartner,
  IItemDetail,
  IItemList
} from "@data";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { ZOOTOPIA_WEB_URL } from "utils/constant";
import { ItemDetailModal } from "./ItemDetailModal";
import styles from "./style.module.scss";
import FilterSection from "../../components/FilterSection";
import ToggleSelectBox from "../../components/ToggleSelectBox";
import { axiosGetUtil } from "utils/axiosUtil";

export interface IFilterData {
  sort?: TItemFilterSortType;
  partner?: number;
  searchtxt?: string;
}

const LOCAL_STORAGE_KEYS = {
  ITEMS_PER_PAGE: "items_itemsPerPage",
  PARTNERS: "items_partners",
  SORTS: "items_sorts",
  SEARCH_TEXT: "items_search_text",
  CURRENT_PAGE: "items_current_page"
};

const sortArr: { label: string; value: TItemFilterSortType | "" }[] = [
  { label: "바코드순", value: "barcodeASC" },
  // {label: "바코드순(역순)", value: "barcodeDESC"},
  { label: "재고많은순", value: "stockDESC" },
  { label: "재고적은순", value: "stockASC" },
  { label: "등록순", value: "createdAtASC" },
  { label: "등록순(역순)", value: "createdAtDESC" },
  { label: "이름순", value: "itemNameASC" },
  { label: "이름순(역순)", value: "itemNameDESC" },
  { label: "저가순", value: "priceASC" },
  { label: "고가순", value: "priceDESC" }
];

export default function ItemPage() {
  const setAlertModal = useSetRecoilState(states.alertState);
  const [filter, setFilter] = useState<IFilterData>({});
  const [partnerList, setPartnerList] = useState<IDBPartner[]>([]);
  const [listData, setListData] = useState<ResultPagingResponse<IItemList[]>>();
  const [detailData, setDetailData] = useState<IItemDetail>();
  const [isDetailModalVisible, setIsDetailModalVisible] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState<number>(30);
  const [partners, setPartners] = useState<string[]>([]);
  const [sorts, setSorts] = useState<string[]>([]);
  const [searchText, setSearchText] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);

  // useEffect(() => {
  //   localStorage.setItem(
  //     LOCAL_STORAGE_KEYS.ITEMS_PER_PAGE,
  //     JSON.stringify(itemsPerPage),
  //   );
  // }, [itemsPerPage]);
  //
  // useEffect(() => {
  //   localStorage.setItem(LOCAL_STORAGE_KEYS.PARTNERS, JSON.stringify(partners));
  // }, [partners]);
  //
  // useEffect(() => {
  //   localStorage.setItem(LOCAL_STORAGE_KEYS.SORTS, JSON.stringify(sorts));
  // }, [sorts]);
  //
  // useEffect(() => {
  //   localStorage.setItem(LOCAL_STORAGE_KEYS.SEARCH_TEXT, searchText);
  // }, [searchText]);
  //
  // useEffect(() => {
  //   localStorage.setItem(
  //     LOCAL_STORAGE_KEYS.CURRENT_PAGE,
  //     JSON.stringify(currentPage),
  //   );
  // }, [currentPage]);

  async function getList({ page = currentPage }: { page?: number } = {}) {
    try {
      setCurrentPage(page);
      const partnerNumbers = partners.map(item => Number(item));
      const { success, message, data } = await adminAPI.item.getList({
        page: page,
        limit: itemsPerPage,
        sorts: sorts,
        partners: partnerNumbers,
        searchtxt: searchText
        // ...filter,
      });
      if (success) {
        setListData(data);
        console.log("data", data);
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다"
        });
      }
    } catch (error) {
      console.error("getList error", error);
    }
  }

  async function getDetail({ no }: { no: number }) {
    try {
      const { success, message, data } = await adminAPI.item.getDetail({ no });
      if (success) {
        setIsDetailModalVisible(true);
        setDetailData(data);
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다"
        });
      }
    } catch (error) {
      console.error("getDetail error", error);
    }
  }

  async function toggleView({ no, view_yn }: { no: number; view_yn: TypeYN }) {
    try {
      if (!confirm("노출여부를 변경하시겠습니까?")) {
        return;
      }

      const { success, message } = await adminAPI.item.toggleViewYn({
        no,
        view_yn
      });
      if (success) {
        getList({ page: listData?.page?.cur || 1 });
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다"
        });
      }
    } catch (error) {
      console.error("getList error", error);
    }
  }

  async function getPartnerList() {
    try {
      const { data, success, message } =
        await adminAPI.partner.apiGetAllPartners();
      if (success) {
        setPartnerList(data.result);
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다"
        });
      }
    } catch (error) {
      console.log("getPartnerList error", error);
    }
  }

  async function exportBarcodeExcel() {
    try {
      const { success, data, message } =
        await adminAPI.item.exportExcelBarcodeList({
          ...filter
        });
      if (success) {
        excelUtil.excelDownload({
          excelData: data.result,
          filename: `바코드_${moment().format("YYMMDD")}`
        });
      } else {
        return setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다"
        });
      }
    } catch (error) {
      console.error("exportBarcodeExcel error", error);
    }
  }

  async function exportExcel() {
    try {
      // 필터 값을 쿼리 파라미터로 변환
      const queryParams: Record<string, string> = {};

      if (filter.sort) {
        queryParams.sort = filter.sort;
      }
      if (filter.partner) {
        queryParams.partner = String(filter.partner);
      }
      if (searchText) {
        queryParams.searchtxt = searchText;
      }

      // 파트너스 배열 처리
      if (partners.length > 0) {
        queryParams.partners = partners.join(",");
      }

      // 정렬 배열 처리
      if (sorts.length > 0) {
        queryParams.sorts = sorts.join(",");
      }

      const queryString = new URLSearchParams(queryParams).toString();
      const url = `/admin/excel/items?${queryString}`;

      // Blob으로 직접 다운로드 처리
      const response = await axiosGetUtil<Blob>(url, {
        responseType: "blob"
      });

      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      });
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute(
        "download",
        `상품목록_${moment().format("YYYYMMDD")}.xlsx`
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      console.error("exportExcel error", error);
      setAlertModal({
        isVisible: true,
        title: "엑셀 다운로드 중 오류가 발생했습니다"
      });
    }
  }

  useEffect(() => {
    getPartnerList();
  }, []);

  useEffect(() => {
    getList({ page: 1 });
  }, [filter.partner, filter.sort, itemsPerPage, partners, sorts]);

  return (
    <>
      <ContentLayout title="상품관리" buttons={[]}>
        <SearchFilter
          left={[
            <SelectBox
              key={2}
              list={[
                { label: "30개씩 보기", value: "30" },
                { label: "50개씩 보기", value: "50" },
                { label: "100개씩 보기", value: "100" }
              ]}
              value={itemsPerPage.toString()}
              singleSelect={val => {
                setItemsPerPage(Number(val));
              }}
              placeholder="개수"
              boxStyle={{ width: "140px" }}
            />,
            <SelectBox
              key={5}
              multiple
              list={sortArr}
              boxStyle={{
                minWidth: "120px"
              }}
              // value={filter.sort}
              value={sorts}
              singleSelect={value =>
                setFilter({ ...filter, sort: value as TItemFilterSortType })
              }
              multipleSelect={(value = []) => {
                setSorts(value);
              }}
              placeholder="정렬"
            />,
            <SelectBox
              key={6}
              multiple
              list={[...partnerList].map(item => ({
                value: String(item.no),
                label: String(item.partner_name || "")
              }))}
              boxStyle={{
                minWidth: "150px"
              }}
              // value={String(filter.partner)}
              value={partners}
              singleSelect={value => {
                setFilter({ ...filter, partner: Number(value) });
              }}
              multipleSelect={(value = []) => {
                // 순서대로 들어갈수 있게 기존 배열과 비교해서 새로 추가된 것만 추가
                setPartners(value);
              }}
              placeholder="업체선택"
            />,
            <InputBox
              key={2}
              value={searchText}
              // onValue={value => setFilter({...filter, searchtxt: value})}
              onValue={value => setSearchText(value)}
              onEnter={getList}
              rightContent={
                <div style={{ display: "flex" }} onClick={() => getList()}>
                  <FontAwesomeIcon icon={faSearch} />
                </div>
              }
            />
          ]}
          right={[
            <Button
              key={2}
              text="다운로드"
              icon={<FontAwesomeIcon icon={faFileExcel} />}
              backgroundColor={styleUtils.COLOR.excel}
              fontColor={styleUtils.COLOR.white}
              onClick={() => exportExcel()}
              marginHorizontal={2}
            />,
            <Button
              key={3}
              text="바코드"
              icon={<FontAwesomeIcon icon={faDownload} />}
              backgroundColor={styleUtils.COLOR.warning}
              onClick={exportBarcodeExcel}
              marginHorizontal={2}
            />,
            <Button
              key={4}
              text="상품등록"
              backgroundColor={styleUtils.COLOR.success}
              fontColor={styleUtils.COLOR.white}
              onClick={() => setIsDetailModalVisible(true)}
              marginHorizontal={2}
              borderRadius={1}
            />
          ]}
        />
        <Content>
          <ListTable paging={listData?.page} getList={getList}>
            <colgroup>
              <col style={{ width: "5%" }} />
              <col style={{ width: "5%" }} />
              <col style={{ width: "7%" }} />
              <col style={{ width: "10%" }} />
              <col style={{ width: "15%" }} />
              <col style={{ width: "10%" }} />
              <col style={{ width: "5%" }} />
              <col style={{ width: "5%" }} />
              <col style={{ width: "5%" }} />
              <col style={{ width: "5%" }} />
              <col style={{ width: "10%" }} />
              <col style={{ width: "7%" }} />
            </colgroup>
            <thead>
              <tr>
                <th>바코드</th>
                <th>물류코드</th>
                <th>업체명</th>
                <th>제품명</th>
                <th>현재재고</th>
                <th>
                  미출고
                  <br />
                  재고
                </th>
                <th>
                  정기구독
                  <br />
                  주문수량
                </th>
                <th>입고가</th>
                <th>표시가격</th>
                <th>정상가격</th>
                <th>리뷰</th>
                <th>노출여부</th>
              </tr>
            </thead>
            <tbody>
              {(listData?.result || []).map((item, index) => {
                const webLink = `${ZOOTOPIA_WEB_URL}/productDetail/${item.no}`;
                return (
                  <tr
                    key={index}
                    onClick={() => !!item.no && getDetail({ no: item.no })}
                  >
                    <td>{item.barcode}</td>
                    <td>{item.teamfresh_code}</td>
                    <td>{item.partners?.partner_name}</td>
                    <td>
                      {item.item_name}
                      {item?.no && (
                        <span
                          className="ml-2 text-xs text-indigo-600 underline cursor-pointer"
                          onClick={e => {
                            e.stopPropagation();
                            if (item.no) {
                              window.open(webLink);
                            }
                          }}
                        >
                          조회
                        </span>
                      )}
                    </td>
                    <td>
                      <span
                        style={{
                          color:
                            (item.stock || 0) <= 0
                              ? styleUtils.COLOR.danger
                              : styleUtils.COLOR.black
                        }}
                      >
                        {(item.stock || 0).toLocaleString()}
                      </span>
                      {!!item?.stock_date &&
                        moment(item?.stock_date?.stock_date).isValid() && (
                          <div>
                            <span className="text-12">
                              다음입고:{" "}
                              {moment(item?.stock_date?.stock_date).format(
                                "MM/DD"
                              )}{" "}
                              (
                              {(
                                Number(item?.stock_date?.stock_count || 0) +
                                Number(
                                  ((item.stock || 0) <= 0
                                    ? item.stock || 0
                                    : 0) * -1
                                )
                              ).toLocaleString()}
                              )
                            </span>
                          </div>
                        )}
                      {((item.stock_item_list || []).length > 0 ||
                        item.detail_item_no) && (
                        <div className="text-10">(재고연동)</div>
                      )}
                    </td>
                    <td>{(item.not_out_stock || 0).toLocaleString()}</td>
                    <td
                      className={[
                        ...((item.subscribe || []).length > 0
                          ? ["text-left"]
                          : ["text-center"])
                      ].join(" ")}
                    >
                      {(item.subscribe || [])
                        .slice(0, 2)
                        .map((subscribe, j) => (
                          <div className="text-12" key={j}>
                            <span className={[styles.badge].join(" ")}>
                              {moment(subscribe.delivery_datetime).format(
                                "MM/DD"
                              )}
                            </span>{" "}
                            총{" "}
                            <span
                              className={[styles.badge, styles.badgeCount].join(
                                " "
                              )}
                            >
                              {(subscribe.count || 0).toLocaleString()}
                            </span>
                            {(subscribe.subscribe_temp_count || 0) > 0 && (
                              <>
                                (함께담기
                                <span
                                  className={[
                                    styles.badge,
                                    styles.badgeCount
                                  ].join(" ")}
                                >
                                  {(
                                    subscribe.subscribe_temp_count || 0
                                  ).toLocaleString()}
                                </span>
                                )
                              </>
                            )}
                          </div>
                        ))}
                    </td>
                    <td>{(item.input_price || 0).toLocaleString()}</td>
                    <td>{(item.price || 0).toLocaleString()}</td>
                    <td>{(item.view_price || 0).toLocaleString()}</td>
                    <td>
                      {(item.review_cnt || 0).toLocaleString()}건<br />
                      <span className="text-12">
                        (평균별점: {item.avg_score || 0})
                      </span>
                    </td>
                    <td onClick={utils.handleClickBubble}>
                      <CheckBox
                        isToggle
                        checked={item.view_yn === "Y"}
                        text={item.view_yn === "Y" ? "노출" : "숨김"}
                        onChange={e => {
                          if (item.no) {
                            toggleView({
                              no: item.no,
                              view_yn: item.view_yn === "Y" ? "N" : "Y"
                            });
                          }
                        }}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </ListTable>
        </Content>
      </ContentLayout>
      <ItemDetailModal
        isVisible={!!isDetailModalVisible}
        detailData={detailData}
        close={() => {
          setIsDetailModalVisible(false);
          setDetailData(undefined);
        }}
        refresh={(item_no?: number) => {
          getList({ page: listData?.page?.cur || 1 });
          if (item_no) {
            getDetail({ no: item_no });
          }
        }}
      />
    </>
  );
}
