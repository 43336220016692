import React from "react";
import { Table } from "@tanstack/react-table";
import {
  ChevronLeft,
  ChevronRight,
  ChevronsLeft,
  ChevronsRight
} from "lucide-react";

interface DataTablePaginationProps<TData> {
  table: Table<TData>;
  onPageChange: (page: number) => void;
  pageCount: number;
}

export function DataTablePagination<TData>({
                                             table,
                                             onPageChange,
                                             pageCount
                                           }: DataTablePaginationProps<TData>) {
  const { pageIndex } = table.getState().pagination;

  const generatePageNumbers = (currentPage: number) => {
    const pageNumbers = [];
    const totalPages = pageCount;
    const maxPagesToShow = 5;

    // 페이지 그룹 계산 (1-5, 6-10, 11-15 등)
    const pageGroup = Math.ceil(currentPage / maxPagesToShow);
    const startPage = (pageGroup - 1) * maxPagesToShow + 1;
    const endPage = Math.min(totalPages, pageGroup * maxPagesToShow);

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    return pageNumbers;
  };

  const pageNumbers = generatePageNumbers(pageIndex + 1);

  const handlePageChange = (newPage: number) => {
    onPageChange(newPage - 1); // 페이지 인덱스는 0부터 시작하므로 -1
  };

  const handleNextPage = () => {
    // 현재 페이지가 속한 그룹 계산
    const maxPagesToShow = 5;
    const currentPageGroup = Math.ceil((pageIndex + 1) / maxPagesToShow);

    // > 버튼 클릭 시 항상 다음 그룹(다음 5개 페이지 묶음)의 첫 페이지로 이동
    const nextGroupFirstPage = currentPageGroup * maxPagesToShow + 1;

    // 다음 그룹의 첫 페이지가 전체 페이지 수를 초과하지 않는지 확인
    if (nextGroupFirstPage <= pageCount) {
      handlePageChange(nextGroupFirstPage);
    }
  };

  const handlePreviousPage = () => {
    // 현재 페이지가 속한 그룹 계산
    const maxPagesToShow = 5;
    const currentPageGroup = Math.ceil((pageIndex + 1) / maxPagesToShow);

    // < 버튼 클릭 시 항상 이전 그룹(이전 5개 페이지 묶음)의 마지막 페이지로 이동
    if (currentPageGroup > 1) {
      // 이전 그룹의 마지막 페이지로 이동
      const prevGroupLastPage = (currentPageGroup - 1) * maxPagesToShow;
      handlePageChange(prevGroupLastPage);
    }
  };

  return (
      <div className="flex items-center justify-center py-4">
        <div className="flex items-center space-x-1"> {/* 간격 줄이기 */}
          <button
              className="p-1 rounded-md border-none disabled:opacity-50" // 보더 제거
              onClick={() => handlePageChange(1)}
              disabled={pageIndex === 0}
          >
            <ChevronsLeft className="h-3 w-3" /> {/* 글자 크기 작게 */}
          </button>
          <button
              className="p-1 rounded-md border-none disabled:opacity-50" // 보더 제거
              onClick={handlePreviousPage}
              disabled={pageIndex === 0}
          >
            <ChevronLeft className="h-3 w-3" /> {/* 글자 크기 작게 */}
          </button>

          {pageNumbers.map(page => (
              <button
                  key={page}
                  className={`w-8 h-8 flex items-center justify-center rounded-full border-none ${
                      page === pageIndex + 1 ? "bg-green text-white" : "hover:bg-green-200"
                  }`}
                  onClick={() => handlePageChange(page)}
              >
                {page}
              </button>
          ))}

          <button
              className="p-1 rounded-md border-none disabled:opacity-50" // 보더 제거
              onClick={handleNextPage}
              disabled={pageIndex >= pageCount - 1}
          >
            <ChevronRight className="h-3 w-3" /> {/* 글자 크기 작게 */}
          </button>
          <button
              className="p-1 rounded-md border-none disabled:opacity-50" // 보더 제거
              onClick={() => handlePageChange(pageCount)}
              disabled={pageIndex >= pageCount - 1}
          >
            <ChevronsRight className="h-3 w-3" /> {/* 글자 크기 작게 */}
          </button>
        </div>
      </div>
  );
}
