import {Button, CheckBox, ListTable} from "@components";
import {states} from "@recoils";
import {adminAPI, styleUtils, utils} from "@utils";
import {
  TProductDetailType,
  TypeYN,
  IDBItemsOption,
  IDBPetKind,
  IDBProduct,
  TProductItemDetail,
  ISelectedDetailList,
} from "@data";
import _ from "lodash";
import React, {useEffect, useState} from "react";
import {useSetRecoilState} from "recoil";
import {CopyItemSelectModal} from "./CopyItemSelectModal";
import {DetailItemAddModal} from "./DetailItemAddModal";
import {
  CheckpointItem,
  CompositionItem,
  InformationItem,
  Interview2Item,
  InterviewItem,
  ItemOriginItem,
  RecipeItem,
  ImgDetailItem,
  FeedAmountItem,
  StorageItem,
  FeedItem,
  ProductGuaranteeItem,
  NoticeItem,
  CheckpointNewItem,
} from "./DetailTabItem";
import {OptionListModal} from "./OptionListModal";

interface IProps {
  item_no: number;
  item_partner_no?: number;
}

const categorys: {
  text: string;
  value: TProductDetailType;
  isAdded: boolean;
}[] = [
  {text: "체크포인트", value: "checkpoint", isAdded: false},
  {text: "체크포인트(신규)", value: "checkpointNew", isAdded: false},
  {text: "제조방법", value: "recipe", isAdded: false},
  {text: "제조방법(신규)", value: "recipe_new", isAdded: false},
  {text: "원산지", value: "itemOrigin", isAdded: false},
  {text: "상세정보", value: "imgDetail", isAdded: false},
  {text: "인터뷰", value: "interview", isAdded: false},
  {text: "영양학박사님 인터뷰", value: "interview2", isAdded: false},
  {text: "성분구성", value: "composition", isAdded: true},
  {text: "급여량", value: "feedAmount", isAdded: true},
  {text: "상품정보", value: "information", isAdded: false},
  {text: "보관방법", value: "storage", isAdded: false},
  {text: "급여정보", value: "feed", isAdded: false},
  {text: "소비보장제", value: "productGuarantee", isAdded: false},
  {text: "상품공지", value: "notice", isAdded: false},
];

export function DetailTab({item_no, item_partner_no}: IProps) {
  const setAlertModal = useSetRecoilState(states.alertState);
  const [optionList, setOptionList] = useState<IDBItemsOption[]>([]);
  const [categoryList, setCategoryList] = useState<TProductItemDetail[]>([]);
  const [petKindList, setPetKindList] = useState<IDBPetKind[]>([]);
  const [detailData, setDetailData] = useState<TProductItemDetail>();
  const [isOptionModal, setIsOptionModal] = useState(false);
  const [isAddDetailModal, setIsAddDetailModal] = useState(false);
  const [isSelectItemModal, setIsSelectItemModal] = useState(false);
  const [itemList, setItemList] = useState<IDBProduct[]>([]);
  const [selectedDetailList, setSelectedDetailList] = useState<
    ISelectedDetailList[]
  >([]);

  const isAllChecked = selectedDetailList.length === categoryList.length;

  async function getItemList() {
    try {
      const {data, success} = await adminAPI.item.apiGetAllItems({
        partner_no: item_partner_no,
      });
      if (success) {
        setItemList((data.result || []).filter(item => item.no !== item_no));
      }
    } catch (error) {
      console.log("getItemList error", error);
    }
  }

  async function getCategoryList({
    isRefresh = false,
  }: {isRefresh?: boolean} = {}) {
    try {
      const {data, success, message} = await adminAPI.item.getDetailList({
        item_no,
      });
      if (success) {
        setCategoryList(data);
        if (isRefresh && detailData?.no) {
          getItemDetail({no: detailData?.no, item_no});
        }
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다.",
        });
      }
    } catch (error) {
      console.error("getCategoryList error", error);
    }
  }

  async function getOptionList() {
    try {
      const {data, success, message} = await adminAPI.item.getItemOptionList({
        item_no,
      });
      if (success) {
        setOptionList(data);
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다.",
        });
      }
    } catch (error) {
      console.error("getOptionList error", error);
    }
  }

  async function getPetKindList() {
    try {
      const {data, success, message} = await adminAPI.item.getPetKindList();
      if (success) {
        setPetKindList(data);
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다.",
        });
      }
    } catch (error) {
      console.error("getPetKindList error", error);
    }
  }

  async function toggleHideItemDetail({
    no,
    item_no,
    hide_yn,
  }: {
    no: number;
    item_no: number;
    hide_yn: TypeYN;
  }) {
    try {
      const {success, message} = await adminAPI.item.toggleHideItemDetail({
        no,
        item_no,
        hide_yn,
      });
      if (success) {
        getCategoryList();
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다.",
        });
      }
    } catch (error) {
      console.error("toggleHideItemDetail error", error);
    }
  }

  async function getItemDetail({no, item_no}: {no: number; item_no: number}) {
    try {
      const {data, success, message} = await adminAPI.item.getItemDetail({
        no,
        item_no,
      });
      if (success) {
        setDetailData(data);
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다.",
        });
      }
    } catch (error) {
      console.error("getItemDetail error", error);
    }
  }

  async function saveCopyItemDetail({itemList}: {itemList: number[]}) {
    try {
      if (itemList.length === 0 || selectedDetailList.length === 0) {
        return alert("상품을 선택해주세요.");
      }
      if (
        !confirm(
          `작성하신 상품상세 ${selectedDetailList.length}개를 ${itemList.length}개 상품에 복사하시겠습니까?\n기존 작성한 내역이 있을 경우, 덮어쓰기 되며 복구가 불가합니다!`,
        )
      ) {
        return;
      }
      const {success, message} = await adminAPI.item.copyItemDetails({
        item_no_list: itemList,
        detail_list: selectedDetailList,
      });
      setAlertModal({
        isVisible: true,
        title: message || "오류가 발생했습니다",
      });
      if (success) {
        setIsSelectItemModal(false);
      }
    } catch (error) {
      console.error("save error", error);
    }
  }

  useEffect(() => {
    getCategoryList();
    getOptionList();
    getPetKindList();
    getItemList();
  }, []);

  useEffect(() => {
    setDetailData(undefined);
  }, [categoryList]);

  return (
    <>
      <div className="row top">
        <div className="col" style={{padding: "0 6px"}}>
          <div style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}>
            <div className="row">
              <div className="col auto title">제품상세</div>
              <div className="col flex-end">
                <div>
                  <Button
                    backgroundColor={styleUtils.COLOR.success}
                    fontColor={styleUtils.COLOR.white}
                    text="샘플팩옵션관리"
                    onClick={() => setIsOptionModal(true)}
                  />
                </div>
                <div style={{width: "4px"}} />
                <div>
                  <Button
                    backgroundColor={styleUtils.COLOR.success}
                    fontColor={styleUtils.COLOR.white}
                    text="추가"
                    onClick={() => setIsAddDetailModal(true)}
                  />
                </div>
                <div style={{width: "4px"}} />
                <div>
                  <Button
                    backgroundColor={styleUtils.COLOR.success}
                    fontColor={styleUtils.COLOR.white}
                    text="복사"
                    onClick={() => {
                      if (selectedDetailList.length === 0) {
                        return alert("복사할 상세를 먼저 체크해주세요.");
                      } else {
                        setIsSelectItemModal(true);
                      }
                    }}
                  />
                </div>
              </div>
            </div>
            <ListTable>
              <thead>
                <tr>
                  <th>
                    <CheckBox
                      checked={isAllChecked}
                      onClick={() => {
                        if (isAllChecked) {
                          setSelectedDetailList([]);
                        } else {
                          const allList = categoryList
                            .filter(
                              _item =>
                                !!_item.no && !!_item.item_no && !!_item.type,
                            )
                            .map(_item => ({
                              detail_no: _item.no as number,
                              item_no: _item.item_no as number,
                              type: _item.type as TProductDetailType,
                            }));
                          setSelectedDetailList(allList);
                        }
                      }}
                    />
                  </th>
                  <th>구분</th>
                  <th>노출여부</th>
                </tr>
              </thead>
              <tbody>
                {categoryList.map(item => {
                  const categoryName = categorys.find(
                    cate => cate.value === item.type,
                  )?.text;
                  const compositionData =
                    item.type === "composition" ? item : undefined;
                  const feedAmountData =
                    item.type === "feedAmount" ? item : undefined;
                  const option_no = compositionData
                    ? compositionData.data_json?.option_no
                    : feedAmountData
                    ? feedAmountData.data_json?.option_no
                    : undefined;
                  const optionName = (optionList || []).find(
                    _option => _option.no === option_no,
                  )?.option_name;
                  const isSelected = !!selectedDetailList.find(
                    _item =>
                      _item.detail_no === item.no &&
                      _item.item_no === item.item_no &&
                      _item.type === item.type,
                  );

                  return (
                    <tr
                      key={_.uniqueId()}
                      onClick={() => {
                        if (item.no && item.item_no) {
                          getItemDetail({
                            no: item.no,
                            item_no: item.item_no,
                          });
                        } else {
                          setDetailData({...item});
                        }
                      }}>
                      <td onClick={utils.handleClickBubble}>
                        <CheckBox
                          checked={isSelected}
                          onClick={() => {
                            if (isSelected) {
                              setSelectedDetailList([
                                ...selectedDetailList.filter(
                                  _item =>
                                    !(
                                      _item.detail_no === item.no &&
                                      _item.item_no === item.item_no &&
                                      _item.type === item.type
                                    ),
                                ),
                              ]);
                            } else if (
                              !!item.no &&
                              !!item.item_no &&
                              !!item.type
                            ) {
                              setSelectedDetailList([
                                ...selectedDetailList,
                                {
                                  detail_no: item.no,
                                  item_no: item.item_no,
                                  type: item.type,
                                },
                              ]);
                            }
                          }}
                        />
                      </td>
                      <td>
                        {categoryName || "-"}
                        {!!optionName && ` (${optionName || "-"})`}
                      </td>
                      <td onClick={utils.handleClickBubble}>
                        <CheckBox
                          isToggle
                          checked={item.hide_yn === "N"}
                          text={item.hide_yn === "Y" ? "숨김" : "노출"}
                          onClick={() => {
                            if (item.no && item.item_no) {
                              toggleHideItemDetail({
                                no: item.no,
                                item_no: item.item_no,
                                hide_yn: item.hide_yn === "Y" ? "N" : "Y",
                              });
                            }
                          }}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </ListTable>
          </div>
        </div>
        <div className="col" style={{padding: "0 6px"}}>
          {detailData?.type === "checkpoint" && (
            <CheckpointItem
              detailData={detailData}
              item_no={item_no}
              refresh={() => getCategoryList({isRefresh: true})}
            />
          )}
          {detailData?.type === "checkpointNew" && (
            <CheckpointNewItem
              detailData={detailData}
              item_no={item_no}
              refresh={() => getCategoryList({isRefresh: true})}
            />
          )}
          {(detailData?.type === "recipe" ||
            detailData?.type === "recipe_new") && (
            <RecipeItem
              detailData={detailData}
              item_no={item_no}
              refresh={() => getCategoryList({isRefresh: true})}
            />
          )}
          {detailData?.type === "itemOrigin" && (
            <ItemOriginItem
              detailData={detailData}
              item_no={item_no}
              refresh={() => getCategoryList({isRefresh: true})}
            />
          )}
          {detailData?.type === "imgDetail" && (
            <ImgDetailItem
              detailData={detailData}
              item_no={item_no}
              refresh={() => getCategoryList({isRefresh: true})}
            />
          )}
          {detailData?.type === "interview" && (
            <InterviewItem
              detailData={detailData}
              item_no={item_no}
              refresh={() => getCategoryList({isRefresh: true})}
            />
          )}
          {detailData?.type === "feedAmount" && (
            <FeedAmountItem
              detailData={detailData}
              item_no={item_no}
              refresh={() => getCategoryList({isRefresh: true})}
              optionList={optionList}
            />
          )}
          {detailData?.type === "interview2" && (
            <Interview2Item
              detailData={detailData}
              item_no={item_no}
              refresh={() => getCategoryList({isRefresh: true})}
            />
          )}
          {detailData?.type === "composition" && (
            <CompositionItem
              detailData={detailData}
              item_no={item_no}
              refresh={() => getCategoryList({isRefresh: true})}
              optionList={optionList}
            />
          )}
          {detailData?.type === "information" && (
            <InformationItem
              detailData={detailData}
              item_no={item_no}
              refresh={() => getCategoryList({isRefresh: true})}
              petKindList={petKindList}
            />
          )}
          {detailData?.type === "storage" && (
            <StorageItem
              detailData={detailData}
              item_no={item_no}
              refresh={() => getCategoryList({isRefresh: true})}
            />
          )}
          {detailData?.type === "feed" && (
            <FeedItem
              detailData={detailData}
              item_no={item_no}
              refresh={() => getCategoryList({isRefresh: true})}
            />
          )}
          {detailData?.type === "productGuarantee" && (
            <ProductGuaranteeItem
              detailData={detailData}
              item_no={item_no}
              refresh={() => getCategoryList({isRefresh: true})}
            />
          )}
          {detailData?.type === "notice" && (
            <NoticeItem
              detailData={detailData}
              item_no={item_no}
              refresh={() => getCategoryList({isRefresh: true})}
            />
          )}
        </div>
      </div>
      <OptionListModal
        isVisible={isOptionModal}
        item_no={item_no}
        optionList={optionList}
        close={() => setIsOptionModal(false)}
        refresh={getOptionList}
      />
      <DetailItemAddModal
        isVisible={isAddDetailModal}
        item_no={item_no}
        close={() => setIsAddDetailModal(false)}
        refresh={getCategoryList}
        categorys={categorys.filter(item => item.isAdded)}
      />
      {!!isSelectItemModal && (
        <CopyItemSelectModal
          isVisible={isSelectItemModal}
          itemList={itemList}
          close={() => setIsSelectItemModal(false)}
          save={saveCopyItemDetail}
        />
      )}
    </>
  );
}
