import {Button, FileUploadButton, TextAreaBox} from "@components";
import {faMinus} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {states} from "@recoils";
import {adminAPI, styleUtils} from "@utils";
import {IProductDetailGuarantee} from "@data";
import _ from "lodash";
import React, {useEffect, useState} from "react";
import {useSetRecoilState} from "recoil";
import styles from "../../style.module.scss";

interface IProps {
  item_no: number;
  detailData: IProductDetailGuarantee;
  refresh?: () => void;
}

export function ProductGuaranteeItem({detailData, refresh, item_no}: IProps) {
  const setAlertModal = useSetRecoilState(states.alertState);
  const [data, setData] = useState<IProductDetailGuarantee>({
    type: "productGuarantee",
    data_json: {
      data: [],
      img_url: "",
      title: "",
    },
  });

  const img_url = data.data_json?.thumbnail_img_url || data.data_json?.img_url;

  async function save() {
    try {
      const params = {
        ...data,
        data_json: {...data.data_json},
        item_no,
      };
      const {success, message} = detailData.no
        ? await adminAPI.item.updateItemDetail({...params, no: detailData.no})
        : await adminAPI.item.createItemDetail(params);

      if (success) {
        refresh?.();
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다.",
        });
      }
    } catch (error) {
      console.error("save error", error);
    }
  }

  useEffect(() => {
    setData({...detailData});
  }, [detailData]);

  return (
    <>
      <div style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}>
        <div className="row">
          <div className="col flex-end">
            <div>
              <Button
                backgroundColor={styleUtils.COLOR.success}
                fontColor={styleUtils.COLOR.white}
                text="저장"
                onClick={save}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col title">
            <div className="buttonCol">
              이미지
              <div>
                <FileUploadButton
                  text={"업로드"}
                  height={32}
                  uploadUrl={"/admin/utils/uploadItemDetailImg"}
                  fileUpload={({files}) =>
                    setData({
                      ...data,
                      data_json: {
                        ...data.data_json,
                        img_url: files[0],
                      },
                    })
                  }
                />
              </div>
            </div>
          </div>
          <div className={["col", styles.imgCol].join(" ")}>
            {!!img_url && <img src={img_url} className={styles.detailImg} />}
          </div>
        </div>
        <div className="row">
          <div className="col title">내용</div>
          <div className="col flex-end">
            <div>
              <Button
                text="추가"
                onClick={() => {
                  setData({
                    ...data,
                    data_json: {
                      ...data.data_json,
                      data: [...(data.data_json?.data || []), ""],
                    },
                  });
                }}
              />
            </div>
          </div>
        </div>
        {(data.data_json?.data || []).map((item, index) => {
          return (
            <div key={index}>
              <div className="row">
                <div className="col">
                  <TextAreaBox
                    value={item}
                    onValue={value =>
                      setData?.({
                        ...data,
                        data_json: {
                          ...data.data_json,
                          data: (data.data_json?.data || []).map((_item, j) =>
                            index === j ? value : _item,
                          ),
                        },
                      })
                    }
                    height={60}
                  />
                </div>
                <div
                  className={styles.minusIcon}
                  onClick={() => {
                    if (!confirm("항목을 제거하시겠습니까?")) {
                      return;
                    }
                    setData({
                      ...data,
                      data_json: {
                        ...data.data_json,
                        data: (data.data_json?.data || []).filter(
                          (_item, j) => index !== j,
                        ),
                      },
                    });
                  }}>
                  <FontAwesomeIcon icon={faMinus} />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}
