import { styleUtils } from "@utils";
import {
  IDBCouponList,
  IDBMember,
  IDBReply,
  IDBReviewOption,
  IDBReviewOptionCategoryList,
  IDBReviewOptionList
} from "./DAO";

export type TypeYN = "Y" | "N";
export type TypeYNWithEmpty = TypeYN | "";
export type TDeliveryType = "새벽" | "당일" | "일반" | "도서산간";

export type TCommonDBType = "review" | "item_qna" | "reply";
export type TLikeHistoryType = "item" | "partner";
export type TDeclarationType = "review";
export type TDBCouponGroup = "order" | "item" | "subscribe";
export type TDBCouponType = "price" | "percent" | "delivery";
export type TDBAlarmType =
    | "signup"
    | "coupon_add"
    | "marketing"
    | "delivery_change"
    | "review_req"
    | "review_point";
export type TDBOrderStatus = "1" | "2" | "3" | "4" | "5" | "6" | "7";
export type TDBOrderPaymentType = "1" | "2" | "3";
export type TDBOrderDeliveryStatus = "1" | "2" | "3";
export type TDBOrderDiscountType = "coupon" | "point";
export type TDBPointType = "review" | "order";
export type TDBPointStatus = "0" | "1" | "2";

export const ORDER_STATUS: { [key in TDBOrderStatus]: string } = {
  1: "주문완료",
  2: "주문취소",
  3: "발송준비",
  4: "배송시작",
  5: "배송중",
  6: "배송완료",
  7: "전체환불"
};

export const ORDER_STATUS_COLOR: { [key in TDBOrderStatus]: styleUtils.COLOR } =
    {
      1: styleUtils.COLOR.danger,
      2: styleUtils.COLOR.warning,
      3: styleUtils.COLOR.primary,
      4: styleUtils.COLOR.primary,
      5: styleUtils.COLOR.primary,
      6: styleUtils.COLOR.success,
      7: styleUtils.COLOR.warning
    };

export const ORDER_STATUS_TEXT_COLOR: {
  [key in TDBOrderStatus]: styleUtils.COLOR;
} = {
  1: styleUtils.COLOR.white,
  2: styleUtils.COLOR.gray1,
  3: styleUtils.COLOR.white,
  4: styleUtils.COLOR.white,
  5: styleUtils.COLOR.white,
  6: styleUtils.COLOR.white,
  7: styleUtils.COLOR.gray1
};

export const ORDER_PAYMENT_TYPE: { [key in TDBOrderPaymentType]: string } = {
  1: "주피페이", // nicepayment
  2: "신용/체크카드", // nicepayment
  3: "토스페이"
};

export const ORDER_DELIVERY_STATUS: {
  [key in TDBOrderDeliveryStatus]: string;
} = {
  1: "배송대기",
  2: "배송중",
  3: "배송완료"
};

export const POINT_STATUS: { [key in TDBPointStatus]: string } = {
  "0": "적립",
  "1": "사용",
  "2": "소멸"
};

export interface IPage {
  cur: number;
  maxcnt: number;
  maxpage: number;
  limit?: number;
}

export interface ApiResponse<T = void> {
  success: boolean;
  message: string;
  page?: IPage;
  data: T;
}

export interface ResultPagingResponse<T = void> {
  result: T;
  page?: IPage;
}

export interface IButtonProps {
  text?: string;
  icon?: JSX.Element;
  fontSize?: number;
  fontWeight?: number;
  onClick?: (e?: any) => void;
  isMainBtn?: boolean;
  height?: number;
  backgroundColor?: styleUtils.COLOR;
  fontColor?: styleUtils.COLOR;
  borderRadius?: number;
  marginHorizontal?: number;
  marginVertical?: number;
  paddingHorizontal?: number;
  paddingVertical?: number;
  disabled?: boolean;
}

export interface IAlertModal {
  isVisible?: boolean;
  title?: string;
  content?: string;
  button?: IButtonProps[];
  close?: () => void;
}

export type TFilterSelectUpDown = "up" | "down" | "equal";
export const convertFilterSelectUpDown: {
  [key in TFilterSelectUpDown]: string;
} = {
  up: "이상",
  down: "이하",
  equal: "같음"
};

export interface IMarketingLogFilter {
  test?: boolean;
  createdAt_sdate?: string;
  createdAt_edate?: string;
  order_sdate?: string;
  order_edate?: string;
  last_order_sdate?: string;
  last_order_edate?: string;
  orderCnt?: string;
  orderCnt_select?: TFilterSelectUpDown;
  orderAllCnt?: string;
  orderAllCnt_select?: TFilterSelectUpDown;
  partner_no?: string;
  item_no?: string[];
  petKind?: string;
  delivery_type?: TDeliveryType[];
  subscription_yn?: TypeYNWithEmpty;
  gradeList?: number[];
  push_yn?: TypeYNWithEmpty;
  include_nonMember?: TypeYN;
  cart_yn?: TypeYNWithEmpty;
  use_coupon_no?: string;
  use_not_coupon_no?: string[];
  expire_coupon_no?: string;
}

export interface IMarketingLogFilterDetail extends IMarketingLogFilter {
  partner_name?: string;
  item_name?: string;
  kind_name?: string;
  coupon_data?: IDBCouponList;
  use_not_coupon_data?: IDBCouponList[];
  expire_coupon_data?: IDBCouponList;
}

export interface IReviewOption extends IDBReviewOption {
  category?: IDBReviewOptionCategoryList;
  option?: IDBReviewOptionList;
}

export interface IReply extends IDBReply {
  member?: IDBMember;
}

export type TOrderListOrering =
    | "createdAt"
    | "output_date"
    | "orderPrice"
    | "status";

export type TOrderListDateType = "createdAt" | "output_date" | "delivery_date";
export type TOrderBy = "ASC" | "DESC";
export type TBannerUrl =
    | "event"
    | "login"
    | "petinfo"
    | "attendance"
    | "noticeDetail"
    | "MEMBERSHIP_OPEN"
    | "COOKED_LULU"
    | "NEWYEAR_2025"
    | "NATIONAL_PUPPY_DAY_TEASER"
    | "NATIONAL_PUPPY_DAY";
export type TCouponIssuanceType = "signup" | "birthday" | "addPetInfo" | "";

export type TDeeplinkValue =
    | "product"
    | "banner"
    | "coupon"
    | "point"
    | "post"
    | "postDetail"
    | "noticeDetail"
    | "orderList"
    | "likeList"
    | "recentList"
    | "alarm"
    | "cart"
    | "attendance"
    | "categoryList"
    | "blackfriday"
    | "blackfriday_teaser"
    | "LAST2024"
    | "MEMBERSHIP_OPEN"
    | "COOKED_LULU"
    | "NEWYEAR_2025"
    | "ADD_PET"
    | "NATIONAL_PUPPY_DAY_TEASER"
    | "NATIONAL_PUPPY_DAY";

export interface IDeeplinkType {
  value: TDeeplinkValue;
  label: string;
  requireno: boolean;
}

export type TSmsLogType =
    | "login"
    | "subscribe_notice"
    | "subscribe_fail"
    | "first_order_survey";
export type TUpDown = "up" | "down";
export type TSectionViewType = 1 | 2 | 3;
export const SECTION_VIEW_TYPE: { [key in TSectionViewType]: string } = {
  1: "바둑판",
  2: "캐러셀",
  3: "상품조합"
};

export type TItemFilterSortType =
    | "barcodeDESC"
    | "barcodeASC"
    | "stockDESC"
    | "stockASC"
    | "createdAtASC"
    | "createdAtDESC"
    | "itemNameASC"
    | "itemNameDESC"
    | "priceASC"
    | "priceDESC";

export type TInOut = "input" | "output";

export type TProductDetailType =
    | "checkpoint"
    | "checkpointNew"
    | "interview"
    | "interview2"
    | "recipe"
    | "information"
    | "composition"
    | "feedAmount"
    | "imgDetail"
    | "itemOrigin"
    | "storage"
    | "feed"
    | "productGuarantee"
    | "notice"
    | "recipe_new"; // FIXME delete me

export type TProductDetailInformationPackType = "냉장" | "냉동" | "상온";
export type TProductDetailInformationPackType2 =
    | "(종이포장)"
    | "(비닐포장)"
    | "";
export type TProductDetailInformationWeightType = "g" | "kg" | "ml";
export type TProductDetailInformationKeepType =
    | "냉장보관"
    | "냉동보관"
    | "실온보관"
    | "온장보관";
export type TProductDetailIconType = "check" | "close";

export type TOrderProfitSelect = "minus" | "plus";
export type TStrBoolean = "TRUE" | "FALSE" | "";

export type TDeliveryEntranceType = "password" | "free" | "security" | "etc";

export type TClaimType = "주문" | "단순문의" | "클레임";

export type TBannerDetailLayout = "2column" | "3column" | "carousel" | "set";
export const BANNER_DETAIL_LAYOUT: { [key in TBannerDetailLayout]: string } = {
  "2column": "2컬럼",
  "3column": "3컬럼",
  carousel: "캐러셀",
  set: "배너세트(제품 3개만 가능)"
};
export const bannerDetailLayout: {
  value: string;
  label: string;
}[] = (Object.keys(BANNER_DETAIL_LAYOUT) as TBannerDetailLayout[]).map(key => ({
  value: key,
  label: BANNER_DETAIL_LAYOUT[key]
}));
