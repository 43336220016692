import React, { useEffect, useState } from "react";
import { Header } from "./Header";
import { Sidebar } from "./Sidebar";
import styles from "./style.module.scss";
import ivoryLogo_small from "../../assets/images/ivoryLogo_small.png";
import ivoryLogo from "../../assets/images/ivoryLogo.png";
import { useLocation } from "react-router-dom";
import { ICommonResponse } from "@data";
import { adminAPI, constant } from "@utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAnglesRight,
  faAnglesLeft,
  faBars
} from "@fortawesome/free-solid-svg-icons";
import { Dropdown } from "../index";
import { useRecoilValue } from "recoil";
import { states } from "@recoils";
import { useLogin } from "@hook";

interface IProps {
  children: JSX.Element;
}

export default function Layout({ children }: IProps) {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(
    window.innerWidth > constant.MOBILE_WIDTH
  );
  const [isFixed, setIsFixed] = useState(true);
  const [commonData, setCommonData] = useState<ICommonResponse>({});
  const [isMobile, setIsMobile] = useState(
    window.innerWidth <= constant.MOBILE_WIDTH
  );

  async function getCommonData() {
    try {
      const { data, success } = await adminAPI.admin.apiGetCommon();
      if (success) {
        setCommonData(data);
      }
    } catch (error) {
      console.error("getCommonData error", error);
    }
  }

  function toggleMenu() {
    setIsFixed(!isFixed);
    // if (isFixed) {
    //   setIsOpen(false);
    // }
    setIsOpen(!isOpen);
  }

  function mobileToggleMenu() {
    setIsOpen(true);
    // setIsFixed(false);
  }

  function closeMenu() {
    setIsOpen(false);
  }

  function fixMenu() {
    setIsFixed(!isFixed);
  }

  //외부로 나가는 setIsOpen을 통해 isOpen을 변경할 수 있게 하는 펑션
  function controlMenu(bool: boolean) {
    setIsOpen(bool);
  }

  function handleResize() {
    setIsMobile(window.innerWidth <= constant.MOBILE_WIDTH);
    if (window.innerWidth <= constant.MOBILE_WIDTH) {
      setIsOpen(false);
    }
  }

  useEffect(() => {
    getCommonData();

    if (window.innerWidth <= constant.MOBILE_WIDTH) {
      setIsOpen(false);
    }

    window.addEventListener("resize", handleResize); // 추가
    return () => {
      window.removeEventListener("resize", handleResize); // 추가
    };
  }, [location]);

  const adminData = useRecoilValue(states.adminDataState);
  const { logout } = useLogin();

  return (
    <div
      className={styles.pane}
    >
      {isMobile && !isOpen && (
        <div className={styles.mobileMenuToggle} onClick={mobileToggleMenu}>
          <FontAwesomeIcon icon={faBars} size="2x" />
        </div>
      )}

      <div
        // onMouseEnter={() =>
        //   !isFixed && setIsOpen ? setIsOpen(true) : undefined
        // }
        // onMouseLeave={() =>
        //   !isFixed && setIsOpen ? setIsOpen(false) : undefined
        // }
        className={[styles.sidebar, isOpen ? styles.open : styles.close].join(
          " "
        )}
      >
        {!isMobile && isOpen && (
          <div className={styles.sidebarHeader}>
            <img src={ivoryLogo_small} className={styles.logoSmall} />
            {isOpen && <img src={ivoryLogo} className={styles.logoLarge} />}
            {!isMobile && isOpen && (
              <FontAwesomeIcon
                className={styles.hambuger}
                icon={faBars}
                onClick={toggleMenu}
              />
            )}
          </div>
        )}

        {!isMobile && !isOpen && (
          <>
            <div className={styles.sidebarHeader}>
              <img src={ivoryLogo_small} className={styles.logoSmall} />
            </div>
            <div className={styles.sidebarHeader}>
              <FontAwesomeIcon
                className={styles.hambuger}
                icon={faBars}
                onClick={toggleMenu}
              />
            </div>
          </>
        )}

        <Sidebar
          isOpen={isOpen}
          setIsOpen={controlMenu}
          itemQnaCount={commonData.item_qna_count}
          memberQnaCount={commonData.member_qna_count}
          frequnecyApplyCount={commonData.frequnecy_apply_count}
          memberOutRequestCount={commonData.member_out_request_count}
        />
        {/*<div className={styles.profile}>*/}
        {/*  <Dropdown*/}
        {/*    content={*/}
        {/*      <>*/}
        {/*        <span className={styles.title}>*/}
        {/*          {adminData?.nickname || adminData?.name || "관리자"}*/}
        {/*        </span>*/}
        {/*      </>*/}
        {/*    }*/}
        {/*    options={[{label: "로그아웃", value: "logout", onClick: logout}]}*/}
        {/*  />*/}
        {/*</div>*/}
      </div>
      <div
          className={styles.content}>
        <div
          className={[
            styles.sidebarPlaceholder,
            // isFixed ? styles.open : styles.close,
            isOpen ? styles.open : styles.close
          ].join(" ")}
        />
        <div className={styles.mainContent}>
          <div>{children}</div>
        </div>
      </div>
    </div>
  );
}
