import React from "react";
import styles from "./style.module.scss";

export interface ITabList<T = string> {
  tab: T;
  title: string;
}

interface IProps {
  tabList: ITabList<any>[];
  setTab: (tab: any) => void;
  activeTab: any;
  onAddTab?: () => void; // 추가
}

export default function TabList({tabList, setTab, activeTab, onAddTab}: IProps) {
  return (
    <div className={styles.tabs}>
      {tabList.map((tab, i) => {
        const isActive = activeTab === tab.tab;
        return (
          <div
            key={i}
            onClick={() => setTab(tab.tab)}
            className={[styles.item, ...(isActive ? [styles.active] : [])].join(" ")}>
            {tab.title}
          </div>
        );
      })}
      {onAddTab && (
        <div
          onClick={onAddTab}
          className={styles.item}
          style={{ cursor: 'pointer' }}
        >
          +
        </div>
      )}
    </div>
  );
}