import { IButtonProps } from "@data";
import React from "react";
import styles from "./style.module.scss";

export default function Button({
                                   isMainBtn,
                                   onClick,
                                   text,
                                   icon,
                                   height,
                                   fontSize = 16,
                                   fontWeight = 400,
                                   backgroundColor,
                                   fontColor,
                                   borderRadius = 1,
                                   marginHorizontal,
                                   marginVertical,
                                   paddingHorizontal,
                                   paddingVertical,
                                   disabled
                               }: IButtonProps) {
    return (
        <div
            onClick={disabled ? undefined : onClick} // disabled일 때는 onClick 비활성화
            className={[
                styles.button,
                ...[isMainBtn ? styles.greenBtn : undefined],
                ...[disabled ? styles.disabled : undefined] // disabled 클래스 추가
            ].join(" ")}
            style={{
                ...(height ? { height: `${height}px` } : undefined),
                fontSize: `${fontSize}px`,
                fontWeight: `${fontWeight}`,
                borderRadius: `${borderRadius}px`,
                ...(backgroundColor ? { backgroundColor } : undefined),
                ...(fontColor ? { color: fontColor } : undefined),
                ...(marginHorizontal
                    ? {
                        marginLeft: `${marginHorizontal}px`,
                        marginRight: `${marginHorizontal}px`
                    }
                    : undefined),
                ...(marginVertical
                    ? {
                        marginTop: `${marginVertical}px`,
                        marginBottom: `${marginVertical}px`
                    }
                    : undefined),
                ...(paddingVertical
                    ? {
                        paddingTop: `${paddingVertical}px`,
                        paddingBottom: `${paddingVertical}px`
                    }
                    : undefined),
                ...(paddingHorizontal
                    ? {
                        paddingLeft: `${paddingHorizontal}px`,
                        paddingRight: `${paddingHorizontal}px`
                    }
                    : undefined),
                cursor: disabled ? "not-allowed" : "pointer", // disabled일 때 커서 스타일 변경
                opacity: disabled ? 0.6 : 1 // disabled일 때 투명도 조정
            }}
        >
            {!!text && <span>{text}</span>}
            {icon}
        </div>
    );
}
