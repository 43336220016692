import {
  Button,
  CheckBox,
  InputBox,
  Modal,
  ModalContent,
  ModalFooter,
  ModalTitle,
  RadioBox,
  TextAreaBox,
} from "@components";
import {states} from "@recoils";
import {IDBDeliveryAddress, TDeliveryEntranceType} from "@data";
import React, {useEffect, useState} from "react";
import {useSetRecoilState} from "recoil";
import DaumPostcodeEmbed, {Address as DaumAddress} from "react-daum-postcode";
import {adminAPI} from "@utils";
import {defaultEntranceList} from "utils/constant";

interface IProps {
  isVisible: boolean;
  data?: IDBDeliveryAddress;
  member_no: number;
  close?: () => void;
  refresh?: () => void;
}

const initEntranceType = defaultEntranceList[0]?.entrance_type;

export function AddressDetailModal({
  isVisible,
  data,
  member_no,
  close,
  refresh,
}: IProps) {
  const setAlertModal = useSetRecoilState(states.alertState);
  const [daumAddress, setDaumAddress] = useState<DaumAddress>();
  const [address, setAddress] = useState<{
    road_address: string;
    jibun_address: string;
    isRoadType: boolean;
  }>();
  const [senderName, setSenderName] = useState("");
  const [senderPhone, setSenderPhone] = useState("");
  const [subPhone, setSubPhone] = useState("");
  const [entranceType, setEntranceType] =
    useState<TDeliveryEntranceType>(initEntranceType);
  const [entranceMemo, setEntranceMemo] = useState("");
  const [detailAddress, setDetailAddress] = useState("");

  const defaultEntranceType = defaultEntranceList.find(
    item => item.entrance_type === entranceType,
  );

  function setClose() {
    close?.();

    setDaumAddress(undefined);
    setAddress(undefined);
    setSenderName("");
    setSenderPhone("");
    setSubPhone("");
    setEntranceType(initEntranceType);
    setEntranceMemo("");
    setDetailAddress("");
  }

  async function save() {
    try {
      const sender_phone = senderPhone.replace(/[^0-9]/g, "");
      const sub_phone = subPhone.replace(/[^0-9]/g, "");
      if (!senderName) {
        return setAlertModal({
          isVisible: true,
          title: "수령인을 입력해주세요.",
        });
      }
      if (!sender_phone) {
        return setAlertModal({
          isVisible: true,
          title: "연락처를 입력해주세요.",
        });
      }
      if (!detailAddress) {
        return setAlertModal({
          isVisible: true,
          title: "공동현관 메모를 입력해주세요.",
        });
      }

      if (!confirm("저장하시겠습니까?")) {
        return;
      }

      if (data?.no) {
        const {success, message} = await adminAPI.member.updateDeliveryAddress({
          no: data.no,
          member_no,
          sender_name: senderName,
          sender_phone,
          sub_phone,
          entrance_yn: entranceMemo ? "Y" : "N",
          entrance_type: entranceType,
          entrance_memo: entranceMemo,
          detail_address: detailAddress,
        });
        setAlertModal({
          isVisible: true,
          title: message || "오류",
        });
        if (success) {
          setClose();
          refresh?.();
        }
      } else {
        const {success, message} = await adminAPI.member.addDeliveryAddress({
          member_no,
          sender_name: senderName,
          sender_phone,
          sub_phone,
          entrance_yn: defaultEntranceType?.isRequire ? "Y" : "N",
          entrance_type: entranceType,
          entrance_memo: entranceMemo,
          detail_address: detailAddress,
          bname: daumAddress?.bname || "",
          bname1: daumAddress?.bname1 || "",
          bname2: daumAddress?.bname2 || "",
          jibun_address:
            address?.jibun_address || daumAddress?.jibunAddress || "",
          road_address: address?.road_address || daumAddress?.roadAddress || "",
          query: daumAddress?.query || "",
          roadname: daumAddress?.roadname || "",
          sido: daumAddress?.sido || "",
          sigungu: daumAddress?.sigungu || "",
          zonecode: daumAddress?.zonecode || "",
        });
        setAlertModal({
          isVisible: true,
          title: message || "오류",
        });
        if (success) {
          setClose();
          refresh?.();
        }
      }
    } catch (error) {
      console.error("save error", error);
    }
  }

  function daumAddressComplete(data: DaumAddress) {
    setDaumAddress(data);
    setAddress({
      isRoadType: data.addressType === "R",
      road_address: data.roadAddress,
      jibun_address: data.jibunAddress,
    });
  }

  useEffect(() => {
    setDaumAddress(undefined);
    if (data) {
      setAddress({
        isRoadType: !!data?.road_address,
        road_address: data?.road_address || "",
        jibun_address: data?.jibun_address || "",
      });
      setSenderName(data.sender_name || "");
      setSenderPhone(data.sender_phone || "");
      setSubPhone(data.sub_phone || "");
      setEntranceType(data.entrance_type || initEntranceType);
      setEntranceMemo(data.entrance_memo || "");
      setDetailAddress(data.detail_address || "");
    } else {
      setAddress(undefined);
      setSenderName("");
      setSenderPhone("");
      setSubPhone("");
      setEntranceType(initEntranceType);
      setEntranceMemo("");
      setDetailAddress("");
    }
  }, [data]);

  return (
    <Modal isVisible={isVisible} width={"500px"} close={setClose}>
      <ModalTitle title="배송지상세" close={setClose} isClose />
      <ModalContent>
        <div style={{display: !address ? "block" : "none"}}>
          <DaumPostcodeEmbed onComplete={daumAddressComplete} />
        </div>
        <div style={{display: address ? "block" : "none"}}>
          <div className="row top">
            <div className="col title">주소</div>
            <div className="col">
              <div style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}>
                <InputBox
                  value={address?.road_address || address?.jibun_address}
                  readOnly
                  disabled
                />
                <InputBox
                  value={detailAddress}
                  onValue={setDetailAddress}
                  placeholder="상세주소입력"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col title">수령인</div>
            <div className="col">
              <InputBox
                value={senderName}
                onValue={setSenderName}
                placeholder="수령인기재"
                maxLength={10}
              />
            </div>
          </div>
          <div className="row">
            <div className="col title">휴대폰번호</div>
            <div className="col">
              <InputBox
                value={senderPhone}
                onValue={setSenderPhone}
                placeholder="휴대폰번호 기재"
                maxLength={15}
              />
            </div>
          </div>
          <div className="row">
            <div className="col title">전화번호(선택)</div>
            <div className="col">
              <InputBox
                value={subPhone}
                onValue={setSubPhone}
                placeholder="전화번호 기재"
                maxLength={15}
              />
            </div>
          </div>
          <div className="row top">
            <div className="col title">공동현관</div>
            <div className="col" style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}>
              <div>
                <RadioBox
                  list={defaultEntranceList.map(item => ({
                    text: item.title,
                    value: item.entrance_type,
                  }))}
                  value={entranceType || ""}
                  setValue={value =>
                    setEntranceType(value as TDeliveryEntranceType)
                  }
                  containerStyle={{flexWrap: "wrap"}}
                />
              </div>
            </div>
          </div>
          {!!defaultEntranceType?.isRequire && (
            <div className="row top">
              <div className="col title">공동현관메모</div>
              <div className="col">
                <TextAreaBox
                  value={entranceMemo}
                  onValue={setEntranceMemo}
                  placeholder="공동현관 비밀번호"
                />
              </div>
            </div>
          )}
        </div>
      </ModalContent>
      <ModalFooter>
        <div></div>
        <div>
          <Button text="저장" onClick={save} />
        </div>
      </ModalFooter>
    </Modal>
  );
}
