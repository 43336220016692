import React, { useEffect, useState } from "react";
import {
  Content,
  ListTable,
  SearchFilter,
  Button,
  FileUploadButton,
  InputBox,
  SelectBox
} from "@components";
import { adminAPI, styleUtils } from "@utils";
import { SpareItemModal } from "./SpareItemModal";
import {
  faDownload,
  faPlus,
  faSearch,
  faUpload,
  faFileExcel
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ShadListTable } from "../../components/ShadListTable";
import { ItemDetailModal } from "pages/ItemPage/ItemDetailModal";
import { cn } from "@/utils/shadUtils";
import moment from "moment";

interface ISpareItem {
  no: number;
  promotion_no: number;
  item_no: number;
  item_name: string;
  spare_price: number;
  spare_limit: number | null;
  start_date: string;
  end_date: string;
  spare_sort_order: number;
  active_yn: string;
  item?: any;
  discount_rate?: number;
}

interface ISpareItemsTabProps {
  promotions: any[];
  refresh: () => void;
}

export function SpareItemsTab({ promotions, refresh }: ISpareItemsTabProps) {
  const [selectedPromotion, setSelectedPromotion] = useState<string>("");
  const [spareItems, setSpareItems] = useState<ISpareItem[]>([]);
  const [paging, setPaging] = useState();
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [modalMode, setModalMode] = useState<"create" | "edit">("create");
  const [selectedSpareItem, setSelectedSpareItem] = useState<ISpareItem | null>(
    null
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>("");
  const [checkedItems, setCheckedItems] = useState<number[]>([]);

  // 추가된 상태 변수
  const [itemsPerPage, setItemsPerPage] = useState<number>(30);
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  // 상품 상세 모달을 위한 상태 추가
  const [itemDetailData, setItemDetailData] = useState<any>();
  const [isItemDetailModalVisible, setIsItemDetailModalVisible] =
    useState<boolean>(false);

  useEffect(() => {
    const startOfWeek = moment().startOf("week").format("YYYY-MM-DD");
    const endOfWeek = moment().endOf("week").format("YYYY-MM-DD");

    setStartDate(startOfWeek);
    setEndDate(endOfWeek);
  }, []);

  useEffect(() => {
    getList();
  }, [selectedPromotion, itemsPerPage, startDate, endDate, currentPage]); // 의존성 배열에 추가

  const getList = async () => {
    setLoading(true);
    try {
      const params: any = {
        limit: itemsPerPage, // 보기수 추가
        page: currentPage, // 현재 페이지 추가
        startDate: startDate, // 시작일 추가
        endDate: endDate // 종료일 추가
      };

      if (selectedPromotion) {
        params.promotionNo = Number(selectedPromotion);
      }

      if (searchText) {
        params.query = searchText;
      }

      const result = await adminAPI.promotion.getSpareItems(params);
      if (result.success && result.data) {
        setSpareItems(result.data.result);
        setPaging(result.data.page);
      }
    } catch (error) {
      console.error("대체상품 목록 조회 실패:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = () => {
    getList();
  };

  const handleDownloadExcel = async () => {
    try {
      await adminAPI.promotion.downloadSpareItemsExcel();
    } catch (error) {
      console.error("엑셀 다운로드 실패:", error);
    }
  };

  const handleAddSpareItem = () => {
    setModalMode("create");
    setSelectedSpareItem(null);
    setIsModalVisible(true);
  };

  const handleEditSpareItem = (item: ISpareItem) => {
    setModalMode("edit");
    setSelectedSpareItem(item);
    setIsModalVisible(true);
  };

  // 상품 상세 조회 함수 추가
  const handleItemDetail = async (itemNo: number) => {
    try {
      const { success, message, data } = await adminAPI.item.getDetail({
        no: itemNo
      });
      if (success) {
        setIsItemDetailModalVisible(true);
        setItemDetailData(data);
      } else {
        console.error("상품 조회 실패:", message);
      }
    } catch (error) {
      console.error("상품 조회 에러:", error);
    }
  };

  return (
    <>
      <SearchFilter
        // leftHeaders={["보기수", "시작일", "종료일", "검색"]}
        left={[
          <SelectBox
            key={1}
            list={[
              { label: "30개씩 보기", value: "30" },
              { label: "50개씩 보기", value: "50" },
              { label: "100개씩 보기", value: "100" }
            ]}
            value={itemsPerPage.toString()}
            singleSelect={val => setItemsPerPage(Number(val))}
            placeholder="개수"
            boxStyle={{ width: "140px" }}
          />,
          <InputBox
            key={2}
            type="date"
            value={startDate}
            onValue={setStartDate}
            placeholder="시작일"
          />,
          <InputBox
            key={3}
            type="date"
            value={endDate}
            onValue={setEndDate}
            placeholder="종료일"
          />,
          <InputBox
            key={4}
            value={searchText}
            onValue={setSearchText}
            placeholder="상품명 검색"
            onEnter={handleSearch}
            rightContent={
              <div style={{ display: "flex" }} onClick={handleSearch}>
                <FontAwesomeIcon icon={faSearch} />
              </div>
            }
          />
        ]}
        bottom={
          <div className="flex items-center gap-2">
            <Button
              isMainBtn
              text="신규 입력"
              icon={<FontAwesomeIcon icon={faPlus} />}
              onClick={handleAddSpareItem}
            />
            <Button
              text="다운로드"
              icon={<FontAwesomeIcon icon={faDownload} />}
              onClick={handleDownloadExcel}
            />
            <FileUploadButton
              text="일괄추가"
              callback={() => getList()}
              type={"excel"}
              uploadUrl={"/admin/excel/spare-items"}
            />
          </div>
        }
      />
      <Content>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%"
          }}
        >
          <ShadListTable
            data={spareItems || []}
            paging={paging}
            showExport={true}
            exportFileName="spareItemsList"
            columns={[
              {
                header: "번호",
                accessorKey: "no",
                csvOnly: true
              },
              {
                header: "상품번호",
                accessorKey: "item_no",
                csvOnly: true
              },
              {
                header: "상태",
                accessorKey: "active_yn",
                csvExclude: true,
                cell: row => {
                  const isActive = row.active_yn === "Y";
                  return (
                    <div>
                      {isActive ? (
                        <span className="px-1.5 py-0.5 bg-red-100 text-red-700 text-xs rounded">
                          사용됨
                        </span>
                      ) : (
                        <span className="px-1.5 py-0.5 bg-green text-white text-xs rounded">
                          대기중
                        </span>
                      )}
                    </div>
                  );
                }
              },
              {
                header: "시작일",
                accessorKey: "start_date",
                cell: row => {
                  const isActive = row.active_yn === "Y";
                  return (
                    <div className={cn(isActive && "text-gray-400")}>
                      {row.start_date}
                    </div>
                  );
                }
              },
              {
                header: "종료일",
                accessorKey: "end_date",
                cell: row => {
                  const isActive = row.active_yn === "Y";
                  return (
                    <div className={cn(isActive && "text-gray-400")}>
                      {row.end_date}
                    </div>
                  );
                }
              },
              {
                header: "상품명",
                accessorKey: "item_name",
                cell: row => {
                  const isActive = row.active_yn === "Y";

                  return (
                    <div className={cn("py-1 relative")}>
                      <div className="flex items-center">
                        <a
                          href="#"
                          onClick={() => handleEditSpareItem(row)}
                          className={cn(isActive && "text-gray-500")}
                        >
                          {row.item_name || row?.item?.item_name}
                        </a>

                        {row.item_no && (
                          <span
                            className="ml-2 text-xs text-indigo-600 underline cursor-pointer"
                            onClick={e => {
                              e.stopPropagation();
                              handleItemDetail(row.item_no);
                            }}
                          >
                            조회
                          </span>
                        )}
                      </div>
                    </div>
                  );
                },
                csv: row => {
                  return `${row.item_name || row?.item?.item_name || ""}`;
                }
              },
              {
                header: "변동가격",
                accessorKey: "spare_price",
                cell: row => {
                  const isActive = row.active_yn === "Y";
                  return (
                    <div
                      className={cn("font-normal", isActive && "text-gray-400")}
                    >
                      {row.spare_price
                        ? `${row.spare_price.toLocaleString()}원`
                        : "-"}
                    </div>
                  );
                }
              },
              {
                header: "할인율",
                accessorKey: "discount_rate",
                cell: row => {
                  const isActive = row.active_yn === "Y";
                  return (
                    <div
                      className={cn("font-normal", isActive && "text-gray-400")}
                    >
                      {row.discount_rate !== null &&
                      row.discount_rate !== undefined
                        ? `${row.discount_rate}%`
                        : "-"}
                    </div>
                  );
                }
              },
              {
                header: "한정수량",
                accessorKey: "spare_limit",
                cell: row => {
                  const isActive = row.active_yn === "Y";
                  return (
                    <div className={cn(isActive && "text-gray-400")}>
                      {row.spare_limit || "-"}
                    </div>
                  );
                }
              },
              {
                header: "활성여부",
                accessorKey: "active_yn",
                csvOnly: true,
                cell: row => {
                  return `${row.active_yn || ""}`;
                }
              },
              {
                header: "쿠폰상품적용",
                accessorKey: "is_coupon_product_yn",
                csvOnly: true,
                cell: row => {
                  return `${row.is_coupon_product_yn || ""}`;
                }
              }
            ]}
            getList={getList}
            className="w-full"
          />
        </div>
      </Content>

      <SpareItemModal
        isVisible={isModalVisible}
        close={() => setIsModalVisible(false)}
        refresh={getList}
        mode={modalMode}
        data={selectedSpareItem}
      />

      <ItemDetailModal
        isVisible={isItemDetailModalVisible}
        detailData={itemDetailData}
        close={() => setIsItemDetailModalVisible(false)}
      />
    </>
  );
}
