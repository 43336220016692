import React from "react";
import parse from "html-react-parser";
import { marked } from "marked";

import { Modal } from "@components";

interface Props {
  isVisible: boolean;
  onClose: () => void;
  contentData?: {
    no?: number;
    title?: string;
    expose_yn?: string;
    contentList?: {
      no?: number;
      title?: string;
      answer?: string;
    }[];
  };
}

export function ContentPreviewModal({
                                      isVisible,
                                      onClose,
                                      contentData
                                    }: Props) {
  if (!isVisible) {
    return null;
  }

  const convertMarkdownToHtml = (markdown: string) => {
    try {
      return marked(markdown);
    } catch (error) {
      console.error("마크다운 변환 오류:", error);
      return markdown;
    }
  };

  return (
    <Modal isVisible={isVisible} close={onClose}>
      {contentData ? (
        <div style={{ marginBottom: 20 }}>
          <div style={{ fontSize: 18, fontWeight: 700, lineHeight: 2 }}>
            {contentData.title}
          </div>
          <ul>
            {contentData.contentList
              ?.sort((a, b) => (a.no ?? 0) - (b.no ?? 0))
              .map((item, index) => (
                <div key={item.no}>
                  <li>
                    <p
                      style={{
                        fontSize: 16,
                        fontWeight: 700,
                        marginBottom: 10,
                        marginTop: 20
                      }}
                    >
                      Q{index + 1}. {parse(item.title ?? "")}
                    </p>
                  </li>
                  <li>
                    <p>{parse(convertMarkdownToHtml(item.answer ?? ""))}</p>
                  </li>
                </div>
              ))}
          </ul>
        </div>
      ) : (
        <p>No content available</p>
      )}
    </Modal>
  );
}
