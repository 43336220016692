import { faSearch, faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ContentLayout,
  Content,
  InputBox,
  SearchFilter,
  SelectBox,
  ListTable,
  Button
} from "@components";
import {
  ResultPagingResponse,
  IMemberListResponse,
  IMemberResponse,
  TMemberOrderSort,
  TMemberType
} from "@data";
import React, { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { states } from "@recoils";
import { adminAPI, constant, excelUtil, styleUtils, utils } from "@utils";
import styles from "./style.module.scss";
import moment from "moment";
import { MemberDetailModal } from "./MemberDetailModal";
import { MemberPointAddMultiModal } from "./MemberPointAddMultiModal";
import { MemberCouponAddMultiModal } from "./MemberCouponAddMultiModal";

const MemberTypeArr: { label: string; value: TMemberType | "" }[] = [
  { label: "전체", value: "" },
  // { label: "잠재고객", value: "member_0" },
  { label: "접촉고객", value: "member_1" },
  { label: "경험고객", value: "member_2" },
  { label: "재구매고객", value: "member_3" },
  { label: "로열티고객", value: "member_4" },
  { label: "이탈고객", value: "member_5" }
];
const MemberSortArr: { label: string; value: TMemberOrderSort | "" }[] = [
  { label: "정렬", value: "" },
  { label: "구매건순", value: "orderCntDESC" },
  { label: "구매금액순", value: "orderPriceDESC" },
  { label: "가입일순", value: "createdAtDESC" },
  // {label: "마지막수정일순", value: "updatedAtDESC"},
  { label: "마지막접속일순", value: "accessDateDESC" },
  { label: "가입상태순", value: "statusDESC" }
];
const MemberOrganicArr: { label: string; value: string }[] = [
  { label: "유입채널", value: "" },
  { label: "광고", value: "Non-organic" },
  { label: "자연유입", value: "organic" }
];

export default function MemberPage() {
  const setAlertModal = useSetRecoilState(states.alertState);
  const [listData, setListData] = useState<
    ResultPagingResponse<{
      list: IMemberListResponse[];
      memberCnt?: number;
      memberOutCnt?: number;
      purchaseMemberCnt?: number;
      todayJoinCnt?: number;
    }>
  >();
  const [searchText, setSearchText] = useState("");
  const [searchCreatedStartAt, setSearchCreatedStartAt] = useState<string>();
  const [searchCreatedEndAt, setSearchCreatedEndAt] = useState<string>();
  const [memberType, setMemberType] = useState<TMemberType>();
  const [sort, setSort] = useState<TMemberOrderSort>("createdAtDESC");
  const [grade, setGrade] = useState<string>("");
  const [isSubscribe, setIsSubscribe] = useState<boolean>();
  const [isGift, setIsGift] = useState<boolean>();
  const [isSupporters, setIsSupporters] = useState<boolean>();
  const [memberDetail, setMemberDetail] = useState<IMemberResponse>();
  const [isOpenPointModal, setIsOpenPointModal] = useState(false);
  const [isOpenCouponModal, setIsOpenCouponModal] = useState(false);
  const [memberOrganic, setMemberOrganic] = useState<string>("");
  const [gradeStartDate, setGradeStartDate] = useState<string>(
    moment().subtract(6, "months").format("YYYY-MM-DD")
  );
  const [gradeEndDate, setGradeEndDate] = useState<string>(
    moment().format("YYYY-MM-DD")
  );
  const [vvipStartDate, setVvipStartDate] = useState<string>(
    moment().subtract(6, "months").format("YYYY-MM-DD")
  );
  const [vvipEndDate, setVvipEndDate] = useState<string>(
    moment().format("YYYY-MM-DD")
  );
  const [pointStartDate, setPointStartDate] = useState<string>(
    moment().format("YYYY-MM-DD")
  );

  useEffect(() => {
    getList();
  }, [searchCreatedStartAt, searchCreatedEndAt]);

  async function getList({ page = 1 }: { page?: number } = {}) {
    try {
      const { data, success, message } = await adminAPI.member.getList({
        organic: memberOrganic,
        member_type: memberType,
        searchtxt: searchText,
        searchCreatedStartAt,
        searchCreatedEndAt,
        sort,
        grade,
        subscribe_y: isSubscribe,
        gift_apply_y: isGift,
        supporters_y: isSupporters,
        page
      });
      if (success) {
        setListData(data);
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다"
        });
      }
    } catch (error) {
      console.error("MemberPage getData error", error);
    }
  }

  async function memberListExcelDownload() {
    try {
      const { success, data, message } =
        await adminAPI.member.getExportMemberList({
          member_type: memberType,
          searchtxt: searchText,
          sort,
          grade,
          subscribe_y: isSubscribe,
          gift_apply_y: isGift,
          supporters_y: isSupporters,
          searchCreatedStartAt,
          searchCreatedEndAt
        });
      if (success) {
        excelUtil.excelDownload({
          excelData: data.result,
          filename: "memberFile"
        });
      } else {
        return setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다"
        });
      }
    } catch (error) {
      console.log("memberListExcelDownload error", error);
    }
  }

  async function getDetail(no: number) {
    try {
      const { data, success, message } = await adminAPI.member.getDetail({
        member_no: no
      });
      if (success) {
        setMemberDetail(data.result);
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다"
        });
      }
    } catch (error) {
      console.error("getDetail error", error);
    }
  }

  async function testGradeUpdate() {
    try {
      if (!confirm("테스트 등급갱신을 하시겠습니까?")) {
        return;
      }

      //gradeEndDate 기준의 달에서 날짜만 23일로 변경
      const memberPointDate = moment(gradeEndDate)
        .subtract(1, "months")
        .date(24)
        .format("YYYY-MM-DD");

      await adminAPI.apiGrade.updateMemberGradeMonthlyManual(
        gradeStartDate,
        gradeEndDate
      );
      await adminAPI.apiGrade.updateMemberPoint(memberPointDate);
      await adminAPI.apiGrade.updateMemberCoupon();
      getList();
    } catch (error) {
      console.error("testGradeUpdate error", error);
    }
  }

  async function testVVIPUpdate() {
    try {
      if (!confirm("테스트 VVIP 갱신을 하시겠습니까?")) {
        return;
      }
      await adminAPI.apiGrade.updateVVIPGradeManual(
        gradeStartDate,
        gradeEndDate
      );
      getList();
    } catch (error) {
      console.error("testGradeUpdate error", error);
    }
  }

  useEffect(() => {
    getList();
  }, [
    sort,
    isSubscribe,
    isGift,
    isSupporters,
    memberType,
    grade,
    memberOrganic
  ]);

  async function testPoint() {
    try {
      if (!confirm("테스트 적립금갱신을 하시겠습니까?")) {
        return;
      }
      await adminAPI.apiGrade.updateMemberPoint(pointStartDate);
      await getList();
    } catch (error) {
      console.error("testPoint error", error);
    }
  }

  async function testCoupon() {
    try {
      if (!confirm("테스트 쿠폰발급을 하시겠습니까?")) {
        return;
      }
      await adminAPI.apiGrade.updateMemberCoupon();
      await getList();
    } catch (error) {
      console.error("testCoupon error", error);
    }
  }

  return (
    <>
      <ContentLayout title="고객관리" buttons={[]}>
        <SearchFilter
          left={[
            <SelectBox
              key={0}
              multiple
              boxStyle={{
                minWidth: "80px"
              }}
              list={[
                {
                  label: "정기구독",
                  value: "subscribeY"
                },
                {
                  label: "체험혜택",
                  value: "giftApplyY"
                },
                {
                  label: "주피터즈",
                  value: "supportersY"
                }
              ]}
              value={[
                ...(isSubscribe ? ["subscribeY"] : []),
                ...(isGift ? ["giftApplyY"] : []),
                ...(isSupporters ? ["supportersY"] : [])
              ]}
              multipleSelect={(value = []) => {
                setIsSubscribe(
                  value.find(item => item === "subscribeY") ? true : undefined
                );
                setIsGift(
                  value.find(item => item === "giftApplyY") ? true : undefined
                );
                setIsSupporters(
                  value.find(item => item === "supportersY") ? true : undefined
                );
              }}
            />,
            <SelectBox
              key={0}
              list={MemberTypeArr}
              placeholder="고객유형"
              value={memberType}
              singleSelect={value => setMemberType(value as TMemberType)}
            />,
            <SelectBox
              key={1}
              boxStyle={{
                minWidth: "100px"
              }}
              list={MemberSortArr}
              value={sort}
              singleSelect={value => setSort(value as TMemberOrderSort)}
            />,
            <SelectBox
              key={2}
              boxStyle={{
                minWidth: "100px"
              }}
              list={constant.GRADE_LIST}
              value={grade}
              singleSelect={value => setGrade(value)}
            />,

            <SelectBox
              key={3}
              boxStyle={{
                minWidth: "100px"
              }}
              list={MemberOrganicArr}
              value={memberOrganic}
              singleSelect={value => setMemberOrganic(value)}
            />,
            <InputBox
              key={4}
              type="date"
              placeholder="가입일"
              value={searchCreatedStartAt}
              onValue={value =>
                setSearchCreatedStartAt(moment(value).format("YYYY-MM-DD"))
              }
              onEnter={getList}
            />,
            <> ~ </>,
            <InputBox
              key={5}
              type="date"
              placeholder="가입일"
              value={searchCreatedEndAt}
              onValue={value =>
                setSearchCreatedEndAt(moment(value).format("YYYY-MM-DD"))
              }
              onEnter={getList}
            />,
          ]}
          search={
            <InputBox
              key={6}
              placeholder="검색"
              rightContent={
                <div
                  style={{
                    display: "flex"
                  }}
                  onClick={() => getList()}
                >
                  <FontAwesomeIcon icon={faSearch} />
                </div>
              }
              value={searchText}
              onValue={setSearchText}
              onEnter={getList}
            />
          }
          right={[
            <Button
              key={1}
              text="포인트지급"
              fontSize={12}
              backgroundColor={styleUtils.COLOR.baige}
              onClick={() => setIsOpenPointModal(true)}
            />,
            <Button
              key={2}
              text="쿠폰지급"
              fontSize={12}
              backgroundColor={styleUtils.COLOR.lightGreenColor}
              onClick={() => setIsOpenCouponModal(true)}
            />
          ]}
        />
        <Content>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%"
            }}
          >
            <div
              style={{
                display: "flex",
                alignContent: "center",
                alignItems: "center",
                justifyContent: "space-between"
              }}
            >
              <div className={["col", "text-12"].join(" ")}>
                <span
                  style={{
                    marginRight: "8px"
                  }}
                >
                  총고객수{" "}
                  <span className={[styles.badge, styles.total].join(" ")}>
                    {(listData?.result.memberCnt || 0).toLocaleString()}
                  </span>
                </span>
                <span
                  style={{
                    marginRight: "8px"
                  }}
                >
                  조회고객수{" "}
                  <span className={[styles.badge, styles.viewTotal].join(" ")}>
                    {(listData?.page?.maxcnt || 0).toLocaleString()}
                  </span>
                </span>
                <span
                  style={{
                    marginRight: "8px"
                  }}
                >
                  구매고객{" "}
                  <span className={[styles.badge, styles.purchase].join(" ")}>
                    {(listData?.result.purchaseMemberCnt || 0).toLocaleString()}
                  </span>
                </span>
                <span
                  style={{
                    marginRight: "8px"
                  }}
                >
                  오늘가입{" "}
                  <span className={[styles.badge, styles.today].join(" ")}>
                    {(listData?.result.todayJoinCnt || 0).toLocaleString()}
                  </span>
                </span>
                <span
                  style={{
                    marginRight: "8px"
                  }}
                >
                  탈퇴고객{" "}
                  <span className={[styles.badge, styles.out].join(" ")}>
                    {(listData?.result.memberOutCnt || 0).toLocaleString()}
                  </span>
                </span>
              </div>
              <div className={["col", "flex-end"].join(" ")}>
                <div>
                  <Button
                    icon={
                      <div>
                        <FontAwesomeIcon icon={faDownload} />
                      </div>
                    }
                    onClick={memberListExcelDownload}
                    backgroundColor={styleUtils.COLOR.blueLight}
                  />
                </div>
              </div>
            </div>
            <ListTable paging={listData?.page} getList={getList}>
              <thead>
                <tr>
                  <th>번호</th>
                  <th>핸드폰번호</th>
                  <th>닉네임</th>
                  <th>고객명</th>
                  <th>디바이스</th>
                  <th>진입경로</th>
                  <th>구매수</th>
                  <th>구매금액</th>
                  <th>가입상태</th>
                  <th>등급</th>
                  <th>가입일</th>
                  <th>마지막접속일</th>
                </tr>
              </thead>
              <tbody>
                {(listData?.result?.list || []).map((item, index) => (
                  <tr
                    key={index}
                    onClick={() => !!item.no && getDetail(item.no)}
                  >
                    <td>{item.no}</td>
                    <td>{utils.formatPhoneNumber(item.phone_number || "")}</td>
                    <td>{item.nickname}</td>
                    <td>{item.name}</td>
                    <td>{item.app_device}</td>
                    <td>
                      {item.af_status === "Non-organic" ? (
                        <>
                          <span
                            className={[styles.badge, styles.advertise].join(
                              " "
                            )}
                          >
                            광고
                          </span>
                        </>
                      ) : (
                        <span className={[styles.badge].join(" ")}>
                          앱스토어
                        </span>
                      )}
                    </td>
                    <td>{(item.order_count || 0).toLocaleString()}</td>
                    <td>{(item.order_price || 0).toLocaleString()}</td>
                    <td>
                      {item.del_yn === "Y" ? (
                        <span className={[styles.badge, styles.out].join(" ")}>
                          탈퇴
                        </span>
                      ) : (
                        <span
                          className={[styles.badge, styles.purchase].join(" ")}
                        >
                          정상
                        </span>
                      )}
                    </td>
                    <td>
                      <div className="row">
                        <div className="col">{item.grade?.grade_name}</div>
                        <div className="col">
                          <img
                            src={item.grade?.img_url}
                            style={{
                              width: "30px"
                            }}
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      {item.createdAt ? (
                        <>
                          {moment(item.createdAt).format("YYYY-MM-DD")}
                          <br />
                          {moment(item.createdAt).format("HH:mm")}
                        </>
                      ) : (
                        ""
                      )}
                    </td>
                    <td>
                      {item.last_access_date ? (
                        <>
                          {moment(item.last_access_date).format("YYYY-MM-DD")}
                          <br />
                          {moment(item.last_access_date).format("HH:mm")}
                        </>
                      ) : (
                        ""
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </ListTable>
          </div>
        </Content>
      </ContentLayout>
      <MemberDetailModal
        isVisible={!!memberDetail}
        close={() => setMemberDetail(undefined)}
        member={memberDetail}
        refresh={() => !!memberDetail?.no && getDetail(memberDetail.no)}
      />
      {isOpenPointModal && (
        <MemberPointAddMultiModal
          isVisible={isOpenPointModal}
          close={() => setIsOpenPointModal(false)}
        />
      )}
      {isOpenCouponModal && (
        <MemberCouponAddMultiModal
          isVisible={isOpenCouponModal}
          close={() => setIsOpenCouponModal(false)}
        />
      )}
    </>
  );
}
