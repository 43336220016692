import React from "react";
import { Chart, registerables } from "chart.js";
import { RootNavigation } from "@navigation";
import { AlertModal, Loader } from "@components";
import { useRecoilValue } from "recoil";
import { states } from "@recoils";
import { ToastContainer } from "react-toastify";

Chart.register(...registerables);

export default function App() {
    const loading = useRecoilValue(states.loadingState);

    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                closeOnClick
                pauseOnHover
            />
            <RootNavigation />
            <AlertModal />
            <Loader loading={loading} />
        </>
    );
}
