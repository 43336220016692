import { Layout } from "components";
import { useLogin } from "@hook";
import { LoginPage } from "@pages";
import React, { useEffect } from "react";
import { AdminRouteApp, AdminPopupRoute } from "@navigation";
import { Route, Routes, useLocation, useParams } from "react-router-dom";
import * as Pages from "@pages";

export default function AdminNavigation() {
  const { token, getAdminData } = useLogin();
  const location = useLocation();
  const checkUser = location.pathname.includes("addcontents");

  useEffect(() => {
    getAdminData();
  }, []);

  return (
    <>
      {checkUser ? (
        <Routes>
          <Route path="/addcontents/:id" element={<Pages.ContentsUserPage />} />
        </Routes>
      ) : !token ? (
        <LoginPage />
      ) : (
        <Routes>
          <Route path="/pop/*" element={<AdminPopupRoute />} />
          <Route
            path="/*"
            element={
              <Layout>
                <AdminRouteApp />
              </Layout>
            }
          />
        </Routes>
      )}
    </>
  );
}
