import {ChartData} from "chart.js";
import {Content, InputBox, ListTable, SearchFilter} from "@components";
import {IDashBoardOrderResponse} from "@data";
import moment from "moment";
import React, {useEffect, useState} from "react";
import {Line} from "react-chartjs-2";
import {useSetRecoilState} from "recoil";
import {states} from "@recoils";
import {adminAPI, styleUtils} from "@utils";
import styles from "./style.module.scss";

const chartOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
    title: {
      display: false,
    },
    plugins: {
      legend: {display: false},
      tooltip: {
        callbacks: {
          title: () => null,
        },
      },
    },
    scales: {
      x: {stacked: false},
      y: {stacked: false},
    },
  },
  maintainAspectRatio: false,
};

interface ISumData {
  total_price?: number;
  total_count?: number;
  avg_price?: number;
  avg_count?: number;
  profit_price?: number;
  avg_profit_price?: number;
}

export function DashBoardOrderView() {
  const setAlertModal = useSetRecoilState(states.alertState);
  const [startDate, setStartDate] = useState(
    moment().add(-7, "day").format("YYYY-MM-DD"),
  );
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [dashboardData, setDashboardData] =
    useState<IDashBoardOrderResponse[]>();
  const [countChartData, setCountChartData] = useState<ChartData<any>>();
  const [priceChartData, setPriceChartData] = useState<ChartData<any>>();
  const [profitpriceChartData, setProfitPriceChartData] =
    useState<ChartData<any>>();
  const [sumData, setSumData] = useState<ISumData>();

  async function getData() {
    try {
      const {data, success, message} = await adminAPI.dashboard.getOrderData({
        start_date: startDate,
        end_date: endDate,
      });
      if (success) {
        setDashboardData(data);
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.error("getData error", error);
    }
  }

  function convertChartData() {
    try {
      if (!dashboardData) {
        return;
      }
      const data = dashboardData.sort((a, b) => {
        return moment(a.date).diff(moment(b.date)) > 0 ? 1 : -1;
      });

      const labels = data.map(item => moment(item.date).format("M/D"));
      const countDatasets = [
        {
          label: "구매수",
          data: data.map(item => Number(item.total_count || 0)),
          borderColor: styleUtils.COLOR.orange,
        },
      ];
      const priceDatasets = [
        {
          label: "결제금액",
          data: data.map(item => Number(item.total_price || 0)),
          borderColor: styleUtils.COLOR.green,
        },
      ];
      const profitPriceDatasets = [
        {
          label: "수익금액",
          data: data.map(item => Number(item.profit_price || 0)),
          borderColor: styleUtils.COLOR.blue,
        },
      ];

      setCountChartData({
        labels,
        datasets: countDatasets,
      });
      setPriceChartData({
        labels,
        datasets: priceDatasets,
      });
      setProfitPriceChartData({
        labels,
        datasets: profitPriceDatasets,
      });
    } catch (error) {
      console.error("convertChartData error", error);
    }
  }

  function changeSumData() {
    if (!dashboardData) {
      return;
    }
    const len = dashboardData.length;
    let total_count = 0,
      total_price = 0,
      profit_price = 0;
    (dashboardData || []).forEach(item => {
      total_count += item.total_count || 0;
      total_price += item.total_price || 0;
      profit_price += item.profit_price || 0;
    });

    setSumData({
      total_count,
      total_price,
      avg_count: total_count / len,
      avg_price: total_price / len,
      profit_price,
      avg_profit_price: profit_price / len,
    });
  }

  useEffect(() => {
    getData();
  }, [startDate, endDate]);

  useEffect(() => {
    if (dashboardData) {
      convertChartData();
      changeSumData();
    }
  }, [dashboardData]);

  return (
    <>
      <SearchFilter
        left={[
          <div key={0} className="row center">
            <div className="col">
              <InputBox
                type={"date"}
                placeholder="시작일"
                value={startDate}
                onValue={setStartDate}
                onEnter={getData}
              />
            </div>
            <div className="col">~</div>
            <div className="col">
              <InputBox
                type={"date"}
                placeholder="종료일"
                value={endDate}
                onValue={setEndDate}
                onEnter={getData}
              />
            </div>
          </div>,
        ]}
      />
      <Content>
        <div style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}>
          <div>
            <div className={["text-12", "text-right"].join(" ")}>
              (수익은 배송비/사은품/할인 등에 의해 실제와 다를 수 있습니다.
              참고용으로만 봐주세요.)
            </div>
            <ListTable>
              <thead>
                <tr>
                  <th></th>
                  <th>구매수</th>
                  <th>결제금액</th>
                  <th>수익</th>
                  <th>수익률</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>평균</td>
                  <td>{Number(sumData?.avg_count || 0).toLocaleString()} 건</td>
                  <td>
                    {Number(
                      Number(sumData?.avg_price || 0).toFixed(0),
                    ).toLocaleString()}{" "}
                    원
                  </td>
                  <td>
                    {Number(
                      Number(sumData?.avg_profit_price || 0).toFixed(0),
                    ).toLocaleString()}{" "}
                    원
                  </td>
                  <td>
                    {Number(
                      Number(
                        ((sumData?.profit_price || 0) /
                          (sumData?.total_price || 0)) *
                          100,
                      ).toFixed(0),
                    ).toLocaleString()}{" "}
                    %
                  </td>
                </tr>
                <tr>
                  <td>총계</td>
                  <td>
                    {Number(sumData?.total_count || 0).toLocaleString()} 건
                  </td>
                  <td>
                    {Number(
                      Number(sumData?.total_price || 0).toFixed(0),
                    ).toLocaleString()}{" "}
                    원
                  </td>
                  <td>
                    {Number(
                      Number(sumData?.profit_price || 0).toFixed(0),
                    ).toLocaleString()}{" "}
                    원
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td>객단가</td>
                  <td></td>
                  <td>
                    {Number(
                      (
                        (sumData?.total_price || 0) /
                        (sumData?.total_count || 0)
                      ).toFixed(0),
                    ).toLocaleString()}{" "}
                    원
                  </td>
                  <td>
                    {Number(
                      (
                        (sumData?.profit_price || 0) /
                        (sumData?.total_count || 0)
                      ).toFixed(0),
                    ).toLocaleString()}{" "}
                    원
                  </td>
                  <td></td>
                </tr>
              </tbody>
            </ListTable>
          </div>
          {!!countChartData && (
            <div className={styles.signupChartContainer}>
              <Line options={chartOptions} data={countChartData} />
            </div>
          )}
          {!!priceChartData && (
            <div className={styles.signupChartContainer}>
              <Line options={chartOptions} data={priceChartData} />
            </div>
          )}
          {!!profitpriceChartData && (
            <div className={styles.signupChartContainer}>
              <Line options={chartOptions} data={profitpriceChartData} />
            </div>
          )}
        </div>
      </Content>
    </>
  );
}
