import moment from "moment";
import React, { useEffect } from "react";
import {
  Calendar as BigCalendar,
  momentLocalizer,
  ToolbarProps,
  Event as TEvent,
  CalendarProps,
  DateLocalizer,
  Views
} from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";

interface IProps extends CalendarProps<TEvent, object> {
  localizer: DateLocalizer;
  eventList?: TEvent[];
}

export default function Calendar({ eventList, ...props }: IProps) {
  moment.locale("ko-KR");
  const localizer = momentLocalizer(moment);

  // 팝업 창의 최대 높이를 설정하는 CSS 추가
  const customComponents = {
    toolbar: Toolbar,
    agenda: {
      event: EventAgenda
    }
  };

  // CSS를 동적으로 추가하는 useEffect 사용
  useEffect(() => {
    // 이미 존재하는 스타일 요소가 있는지 확인
    const existingStyle = document.getElementById("calendar-popup-style");
    if (!existingStyle) {
      const style = document.createElement("style");
      style.id = "calendar-popup-style";
      style.innerHTML = `
        .rbc-overlay {
          max-height: 400px;
          overflow-y: auto;
        }
      `;
      document.head.appendChild(style);
    }

    // 컴포넌트가 언마운트될 때 스타일 제거
    return () => {
      const styleElement = document.getElementById("calendar-popup-style");
      if (styleElement) {
        styleElement.parentNode?.removeChild(styleElement);
      }
    };
  }, []);

  return (
    <BigCalendar
      {...props}
      localizer={localizer}
      events={eventList}
      style={{ height: 1000 }}
      components={customComponents}
      popup={true}
      messages={{
        showMore: total => `+${total} 더보기`
      }}
    />
  );
}

function Toolbar(props: ToolbarProps) {
  const { date } = props;

  const navigate = (action: any) => {
    props.onNavigate(action);
  };

  return (
    <div className="rbc-toolbar">
      <span className="rbc-btn-group" style={{ display: "flex", flex: 1 }}>
        <div className="row" style={{ justifyContent: "space-between" }}>
          <div>
            <button type="button" onClick={navigate.bind(null, "TODAY")}>
              이번달
            </button>
          </div>
          <div className="col">
            <span className="rbc-toolbar-label">{`${date.getFullYear()}년 ${
              date.getMonth() + 1
            }월`}</span>
          </div>
          <div>
            <button type="button" onClick={navigate.bind(null, "PREV")}>
              이전
            </button>
            <button type="button" onClick={navigate.bind(null, "NEXT")}>
              다음
            </button>
          </div>
        </div>
      </span>
    </div>
  );
}

function EventAgenda({ event }: { event: TEvent }) {
  return (
    <span>
      <strong>{event.title}</strong>
      {/* {event.desc && ": " + event.desc} */}
    </span>
  );
}
