import {
  Button,
  CheckBox,
  ContentLayout,
  Content,
  InputBox,
  ListTable,
  SearchFilter,
  SelectBox,
  FileUploadButton,
} from "@components";
import {
  ORDER_DELIVERY_STATUS,
  ORDER_STATUS,
  ORDER_STATUS_COLOR,
  ORDER_STATUS_TEXT_COLOR,
  ResultPagingResponse,
  TDBOrderDeliveryStatus,
  TDBOrderStatus,
  TDeliveryType,
  TOrderBy,
  TOrderListDateType,
  TOrderListOrering,
  TypeYNWithEmpty,
  IOrderListRequest,
  IOrderDetail,
  IOrderList,
} from "@data";
import _ from "lodash";
import moment from "moment";
import React, {useEffect, useState} from "react";
import {useSetRecoilState} from "recoil";
import {states} from "@recoils";
import {adminAPI, excelUtil, styleUtils, utils} from "@utils";
import styles from "./style.module.scss";
import {OrderDetailModal} from "./OrderDetailModal";

export default function OrderPage() {
  const setAlertModal = useSetRecoilState(states.alertState);
  const [filter, setFilter] = useState<IOrderListRequest>({
    page: 1,
    limit: 20,
    start_date: moment().add(-1, "d").format("YYYY-MM-DD"),
    end_date: moment().format("YYYY-MM-DD"),
    ordering: "createdAt",
    orderBy: "DESC",
    date_type: "createdAt",
    delivery_type: [],
  });
  const [listData, setListData] = useState<
    ResultPagingResponse<{
      list?: IOrderList[];
      sum?: {
        total_item_count?: number;
        total_order_price?: number;
        avg_distinct_item_count?: number;
      };
    }>
  >();
  const [detailData, setDetailData] = useState<IOrderDetail>();
  const [selectNoList, setSelectNoList] = useState<number[]>([]);

  const isAllSelected =
    (listData?.result.list || []).filter(
      item => !!item.no && !selectNoList.includes(item.no),
    ).length === 0;

  async function getList({page = 1}: {page?: number} = {}) {
    try {
      setSelectNoList([]);
      const {data, success, message} = await adminAPI.order.apiGetOrderList({
        ...filter,
        page,
      });
      if (success) {
        setListData(data);
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.error("getList error", error);
    }
  }

  async function getDetail({no}: {no: number}) {
    try {
      const {data, success, message} = await adminAPI.order.apiGetOrderDetail({
        no,
      });
      if (success) {
        setDetailData(data);
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.error("getDetail error", error);
    }
  }

  async function exportExcel(isTeamFresh = false) {
    try {
      const list = [
        ...new Set(
          selectNoList
            .filter(item => !!item && !isNaN(item))
            .map(item => Number(item)),
        ),
      ];

      if (list.length === 0) {
        return setAlertModal({
          isVisible: true,
          title: "주문건을 선택해주세요.",
        });
      }

      const {success, data, message} = isTeamFresh
        ? await adminAPI.order.apiExportExcelTeamFreshOrderList({
            list,
          })
        : await adminAPI.order.apiExportExcelOrderList({
            list,
          });
      if (success) {
        excelUtil.excelDownload({
          excelData: data.result,
          filename: "orderFile",
        });
      } else {
        return setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.error("exportExcel error", error);
    }
  }

  useEffect(() => {
    getList();
  }, []);

  return (
    <>
      <ContentLayout title="주문관리">
        <SearchFilter
          left={[
            <div
              key={0}
              style={{display: "flex", flexDirection: "column", flex: 1}}>
              <div className="row">
                <div className={styles.filterTitle}>주문통합검색</div>
                <div className={styles.col}>
                  <div className="row">
                    <div className="col">
                      <SelectBox
                        multiple
                        value={filter.status}
                        placeholder="주문상태"
                        list={(
                          [
                            "1",
                            "3",
                            "4",
                            "5",
                            "6",
                            "2",
                            "7",
                          ] as TDBOrderStatus[]
                        ).map(item => ({
                          value: item,
                          label: ORDER_STATUS[item],
                        }))}
                        multipleSelect={value =>
                          setFilter({
                            ...filter,
                            status: value as TDBOrderStatus[],
                          })
                        }
                      />
                    </div>
                    <div className="col">
                      <SelectBox
                        multiple
                        value={filter.delivery_type}
                        placeholder="주문구분"
                        list={(
                          ["새벽", "일반", "도서산간"] as TDeliveryType[]
                        ).map(item => ({
                          value: item,
                          label: item,
                        }))}
                        multipleSelect={value =>
                          setFilter({
                            ...filter,
                            delivery_type: value as TDeliveryType[],
                          })
                        }
                      />
                    </div>
                    <div className="col">
                      <SelectBox
                        value={filter.subscribe_yn}
                        placeholder="구독여부"
                        list={
                          [
                            {value: "", label: "전체"},
                            {value: "Y", label: "정기구독"},
                            {value: "N", label: "일반구매"},
                          ] as {
                            value: TypeYNWithEmpty;
                            label: string;
                          }[]
                        }
                        singleSelect={value =>
                          setFilter({
                            ...filter,
                            subscribe_yn: value as TypeYNWithEmpty,
                          })
                        }
                      />
                    </div>
                    <div className="col">
                      <SelectBox
                        value={filter.delivery_status}
                        placeholder="배송상태"
                        list={(["1", "2", "3"] as TDBOrderDeliveryStatus[]).map(
                          item => ({
                            value: item,
                            label: ORDER_DELIVERY_STATUS[item],
                          }),
                        )}
                        singleSelect={value =>
                          setFilter({
                            ...filter,
                            delivery_status: value as TDBOrderDeliveryStatus,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <InputBox
                        placeholder="주문자명"
                        value={filter.name}
                        onValue={value => setFilter({...filter, name: value})}
                        onEnter={getList}
                      />
                    </div>
                    <div className="col">
                      <InputBox
                        placeholder="주문자휴대폰"
                        value={filter.phone}
                        onValue={value => setFilter({...filter, phone: value})}
                        onEnter={getList}
                      />
                    </div>
                    <div className="col">
                      <InputBox
                        placeholder="주문번호"
                        value={filter.order_number}
                        onValue={value =>
                          setFilter({...filter, order_number: value})
                        }
                        onEnter={getList}
                      />
                    </div>
                    <div className="col">
                      <InputBox
                        placeholder="송장번호"
                        value={filter.courier_no}
                        onValue={value =>
                          setFilter({...filter, courier_no: value})
                        }
                        onEnter={getList}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="row" style={{marginBottom: 0}}>
                  <div className={styles.filterTitle}>주문검색날짜</div>
                  <div className={styles.col}>
                    <div className="row">
                      <div className="col">
                        <SelectBox
                          value={filter.date_type}
                          list={
                            [
                              {value: "createdAt", label: "주문일"},
                              {value: "output_date", label: "출고일"},
                              {value: "delivery_date", label: "도착일"},
                            ] as {value: TOrderListDateType; label: string}[]
                          }
                          singleSelect={value =>
                            setFilter({
                              ...filter,
                              date_type: value as TOrderListDateType,
                            })
                          }
                        />
                      </div>
                      <div className="col">
                        <InputBox
                          value={filter.start_date}
                          type={"date"}
                          onValue={value =>
                            setFilter({...filter, start_date: value})
                          }
                          onEnter={getList}
                        />
                      </div>
                      <div className="col">~</div>
                      <div className="col">
                        <InputBox
                          value={filter.end_date}
                          type={"date"}
                          onValue={value =>
                            setFilter({...filter, end_date: value})
                          }
                          onEnter={getList}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={styles.col}>
                    <div className="row">
                      <div className="col">
                        <Button
                          text="오늘"
                          fontSize={12}
                          marginHorizontal={2}
                          onClick={() =>
                            setFilter({
                              ...filter,
                              start_date: moment().format("YYYY-MM-DD"),
                              end_date: moment().format("YYYY-MM-DD"),
                            })
                          }
                        />
                      </div>
                      <div className="col">
                        <Button
                          text="2일"
                          fontSize={12}
                          marginHorizontal={2}
                          onClick={() =>
                            setFilter({
                              ...filter,
                              start_date: moment()
                                .add(-1, "days")
                                .format("YYYY-MM-DD"),
                              end_date: moment().format("YYYY-MM-DD"),
                            })
                          }
                        />
                      </div>
                      <div className="col">
                        <Button
                          text="7일"
                          fontSize={12}
                          marginHorizontal={2}
                          onClick={() =>
                            setFilter({
                              ...filter,
                              start_date: moment()
                                .add(-7, "days")
                                .format("YYYY-MM-DD"),
                              end_date: moment().format("YYYY-MM-DD"),
                            })
                          }
                        />
                      </div>
                      <div className="col">
                        <Button
                          text="한달"
                          fontSize={12}
                          marginHorizontal={2}
                          onClick={() =>
                            setFilter({
                              ...filter,
                              start_date: moment()
                                .add(-1, "month")
                                .format("YYYY-MM-DD"),
                              end_date: moment().format("YYYY-MM-DD"),
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className={styles.filterTitle}>상품명</div>
                  <div className="col">
                    <div>
                      <InputBox
                        value={filter.item_name}
                        placeholder="상품명"
                        onValue={value =>
                          setFilter({...filter, item_name: value})
                        }
                        onEnter={getList}
                      />
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className={styles.filterTitle}>정렬</div>
                  <div className="col">
                    <div className="row">
                      <div className={styles.col}>
                        <SelectBox
                          value={filter.ordering}
                          list={[
                            {value: "createdAt", label: "주문일"},
                            {value: "output_date", label: "출고일"},
                            {value: "orderPrice", label: "결제금액"},
                            {value: "status", label: "주문상태"},
                          ]}
                          singleSelect={value =>
                            setFilter({
                              ...filter,
                              ordering: value as TOrderListOrering,
                            })
                          }
                        />
                      </div>
                      <div className={styles.col}>
                        <SelectBox
                          value={filter.orderBy}
                          list={[
                            {value: "DESC", label: "내림차순"},
                            {value: "ASC", label: "오름차순"},
                          ]}
                          singleSelect={value =>
                            setFilter({
                              ...filter,
                              orderBy: value as TOrderBy,
                            })
                          }
                        />
                      </div>
                      <div className={styles.col}>
                        <SelectBox
                          value={String(filter.limit)}
                          list={[20, 50, 100, 200, 300, 500].map(item => ({
                            value: String(item),
                            label: String(item),
                          }))}
                          singleSelect={value =>
                            setFilter({
                              ...filter,
                              limit: Number(value),
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>,
          ]}
          bottom={
            <>
              <div>
                <Button
                  text="검색"
                  onClick={getList}
                  fontSize={14}
                  backgroundColor={styleUtils.COLOR.green}
                  fontColor={styleUtils.COLOR.white}
                />
              </div>
              <div>
                <Button
                  text="주문서 엑셀"
                  onClick={() => exportExcel(false)}
                  fontSize={12}
                  backgroundColor={styleUtils.COLOR.orange}
                />
              </div>
              <div>
                <FileUploadButton
                  text="송장업로드"
                  callback={() => getList({page: listData?.page?.cur || 1})}
                  type={"excel"}
                  uploadUrl={"/admin/order/importExcel"}
                />
              </div>
              <div>
                <Button
                  text="팀프레시 주문서"
                  onClick={() => exportExcel(true)}
                  fontSize={12}
                  marginHorizontal={2}
                  backgroundColor={styleUtils.COLOR.gray8}
                />
              </div>
              <div>
                <FileUploadButton
                  text="팀프레시 송장업로드"
                  callback={() => getList({page: listData?.page?.cur || 1})}
                  type={"excel"}
                  uploadUrl={"/admin/order/importExcelTeamFresh"}
                />
              </div>
            </>
          }
        />
        <Content>
          <div style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}>
            <div className="text-12" style={{marginBottom: "10px"}}>
              <span style={{marginRight: "8px"}}>
                조회 주문 건수{" "}
                <span className={styles.badge}>
                  {(listData?.page?.maxcnt || 0).toLocaleString()}
                </span>
              </span>
              <span style={{marginRight: "8px"}}>
                조회 결제금액{" "}
                {(
                  listData?.result.sum?.total_order_price || 0
                ).toLocaleString()}
                원
              </span>
              <span>
                (총{" "}
                {(listData?.result.sum?.total_item_count || 0).toLocaleString()}
                수량) 주문당 평균 상품수{" "}
                {(
                  listData?.result.sum?.avg_distinct_item_count || 0
                ).toLocaleString()}
                개
              </span>
            </div>
            <ListTable paging={listData?.page} getList={getList}>
              <thead>
                <tr>
                  <th>
                    <CheckBox
                      checked={isAllSelected}
                      text=""
                      onChange={() => {
                        if (isAllSelected) {
                          setSelectNoList([]);
                        } else {
                          setSelectNoList(
                            (listData?.result?.list || [])
                              .map(data => data.no)
                              .filter(utils.isDefined),
                          );
                        }
                      }}
                    />
                  </th>
                  <th>주문구분</th>
                  <th>주문번호</th>
                  <th>주문자</th>
                  <th>전화번호</th>
                  <th>수신인</th>
                  <th>제품명</th>
                  <th>
                    주문금액
                    <br />
                    (배송비)
                  </th>
                  <th>최종금액</th>
                  <th>주문상태</th>
                  <th>주문일</th>
                  <th>출고일</th>
                  <th>도착예정일</th>
                </tr>
              </thead>
              <tbody>
                {(listData?.result?.list || []).map((data, index) => {
                  const checked =
                    selectNoList.filter(no => data.no === no).length > 0;

                  const deliveryTypeBadge =
                    data.delivery_type === "새벽"
                      ? styles.dawn
                      : data.delivery_type === "도서산간"
                      ? styles.plus
                      : "";
                  const statusColor = data.status
                    ? ORDER_STATUS_COLOR[data.status]
                    : "";
                  const statusTextColor = data.status
                    ? ORDER_STATUS_TEXT_COLOR[data.status]
                    : "";

                  const subscribe_detail = (data.detail || []).filter(
                    _detail =>
                      _detail.cancel_yn === "N" && _detail.refund_yn === "N",
                  );
                  const subscribe_temp_count = data.subscribe_no
                    ? subscribe_detail.filter(
                        _detail => _detail.subscribe_yn === "N",
                      ).length
                    : 0;

                  return (
                    <tr
                      key={_.uniqueId()}
                      onClick={() => !!data.no && getDetail({no: data.no})}>
                      <td onClick={e => utils.handleClickBubble(e)}>
                        <CheckBox
                          checked={checked}
                          text=""
                          onChange={e => {
                            if (checked) {
                              setSelectNoList([
                                ...selectNoList.filter(no => no !== data.no),
                              ]);
                            } else if (data.no) {
                              setSelectNoList([...selectNoList, data.no]);
                            }
                          }}
                        />
                      </td>
                      <td style={{flexDirection: "column"}}>
                        <span
                          className={[styles.badge, deliveryTypeBadge].join(
                            " ",
                          )}>
                          {data.delivery_type}
                        </span>
                        {!!data.subscribe_no && (
                          <div>
                            <span
                              className={[
                                styles.badge,
                                styles.subscribeBadge,
                              ].join(" ")}>
                              정기구독
                            </span>
                          </div>
                        )}
                        {subscribe_temp_count > 0 && (
                          <div>
                            <span
                              className={[
                                styles.badge,
                                styles.subscribeBadge,
                              ].join(" ")}>
                              함께담기
                            </span>
                          </div>
                        )}
                      </td>
                      <td>
                        ({data.no})<br />
                        {data.order_number}
                      </td>
                      <td>
                        {data.member?.name}
                        {!!data.member?.nickname &&
                          ` (${data.member?.nickname})`}
                        {!!data.member?.coupon_add_disable_date &&
                          moment(
                            data.member?.coupon_add_disable_date,
                          ).isValid() &&
                          moment(data.createdAt).isBefore(
                            moment(data.member?.coupon_add_disable_date),
                          ) && (
                            <>
                              <br />
                              <span className="text-12">(주피터즈)</span>
                            </>
                          )}
                      </td>
                      <td>
                        {utils.formatPhoneNumber(
                          data.member?.phone_number || "",
                        )}
                      </td>
                      <td>{data.sender_name}</td>
                      <td>
                        {subscribe_detail?.[0]?.item_name}
                        {(subscribe_detail || []).length > 1 &&
                          ` 외 ${(subscribe_detail || []).length - 1}건`}
                      </td>
                      <td>
                        {(data.total_price || 0).toLocaleString()}
                        <br />
                        <span className="text-12">
                          ({(data.delivery_price || 0).toLocaleString()})
                        </span>
                      </td>
                      <td>{(data.final_price || 0).toLocaleString()}</td>
                      <td>
                        <span
                          className={styles.badge}
                          style={{
                            background: statusColor,
                            color: statusTextColor,
                          }}>
                          {data.status ? ORDER_STATUS[data.status] : ""}
                        </span>
                      </td>
                      <td>
                        {moment(data.createdAt).format("YYYY-MM-DD")}
                        <br />
                        <span className="text-12">
                          {moment(data.createdAt).format("HH:mm:ss")}
                        </span>
                      </td>
                      <td>
                        {moment(data.estimate_output_date).format("YYYY-MM-DD")}
                        <br />
                        <span className="text-10">
                          (주문일 +
                          {moment(data.estimate_output_date)
                            .startOf("day")
                            .diff(
                              moment(data.createdAt).startOf("day"),
                              "days",
                            )}
                          )
                        </span>
                      </td>
                      <td>
                        {moment(data.estimate_delivery_date).format(
                          "YYYY-MM-DD",
                        )}
                        <br />
                        <span className="text-10">
                          (출고일 +
                          {moment(data.estimate_delivery_date)
                            .startOf("day")
                            .diff(
                              moment(data.estimate_output_date).startOf("day"),
                              "days",
                            )}
                          )
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </ListTable>
          </div>
        </Content>
      </ContentLayout>
      <OrderDetailModal
        isVisible={!!detailData}
        close={() => setDetailData(undefined)}
        data={detailData}
        refresh={() => {
          if (detailData?.no) {
            getDetail({no: detailData.no});
          }
        }}
      />
    </>
  );
}
