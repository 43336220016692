import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  CheckBox,
  ContentLayout,
  Content,
  InputBox,
  ListTable,
  SearchFilter
} from "@components";
import { ResultPagingResponse, TypeYN, IDBNotice } from "@data";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { states } from "@recoils";
import { adminAPI, commonUtil, styleUtils, utils } from "@utils";
import { NoticeDetailModal } from "./NoticeDetailModal";
import styles from "./style.module.scss";
import { ZOOTOPIA_WEB_URL } from "utils/constant";

export default function NoticePage() {
  const setAlertModal = useSetRecoilState(states.alertState);
  const [searchText, setSearchText] = useState("");
  const [listData, setListData] = useState<ResultPagingResponse<IDBNotice[]>>();
  const [detailData, setDetailData] = useState<IDBNotice>();
  const [isDetailOpen, setIsDetailOpen] = useState(false);

  async function getList({ page = 1 }: { page?: number } = {}) {
    try {
      const { data, success, message } = await adminAPI.notice.apiGetNoticeList(
        {
          page,
          searchtxt: searchText
        }
      );
      if (success) {
        setListData(data);
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다"
        });
      }
    } catch (error) {
      console.error("getList error", error);
    }
  }

  async function getDetail({ no }: { no: number }) {
    try {
      const { data, success, message } =
        await adminAPI.notice.apiGetNoticeDetail({ no });
      if (success) {
        setDetailData(data);
        setIsDetailOpen(true);
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다"
        });
      }
    } catch (error) {
      console.error("getDetail error", error);
    }
  }

  async function saveNotice() {
    try {
      if (!detailData) {
        return;
      }
      if (!detailData.title) {
        setAlertModal({
          isVisible: true,
          title: "제목을 입력해주세요"
        });
      }
      if (!detailData.content) {
        setAlertModal({
          isVisible: true,
          title: "내용을 입력해주세요"
        });
      }

      const { success, message } = await (detailData?.no
        ? adminAPI.notice.apiUpdateNotice({
            ...detailData,
            hide_yn: detailData.hide_yn || "Y"
          })
        : adminAPI.notice.apiCreateNotice({
            ...detailData,
            hide_yn: detailData.hide_yn || "Y"
          }));
      if (success) {
        setDetailData(undefined);
        setIsDetailOpen(false);
        getList();
        setAlertModal({
          isVisible: true,
          title: message || "저장완료",
          button: [
            {
              text: "닫기",
              onClick: () => setAlertModal({ isVisible: false })
            }
          ]
        });
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
          button: [
            {
              text: "닫기",
              onClick: () => setAlertModal({ isVisible: false })
            }
          ]
        });
      }
    } catch (error) {
      console.error("save error", error);
    }
  }

  async function toggleHideYN({
    no,
    hide_yn
  }: {
    no: number;
    hide_yn: TypeYN;
  }) {
    try {
      if (!no) {
        return;
      }

      const { success, message } = await adminAPI.notice.apiUpdateNotice({
        no,
        hide_yn
      });
      if (success) {
        getList();
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
          button: [
            {
              text: "닫기",
              onClick: () => setAlertModal({ isVisible: false })
            }
          ]
        });
      }
    } catch (error) {
      console.error("toggleHideYN error", error);
    }
  }

  async function deleteNotice({ no }: { no: number }) {
    try {
      if (!no) {
        return;
      }
      if (!confirm("공지를 삭제하시겠습니까?")) {
        return;
      }

      const { success, message } = await adminAPI.notice.apiDeleteNotice({
        no
      });
      if (success) {
        setDetailData(undefined);
        setIsDetailOpen(false);
        getList();
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
          button: [
            {
              text: "닫기",
              onClick: () => setAlertModal({ isVisible: false })
            }
          ]
        });
      }
    } catch (error) {
      console.error("deleteNotice error", error);
    }
  }

  useEffect(() => {
    getList();
  }, []);

  return (
    <>
      <ContentLayout title="공지관리">
        <SearchFilter
          left={[
            <InputBox
              key={1}
              value={searchText}
              onValue={setSearchText}
              onEnter={getList}
              rightContent={
                <div style={{ display: "flex" }} onClick={() => getList()}>
                  <FontAwesomeIcon icon={faSearch} />
                </div>
              }
            />
          ]}
          right={[
            <Button
              key={0}
              text="신규등록"
              onClick={() => setIsDetailOpen(true)}
            />
          ]}
        />
        <Content>
          <ListTable paging={listData?.page} getList={getList}>
            <thead>
              <tr>
                <th>웹</th>
                <th>번호</th>
                <th>제목</th>
                <th>조회수</th>
                <th>등록일</th>
                <th>노출여부</th>
              </tr>
            </thead>
            <tbody>
              {(listData?.result || []).map((item, index) => {
                const webLink = `${ZOOTOPIA_WEB_URL}/noticeDetail?k=${item.no}`;
                return (
                  <tr
                    key={index}
                    onClick={() => !!item.no && getDetail({ no: item.no })}
                  >
                    <td onClick={e => utils.handleClickBubble(e)}>
                      <div style={{ display: "inline-block" }}>
                        <Button
                          text="조회"
                          onClick={() => window.open(webLink)}
                          fontSize={12}
                          backgroundColor={styleUtils.COLOR.baige}
                        />
                      </div>
                    </td>
                    <td>{item.no}</td>
                    <td>{item.title}</td>
                    <td>{(item.view_cnt || 0).toLocaleString()}</td>
                    <td>{moment(item.createdAt).format("YYYY-MM-DD")}</td>
                    <td onClick={utils.handleClickBubble}>
                      <CheckBox
                        checked={item.hide_yn === "N"}
                        text={item.hide_yn === "N" ? "노출" : "숨김"}
                        isToggle
                        onChange={e => {
                          if (!item.no) {
                            return;
                          }
                          toggleHideYN({
                            no: item.no,
                            hide_yn: item.hide_yn === "N" ? "Y" : "N"
                          });
                        }}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </ListTable>
        </Content>
      </ContentLayout>
      <NoticeDetailModal
        isVisible={isDetailOpen}
        data={detailData}
        setData={setDetailData}
        save={saveNotice}
        deleteNotice={deleteNotice}
        close={() => {
          setDetailData(undefined);
          setIsDetailOpen(false);
        }}
      />
    </>
  );
}
