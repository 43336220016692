import React, { useEffect, useState } from "react";
import {
  Content,
  ListTable,
  SearchFilter,
  Button,
  FileUploadButton,
  InputBox,
  SelectBox
} from "@components";
import {
  faDownload,
  faFileExcel,
  faPlus,
  faSearch,
  faUpload
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { adminAPI, styleUtils } from "@utils";
import moment from "moment";
import { PriceModal } from "./PriceChangeModal";
import { IPromotionResponse } from "./index";
import { SpareItemModal } from "./SpareItemModal";
import { ShadListTable } from "../../components/ShadListTable";
import { cn } from "@/utils/shadUtils";
import { ItemDetailModal } from "pages/ItemPage/ItemDetailModal";

interface ListPricingViewProps {
  promotions: any[];
  setPromotions: (promotions: any[]) => void;
}

export default function ListPricingTab({
  promotions,
  setPromotions
}: ListPricingViewProps) {
  const [modalMode, setModalMode] = useState<"create" | "edit">("create");
  const [paging, setPaging] = useState<any>();
  const [data, setData] = useState<any>();
  const [itemsPerPage, setItemsPerPage] = useState<number>(50);
  const [searchText, setSearchText] = useState<string>("");
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [selectedDate, setSelectedDate] = useState<moment.Moment>();
  const [selectedData, setSelectedData] = useState<any>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isSpareModalVisible, setIsSpareModalVisible] = useState(false);
  const [selectedSpareItem, setSelectedSpareItem] = useState(null);
  const [isItemDetailModalVisible, setIsItemDetailModalVisible] =
    useState(false);
  const [itemDetailData, setItemDetailData] = useState<any>();

  async function getList({ page = 1 }: { page?: number }) {
    try {
      const result = (await adminAPI.promotion.getPromotionList({
        page: page,
        limit: itemsPerPage,
        query: searchText,
        startDate: startDate,
        endDate: endDate
      })) as unknown as IPromotionResponse;

      if (result?.data) {
        setPromotions(result.data.result);
        setPaging(result.data.page);
        setData(result.data);
      }
    } catch (error) {
      console.error("getList error", error);
    }
  }

  useEffect(() => {
    const startOfMonth = moment().startOf("month").format("YYYY-MM-DD");
    const endOfMonth = moment().endOf("month").format("YYYY-MM-DD");

    setStartDate(startOfMonth);
    setEndDate(endOfMonth);
  }, []);

  useEffect(() => {
    if (startDate && endDate) {
      getList({});
    }
  }, [startDate, endDate]);

  async function handleExcelDownload() {
    await adminAPI.promotion.downloadPromotionExcel();
  }

  const handleSpareClick = (spareItem: any) => {
    setSelectedSpareItem(spareItem);
    setIsSpareModalVisible(true);
  };

  const isCurrentlyActive = (row: any) => {
    // 진행중 배지는 is_active가 Y이고 현재 날짜가 프로모션 기간 내에 있을 때만 표시
    if (row.expired_yn === "Y") {
      return "close";
    }

    // is_active가 N이면 비활성화 상태
    if (row.is_active === "N") {
      return "inactive";
    }

    // 현재 시간이 프로모션 기간 내에 있는지 체크
    const now = moment();
    const startDate = moment(row.start_date);
    const endDate = moment(row.end_date);

    // 시작일이 미래인 경우 '대기중'
    if (now.isBefore(startDate)) {
      return "waiting";
    }

    // 현재 시간이 프로모션 기간 내에 있는 경우 '진행중'
    if (now.isBetween(startDate, endDate, null, "[]")) {
      return "active";
    }

    return "close";
  };

  const isCurrentlyActiveSpare = (row: any) => {
    // 기본 검사: 객체가 없거나 명시적으로 비활성화된 경우
    if (!row || row.expired_yn === "Y") {
      return "close";
    }

    // is_active가 N이면 비활성화 상태
    if (row.active_yn === "N") {
      return "inactive";
    }

    // 명시적으로 활성화된 상태 확인
    if (row.is_available === true) {
      // 날짜 범위도 확인
      const now = moment();
      const startDate = moment(row.start_date);
      const endDate = moment(row.end_date);

      if (!startDate.isValid() || !endDate.isValid()) {
        return "invalid";
      }

      // 시작일이 미래인 경우 '대기중'
      if (now.isBefore(startDate)) {
        return "waiting";
      }

      // 현재 시간이 프로모션 기간 내에 있는 경우 '진행중'
      if (now.isBetween(startDate, endDate, null, "[]")) {
        return "active";
      }
    }

    return "close";
  };

  // 상품 상세 조회 함수 추가
  async function handleItemDetail(itemNo: number) {
    try {
      const { success, message, data } = await adminAPI.item.getDetail({
        no: itemNo
      });
      if (success) {
        setIsItemDetailModalVisible(true);
        setItemDetailData(data);
      } else {
        console.error("상품 조회 실패:", message);
      }
    } catch (error) {
      console.error("상품 조회 에러:", error);
    }
  }

  return (
    <>
      <SearchFilter
        // leftHeaders={["보기수", "시작일", "종료일", "검색"]}
        left={[
          <SelectBox
            key={1}
            list={[
              { label: "30개씩 보기", value: "30" },
              { label: "50개씩 보기", value: "50" },
              { label: "100개씩 보기", value: "100" }
            ]}
            value={itemsPerPage.toString()}
            singleSelect={val => {
              setItemsPerPage(Number(val));
            }}
            placeholder="개수"
            boxStyle={{ width: "140px" }}
          />,
          <InputBox
            key={2}
            type="date"
            value={startDate}
            onValue={value => setStartDate(value)}
            placeholder="시작일"
          />,
          <InputBox
            key={3}
            type="date"
            value={endDate}
            onValue={value => setEndDate(value)}
            placeholder="종료일"
          />,
          <InputBox
            key={4}
            value={searchText}
            onValue={value => setSearchText(value)}
            placeholder={"상품명 검색"}
            onEnter={() => {
              getList({});
            }}
            rightContent={
              <div style={{ display: "flex" }} onClick={() => getList({})}>
                <FontAwesomeIcon icon={faSearch} />
              </div>
            }
          />
        ]}
        // rightHeaders={[]}
        bottom={
          <div className="flex items-center gap-2">
            <Button
              text="신규 입력"
              isMainBtn
              icon={<FontAwesomeIcon icon={faPlus} />}
              onClick={() => {
                setModalMode("create");
                setIsModalVisible(true);
              }}
            />
            <Button
              text="다운로드"
              icon={<FontAwesomeIcon icon={faDownload} />}
              onClick={handleExcelDownload}
            />
            <FileUploadButton
              text="일괄추가"
              callback={() => getList({})}
              type={"excel"}
              uploadUrl={"/admin/excel/promotions"}
            />
          </div>
        }
      />

      <Content>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%"
          }}
        >
          <ShadListTable
            data={flattenPromotionsWithSpares(promotions) || []}
            paging={paging}
            showExport={true}
            exportFileName="promotionsList"
            columns={[
              {
                header: "번호",
                accessorKey: "item.no",
                csvOnly: true,
                cell: row => {
                  const isActive = isCurrentlyActive(row);
                  return (
                    <div
                      className={cn(
                        "py-1",
                        isActive !== "active" && "text-gray-500"
                      )}
                    >
                      {row.item?.no || "-"}
                    </div>
                  );
                },
                csv: row => {
                  return `${row.no || ""}`;
                }
              },
              {
                header: "상품번호",
                accessorKey: "item_no",
                csvOnly: true,
                cell: row => {
                  return `${row.item_no || ""}`;
                }
              },
              {
                header: "상태",
                accessorKey: "is_active",
                csvExclude: true,
                cell: row => {
                  const isActive = isCurrentlyActive(row);
                  return (
                    <div>
                      {isActive === "active" && (
                        <span className="ml-2 px-1.5 py-0.5 text-white text-xs rounded bg-green">
                          진행중
                        </span>
                      )}

                      {isActive === "waiting" && (
                        <span className="ml-2 px-1.5 py-0.5 bg-blue-100 text-blue-700 text-xs rounded">
                          대기중
                        </span>
                      )}

                      {isActive === "inactive" && (
                        <span className="ml-2 px-1.5 py-0.5 bg-gray-100 text-gray-700 text-xs rounded">
                          비활성
                        </span>
                      )}

                      {isActive === "close" && (
                        <span className="ml-2 px-1.5 py-0.5 bg-red-100 text-red-700 text-xs rounded">
                          종료됨
                        </span>
                      )}
                    </div>
                  );
                }
              },
              {
                header: "시작일",
                accessorKey: "start_date",
                cell: row => {
                  const isActive = isCurrentlyActive(row);
                  return (
                    <div
                      className={cn(isActive !== "active" && "text-gray-400")}
                    >
                      {row.start_date}
                    </div>
                  );
                }
              },
              {
                header: "종료일",
                accessorKey: "end_date",
                cell: row => {
                  const isActive = isCurrentlyActive(row);
                  return (
                    <div
                      className={cn(isActive !== "active" && "text-gray-400")}
                    >
                      {row.end_date}
                    </div>
                  );
                }
              },
              {
                header: "업체명",
                accessorKey: "item.partners.partner_name",
                cell: row => {
                  return `${row.item?.partners?.partner_name || ""}`;
                }
              },
              {
                header: "상품명",
                accessorKey: "item.item_name",
                cell: row => {
                  const hasSpare = !!row.spare;
                  const isActive = isCurrentlyActive(row);
                  const isSpareActive =
                    hasSpare && isCurrentlyActiveSpare(row.spare);

                  return (
                    <div className="py-1 relative">
                      <div className="flex flex-col space-y-1">
                        {/* 원래 상품 정보 */}
                        <div className="flex items-center">
                          <a
                            href="#"
                            onClick={e => {
                              e.preventDefault();
                              setSelectedData(row);
                              setModalMode("edit");
                              setIsModalVisible(true);
                            }}
                            className={cn(
                              isSpareActive === "active"
                                ? "font-normal text-gray-400"
                                : "font-normal",
                              isActive !== "active" &&
                                isSpareActive !== "active" &&
                                "text-gray-500"
                            )}
                          >
                            {row.item?.item_name}
                          </a>

                          {row.item?.no && (
                            <span
                              className="ml-2 text-xs text-indigo-600 underline cursor-pointer"
                              onClick={e => {
                                e.stopPropagation();
                                handleItemDetail(row.item.no);
                              }}
                            >
                              조회
                            </span>
                          )}
                        </div>

                        {/* 대체 상품 정보 - 미니멀한 디자인 */}
                        {hasSpare && (
                          <div className="flex items-center">
                            <div className="flex items-center pl-1 text-xs text-gray-400">
                              <span
                                className={cn(
                                  "mr-1",
                                  isSpareActive === "active"
                                    ? "text-indigo-600"
                                    : "text-indigo-400"
                                )}
                              >
                                ↳
                              </span>
                              <span className="mr-1">대체:</span>
                              <a
                                href="#"
                                onClick={e => {
                                  e.preventDefault();
                                  handleSpareClick(row.spare);
                                }}
                                className={cn(
                                  isActive === "active"
                                    ? "font-normal text-black"
                                    : "font-normal text-gray-500",
                                  "text-sm"
                                )}
                              >
                                {row.spare?.item?.item_name}
                              </a>

                              {row.spare?.item?.no && (
                                <span
                                  className="ml-2 text-xs text-indigo-600 underline cursor-pointer"
                                  onClick={e => {
                                    e.stopPropagation();
                                    handleItemDetail(row.spare.item.no);
                                  }}
                                >
                                  조회
                                </span>
                              )}

                              {isSpareActive === "active" && (
                                <span className="ml-2 px-1.5 py-0.5 text-white text-xs rounded bg-indigo-600">
                                  대체 진행중
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                }
              },
              {
                header: "변동가격",
                accessorKey: "price",
                cell: row => {
                  const isActive = isCurrentlyActive(row);
                  const hasSpare = !!row.spare;
                  const isSpareActive =
                    hasSpare && isCurrentlyActiveSpare(row.spare);

                  return (
                    <div>
                      <div
                        className={cn(
                          "font-normal",
                          isActive !== "active" && "text-gray-400"
                        )}
                      >
                        {row.promotion_price
                          ? `${row.promotion_price.toLocaleString()}원`
                          : "-"}
                      </div>

                      {hasSpare && (
                        <div
                          className={cn(
                            "text-sm mt-1",
                            isActive === "active"
                              ? "text-indigo-600"
                              : "text-gray-400"
                          )}
                        >
                          대체:{" "}
                          {row.spare?.spare_price
                            ? `${row.spare.spare_price.toLocaleString()}원`
                            : "-"}
                        </div>
                      )}
                    </div>
                  );
                },
                csv: row => {
                  return row.promotion_price
                    ? row.promotion_price.toString()
                    : "-";
                }
              },
              {
                header: "할인율",
                accessorKey: "discount_rate",
                cell: row => {
                  const isActive = isCurrentlyActive(row);
                  const hasSpare = !!row.spare;
                  const isSpareActive =
                    hasSpare && isCurrentlyActiveSpare(row.spare);

                  return (
                    <div>
                      <div
                        className={cn(
                          "font-normal",
                          isActive !== "active" && "text-gray-400"
                        )}
                      >
                        {row.discount_rate !== null &&
                        row.discount_rate !== undefined
                          ? `${row.discount_rate}%`
                          : "-"}
                      </div>

                      {hasSpare && (
                        <div
                          className={cn(
                            "text-sm mt-1",
                            isActive === "active"
                              ? "text-indigo-600"
                              : "text-gray-400"
                          )}
                        >
                          대체:{" "}
                          {row.spare?.discount_rate !== null &&
                          row.spare?.discount_rate !== undefined
                            ? `${row.spare.discount_rate}%`
                            : "-"}
                        </div>
                      )}
                    </div>
                  );
                },
                csv: row => {
                  return row.discount_rate !== null &&
                    row.discount_rate !== undefined
                    ? row.discount_rate.toString()
                    : "-";
                }
              },
              {
                header: "한정수량",
                accessorKey: "limit",
                cell: row => {
                  const isActive = isCurrentlyActive(row);
                  const hasSpare = !!row.spare;
                  const isSpareActive =
                    hasSpare && isCurrentlyActiveSpare(row.spare);

                  return (
                    <div>
                      <div
                        className={cn(isActive !== "active" && "text-gray-400")}
                      >
                        {row.limit_count || "-"}
                      </div>

                      {hasSpare && (
                        <div
                          className={cn(
                            "text-sm mt-1",
                            isActive === "active"
                              ? "text-indigo-600"
                              : "text-gray-400"
                          )}
                        >
                          대체: {row.spare?.spare_limit || "-"}
                        </div>
                      )}
                    </div>
                  );
                },
                csv: row => {
                  return row.limit_count?.toString() || "-";
                }
              },
              {
                header: "만료여부",
                accessorKey: "expired_yn",
                csvOnly: true,
                cell: row => {
                  return `${row.expired_yn || ""}`;
                }
              },
              {
                header: "대체상품적용",
                accessorKey: "is_fixed_event_product_yn",
                csvOnly: true,
                cell: row => {
                  return `${row.is_fixed_event_product_yn || ""}`;
                }
              },
              {
                header: "쿠폰상품적용",
                accessorKey: "is_coupon_product_yn",
                csvOnly: true,
                cell: row => {
                  return `${row.is_coupon_product_yn || ""}`;
                }
              }
            ]}
            getList={getList}
            className="w-full"
          />
        </div>
      </Content>

      <PriceModal
        isVisible={isModalVisible}
        close={() => {
          setIsModalVisible(false);
          setSelectedDate(undefined);
          setSelectedData(undefined);
        }}
        refresh={() => getList({})}
        date={selectedDate}
        data={selectedData}
        mode={modalMode}
      />

      <SpareItemModal
        isVisible={isSpareModalVisible}
        close={() => setIsSpareModalVisible(false)}
        refresh={() => {
          getList({});
        }}
        mode="edit"
        data={selectedSpareItem}
      />

      <ItemDetailModal
        isVisible={isItemDetailModalVisible}
        detailData={itemDetailData}
        close={() => setIsItemDetailModalVisible(false)}
      />
    </>
  );
}

function flattenPromotionsWithSpares(promotions: any[]) {
  // 대체상품을 별도 행으로 추가하지 않고 원래 행에 정보를 포함
  return promotions.map(promotion => ({
    ...promotion,
    hasSpare: !!promotion.spare
  }));
}
