import {Button, InputBox, SelectBox, TextAreaBox} from "@components";
import {faMinus} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {states} from "@recoils";
import {adminAPI, styleUtils} from "@utils";
import {IDBItemsOption, IProductDetailFeedAmount} from "@data";
import _ from "lodash";
import React, {useEffect, useState} from "react";
import {useSetRecoilState} from "recoil";
import styles from "../../style.module.scss";
import {COLOR} from "utils/styleUtils";

interface IProps {
  item_no: number;
  detailData: IProductDetailFeedAmount;
  refresh?: () => void;
  optionList?: IDBItemsOption[];
}

export function FeedAmountItem({
  detailData,
  refresh,
  item_no,
  optionList,
}: IProps) {
  const setAlertModal = useSetRecoilState(states.alertState);
  const [data, setData] = useState<IProductDetailFeedAmount>({
    type: "feedAmount",
    data_json: {
      title: "",
      sub_title: "",
      data: [],
    },
  });

  async function save() {
    try {
      const params = {
        ...{
          ...data,
          data_json: {
            ...data.data_json,
            title: data.data_json?.title || "급여량",
          },
        },
        item_no,
      };
      const {success, message} = detailData.no
        ? await adminAPI.item.updateItemDetail({...params, no: detailData.no})
        : await adminAPI.item.createItemDetail(params);

      setAlertModal({
        isVisible: true,
        title: message || "오류가 발생했습니다.",
      });
      if (success) {
        refresh?.();
      }
    } catch (error) {
      console.error("save error", error);
    }
  }

  useEffect(() => {
    setData({...detailData});
  }, [detailData]);

  return (
    <>
      <div style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}>
        <div className="row">
          <div className="col flex-end">
            <div>
              <Button
                backgroundColor={styleUtils.COLOR.success}
                fontColor={styleUtils.COLOR.white}
                text="저장"
                onClick={save}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col title">부제목</div>
          <div className="col">
            <InputBox
              value={data.data_json?.sub_title}
              onValue={value =>
                setData({
                  ...data,
                  data_json: {...data.data_json, sub_title: value},
                })
              }
            />
          </div>
        </div>
        <div className="row">
          <div className="col title">칼로리</div>
          <div className="col">
            <div style={{width: "100%"}}>
              <div className="row">
                <InputBox
                  value={String(data.data_json?.calorie?.calorie)}
                  type="number"
                  placeholder="칼로리"
                  onValue={value =>
                    setData({
                      ...data,
                      data_json: {
                        ...data.data_json,
                        calorie: {
                          calorie_unit: "kcal",
                          ...data.data_json?.calorie,
                          calorie: Number(value),
                        },
                      },
                    })
                  }
                />
                <SelectBox
                  list={[
                    {value: "kcal", label: "kcal"},
                    {value: "cal", label: "cal"},
                  ]}
                  placeholder="kcal"
                  value={data.data_json?.calorie?.calorie_unit}
                  singleSelect={value =>
                    setData({
                      ...data,
                      data_json: {
                        ...data.data_json,
                        calorie: {
                          ...data.data_json?.calorie,
                          calorie_unit: value,
                        },
                      },
                    })
                  }
                />
              </div>
              <div className="row">
                <InputBox
                  value={data.data_json?.calorie?.amount}
                  placeholder="갯수"
                  onValue={value =>
                    setData({
                      ...data,
                      data_json: {
                        ...data.data_json,
                        calorie: {
                          amount_unit: "개",
                          ...data.data_json?.calorie,
                          amount: value,
                        },
                      },
                    })
                  }
                />
                <SelectBox
                  list={[
                    {value: "개", label: "개"},
                    {value: "팩", label: "팩"},
                    {value: "조각", label: "조각"},
                    {value: "병", label: "병"},
                  ]}
                  placeholder="개"
                  value={data.data_json?.calorie?.amount_unit}
                  singleSelect={value =>
                    setData({
                      ...data,
                      data_json: {
                        ...data.data_json,
                        calorie: {
                          ...data.data_json?.calorie,
                          amount_unit: value,
                        },
                      },
                    })
                  }
                />
              </div>
              <div className="row">
                <InputBox
                  value={String(data.data_json?.calorie?.weight)}
                  type="number"
                  placeholder="중량"
                  onValue={value =>
                    setData({
                      ...data,
                      data_json: {
                        ...data.data_json,
                        calorie: {
                          weight_unit: "g",
                          ...data.data_json?.calorie,
                          weight: Number(value),
                        },
                      },
                    })
                  }
                />
                <SelectBox
                  list={[
                    {value: "g", label: "g"},
                    {value: "kg", label: "kg"},
                    {value: "ml", label: "ml"},
                    {value: "l", label: "l"},
                  ]}
                  placeholder="g"
                  value={data.data_json?.calorie?.weight_unit}
                  singleSelect={value =>
                    setData({
                      ...data,
                      data_json: {
                        ...data.data_json,
                        calorie: {
                          ...data.data_json?.calorie,
                          weight_unit: value,
                        },
                      },
                    })
                  }
                />
              </div>
            </div>
          </div>
        </div>
        {!!data.data_json?.calorie && (
          <div className="row">
            <div className="col title"></div>
            <div className="col">
              <span style={{color: COLOR.orangeSub2}}>
                * 칼로리 :{" "}
                {Number(data.data_json?.calorie?.calorie).toLocaleString()}
                {data.data_json?.calorie?.calorie_unit}
                {!!data.data_json?.calorie?.amount &&
                  ` / ${data.data_json?.calorie?.amount}${data.data_json?.calorie?.amount_unit}`}
                {!!data.data_json?.calorie?.weight &&
                  `(${Number(
                    data.data_json?.calorie?.weight,
                  ).toLocaleString()}${data.data_json?.calorie?.weight_unit})`}
              </span>
            </div>
          </div>
        )}
        <div className="row">
          <div className="col title">부가설명</div>
          <div className="col">
            <TextAreaBox
              value={data.data_json?.discription}
              onValue={value =>
                setData({
                  ...data,
                  data_json: {...data.data_json, discription: value},
                })
              }
            />
          </div>
        </div>
        <div className="row">
          <div className="col title">내용</div>
          <div className="col flex-end">
            <div>
              <Button
                text="추가"
                onClick={() => {
                  setData({
                    ...data,
                    data_json: {
                      ...data.data_json,
                      data: [
                        ...(data.data_json?.data || []),
                        {title: "", content: ""},
                      ],
                    },
                  });
                }}
              />
            </div>
          </div>
        </div>
        {(optionList || []).length > 0 && (
          <div className="row">
            <div className="col title">옵션</div>
            <div className="col">
              <SelectBox
                list={[
                  {option_name: "옵션선택", no: ""},
                  ...(optionList || []),
                ].map(option => ({
                  label: option.option_name || "",
                  value: String(option.no),
                }))}
                value={String(data.data_json?.option_no || "")}
                singleSelect={value =>
                  setData({
                    ...data,
                    data_json: {...data.data_json, option_no: Number(value)},
                  })
                }
              />
            </div>
          </div>
        )}
        {(data.data_json?.data || []).map((item, index) => (
          <div key={index}>
            <div className="row">
              <div className="col title" style={{fontWeight: "400"}}>
                몸무게
              </div>
              <div className="col">
                <InputBox
                  value={item.title}
                  onValue={value =>
                    setData({
                      ...data,
                      data_json: {
                        ...data.data_json,
                        data: (data.data_json?.data || []).map((_item, j) =>
                          index === j ? {..._item, title: value} : _item,
                        ),
                      },
                    })
                  }
                />
              </div>
              <div
                className={styles.minusIcon}
                onClick={() => {
                  if (!confirm("항목을 제거하시겠습니까?")) {
                    return;
                  }
                  setData({
                    ...data,
                    data_json: {
                      ...data.data_json,
                      data: (data.data_json?.data || []).filter(
                        (_item, j) => index !== j,
                      ),
                    },
                  });
                }}>
                <FontAwesomeIcon icon={faMinus} />
              </div>
            </div>
            <div className="row">
              <div className="col title" style={{fontWeight: "400"}}>
                급여량
              </div>
              <div className="col">
                <InputBox
                  value={item.content}
                  onValue={value =>
                    setData({
                      ...data,
                      data_json: {
                        ...data.data_json,
                        data: (data.data_json?.data || []).map((_item, j) =>
                          index === j ? {..._item, content: value} : _item,
                        ),
                      },
                    })
                  }
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}
