import {Button, InputBox, SelectBox} from "@components";
import {states} from "@recoils";
import {adminAPI, styleUtils} from "@utils";
import {IDBItemsOption, IProductDetailComposition} from "@data";
import _ from "lodash";
import React, {useEffect, useState} from "react";
import {useSetRecoilState} from "recoil";
import styles from "../../style.module.scss";

interface IProps {
  item_no: number;
  detailData: IProductDetailComposition;
  refresh?: () => void;
  optionList?: IDBItemsOption[];
}

export function CompositionItem({
  detailData,
  refresh,
  item_no,
  optionList,
}: IProps) {
  const setAlertModal = useSetRecoilState(states.alertState);
  const [data, setData] = useState<IProductDetailComposition>({
    type: "composition",
    data_json: {
      title: "",
      sub_title: "",
      data: [],
    },
  });

  async function save() {
    try {
      const params = {
        ...{
          ...data,
          data_json: {
            ...data.data_json,
            title: data.data_json?.title || "영양 성분 정보",
          },
        },
        item_no,
      };
      const {success, message} = detailData.no
        ? await adminAPI.item.updateItemDetail({...params, no: detailData.no})
        : await adminAPI.item.createItemDetail(params);

      if (success) {
        refresh?.();
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다.",
        });
      }
    } catch (error) {
      console.error("save error", error);
    }
  }

  useEffect(() => {
    setData({...detailData});
  }, [detailData]);

  return (
    <>
      <div style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}>
        <div className="row">
          <div className="col flex-end">
            <div>
              <Button
                backgroundColor={styleUtils.COLOR.success}
                fontColor={styleUtils.COLOR.white}
                text="저장"
                onClick={save}
              />
            </div>
          </div>
        </div>
        {/* <div className="row">
          <div className="col title">제목</div>
          <div className="col">
            <InputBox
              value={data.data_json.title}
              onValue={value =>
                setData({
                  ...data,
                  data_json: {...data.data_json, title: value},
                })
              }
            />
          </div>
        </div> */}
        <div className="row">
          <div className="col title">부제목</div>
          <div className="col">
            <InputBox
              value={data.data_json?.sub_title}
              onValue={value =>
                setData({
                  ...data,
                  data_json: {...data.data_json, sub_title: value},
                })
              }
            />
          </div>
        </div>
        {(optionList || []).length > 0 && (
          <div className="row">
            <div className="col title">옵션</div>
            <div className="col">
              <SelectBox
                list={[
                  {option_name: "옵션선택", no: ""},
                  ...(optionList || []),
                ].map(option => ({
                  label: option.option_name || "",
                  value: String(option.no),
                }))}
                value={String(data.data_json?.option_no || "")}
                singleSelect={value =>
                  setData({
                    ...data,
                    data_json: {...data.data_json, option_no: Number(value)},
                  })
                }
              />
            </div>
          </div>
        )}
        {(data.data_json?.data || []).map((item, index) => (
          <div key={index}>
            <div className="row">
              <div className="col title" style={{fontWeight: "400"}}>
                {item.title}
              </div>
              <div className="col">
                <InputBox
                  value={item.content}
                  onValue={value =>
                    setData({
                      ...data,
                      data_json: {
                        ...data.data_json,
                        data: (data.data_json?.data || []).map((_item, j) =>
                          index === j ? {..._item, content: value} : _item,
                        ),
                      },
                    })
                  }
                  rightContent={<>%</>}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}
