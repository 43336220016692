import {
  Button,
  Modal,
  ModalContent,
  ModalFooter,
  InputBox,
  ImageCropModal
} from "@components";
import { IContentDetailResponse } from "@data";
import { states } from "@recoils";
import { adminAPI, axiosUtil } from "@utils";
import React, { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { CKEditor, CKEditorContext } from "@ckeditor/ckeditor5-react";
import {
  ClassicEditor,
  Essentials,
  Paragraph,
  Bold,
  Italic,
  Context,
  ContextWatchdog,
  Image,
  ImageCaption,
  ImageStyle,
  ImageToolbar,
  ImageUpload,
  ImageInsertUI,
  ImageUploadUI,
  Markdown,
  Underline,
  Alignment,
  ImageResize,
  BlockQuote,
  Indent,
  Link,
  List,
  ListProperties,
  ImageResizeEditing,
  ImageResizeHandles,
  CKBoxImageEdit,
  CKBox
} from "ckeditor5";
import "ckeditor5/ckeditor5.css";
import { ContentPreviewModal } from "./ContentPreviewModal";
import Resizer from "react-image-file-resizer";

interface IProps {
  isVisible: boolean;
  content_no?: number;
  close?: () => void;
  refresh?: () => void;
}

export function ContentViewModal({
  isVisible,
  content_no,
  close,
  refresh
}: IProps) {
  const setAlertModal = useSetRecoilState(states.alertState);
  //템플릿 리스트 검색, 리스트, 상세
  const [contentDetail, setContentDetail] = useState<IContentDetailResponse>();
  const [isOpenPreviewModal, setIsOpenPreviewModal] = useState(false);
  const [contentData, setContentData] = useState<{
    no?: number;
    title?: string;
    contentList?: {
      no?: number;
      title?: string;
      answer?: string;
    }[];
  }>();
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [showCropModal, setShowCropModal] = useState(false);
  const [loaderResolver, setLoaderResolver] = useState<any>(null);
  const [selectedImageUrl, setSelectedImageUrl] = useState<string | null>(null);
  const [activeEditorInstance, setActiveEditorInstance] = useState<any>(null);
  const [selectedEditorIndex, setSelectedEditorIndex] = useState<number>(-1);

  async function updateContentData() {
    if (!contentData) return;

    try {
      const { data, success, message } =
        await adminAPI.content.updateContentDetail(contentData);
      if (success) {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다"
        });
        refresh?.();
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다"
        });
      }
    } catch (error) {
      console.error("updateContentData error", error);
      setAlertModal({
        isVisible: true,
        title: "오류가 발생했습니다"
      });
    }
  }

  async function getContentDetail() {
    await setContentData(undefined);
    try {
      const { data, success, message } =
        await adminAPI.content.getContentDetail({
          no: content_no
        });
      if (success) {
        setContentDetail(data);
      }
    } catch (error) {
      console.error("getData error", error);
    }
  }

  const resizeFile = (file: any) =>
    new Promise(resolve => {
      const maxPixel = 1000;
      const resizeWidth = file.width >= maxPixel ? maxPixel : file.width;
      const resizeHeight =
        file.width >= maxPixel
          ? (file.height / file.width) * maxPixel
          : file.height;

      Resizer.imageFileResizer(
        file,
        resizeWidth,
        resizeHeight,
        "JPEG",
        50,
        0,
        async uri => {
          /* resize new image with url*/
          await resolve(uri);
        },
        "file" /* output Type */ //"blob"으로 정의할 수 있다.
      );
    });

  const customUploadAdapter = (loader: any) => {
    return {
      upload() {
        return new Promise((resolve, reject) => {
          loader.file.then(async (file: any) => {
            console.log("filefilefile", file);
            // 이미지 파일을 상태에 저장하고 크롭 모달 표시
            setImageFile(file);
            setShowCropModal(true);

            // Promise resolver를 저장하여 크롭 완료 후 호출
            setLoaderResolver({ resolve, reject });
          });
        });
      },
      abort() {
        console.log("Upload aborted");
      }
    };
  };

  // 크롭된 이미지 처리 함수
  const handleCroppedImage = async (croppedFile: File) => {
    try {
      setShowCropModal(false);
      const resizedFile = await resizeFile(croppedFile);

      // 새 이미지 업로드
      const files: File[] = [resizedFile as File];
      const response = await axiosUtil.imageUpload({
        files,
        endPoint: "/admin/utils/uploadContentsImg"
      });

      console.log("이미지 업로드 응답:", response);

      // 업로드 로직 또는 이미지 교체 로직
      if (loaderResolver && response && response.length > 0) {
        // 신규 업로드인 경우
        console.log("신규 이미지 업로드 완료:", response[0]);
        loaderResolver.resolve({
          default: response[0]
        });
        setLoaderResolver(null);
      } else if (selectedImageUrl && response && response.length > 0) {
        // 기존 이미지 교체인 경우 현재 활성화된 에디터 찾기
        console.log(
          "기존 이미지 교체 시도:",
          selectedImageUrl,
          "->",
          response[0]
        );
        console.log("선택된 에디터 인덱스:", selectedEditorIndex);

        const activeEditor = getActiveEditor();
        if (activeEditor) {
          console.log("활성화된 에디터 인스턴스 존재");
          // 에디터 인스턴스가 있는 경우 이미지 교체
          const result = replaceImage(
            activeEditor,
            selectedImageUrl,
            response[0]
          );
          console.log("이미지 교체 결과:", result);

          // 컨텐츠 데이터도 업데이트
          if (contentData?.contentList && selectedEditorIndex >= 0) {
            const questionItem =
              contentDetail?.questions?.[selectedEditorIndex];
            if (questionItem) {
              const currentAnswer =
                contentData.contentList.find(
                  item => item.no === questionItem.no
                )?.answer || "";

              // HTML 내용에서 이미지 URL 교체
              const updatedAnswer = currentAnswer.replace(
                new RegExp(
                  selectedImageUrl.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"),
                  "g"
                ),
                response[0]
              );

              console.log("컨텐츠 데이터 업데이트:", questionItem.no);

              setContentData(prevData => ({
                ...prevData,
                contentList: [
                  ...(prevData?.contentList?.filter(
                    item => item.no !== questionItem.no
                  ) || []),
                  {
                    no: questionItem.no,
                    title: questionItem.title,
                    answer: updatedAnswer
                  }
                ]
              }));
            }
          }
        } else {
          console.log("활성화된 에디터 인스턴스가 없음");
        }
        setSelectedImageUrl(null);
      } else if (loaderResolver) {
        console.log("이미지 업로드 실패: 응답이 없거나 비어있음");
        loaderResolver.reject(new Error("이미지 업로드에 실패했습니다."));
        setLoaderResolver(null);
      }
    } catch (error) {
      console.error("Image upload error", error);
      if (loaderResolver) {
        loaderResolver.reject(error);
        setLoaderResolver(null);
      }
      setSelectedImageUrl(null);
    }
  };

  // 크롭 모달 닫기 함수
  const handleCloseCropModal = () => {
    setShowCropModal(false);
    // 사용자가 크롭을 취소한 경우에도 Promise 처리
    if (loaderResolver) {
      loaderResolver.reject(new Error("사용자가 이미지 크롭을 취소했습니다."));
      setLoaderResolver(null);
    }
  };

  function uploadPlugin(editor: any) {
    editor.plugins.get("FileRepository").createUploadAdapter = (
      loader: any
    ) => {
      return customUploadAdapter(loader);
    };
  }

  // 모든 에디터에 이미지 클릭 이벤트 등록
  const setupImageClickEvents = () => {
    if (!activeEditorInstance) return;

    console.log("모든 에디터에 이미지 클릭 이벤트 등록");

    // 모든 에디터 영역 찾기
    const editorElements = document.querySelectorAll(".ck-editor__editable");
    editorElements.forEach((editorElement, index) => {
      // 에디터 내의 모든 이미지에 클릭 이벤트 추가
      const imageElements = editorElement.querySelectorAll("img");

      // 이전에 등록된 이벤트를 제거하기 위해 새 클래스 사용
      imageElements.forEach(img => {
        // 이전 이벤트 리스너 제거를 위한 새 요소 복제 방식
        const newImg = img.cloneNode(true);
        if (img.parentNode) {
          img.parentNode.replaceChild(newImg, img);
        }

        // 새 이벤트 리스너 등록
        newImg.addEventListener("click", e => {
          e.stopPropagation();

          // 선택된 에디터 인덱스 설정
          setSelectedEditorIndex(index);

          // 기존 선택된 이미지 스타일 제거
          document.querySelectorAll(".selected-image-for-crop").forEach(el => {
            el.classList.remove("selected-image-for-crop");
          });

          // 선택된 이미지 스타일 추가
          const imgElement = e.target as HTMLImageElement;
          imgElement.classList.add("selected-image-for-crop");

          // 선택된 이미지 URL 저장
          setSelectedImageUrl(imgElement.src);
        });
      });
    });
  };

  // 에디터 준비 완료 이벤트 핸들러
  const handleEditorReady = (editor: any) => {
    console.log("Editor is ready to use!", editor);
    setActiveEditorInstance(editor);

    // 약간의 지연 후 이미지 클릭 이벤트 등록 (에디터가 완전히 로드된 후)
    setTimeout(() => {
      setupImageClickEvents();
    }, 500);
  };

  // 에디터 내용 변경 시 이미지 이벤트 다시 등록
  const handleEditorChange = (
    event: any,
    editor: any,
    questionNo: number,
    questionTitle: string
  ) => {
    setContentData(prevData => ({
      ...prevData,
      no: content_no,
      contentList: [
        ...(prevData?.contentList?.filter(item => item.no !== questionNo) ||
          []),
        {
          no: questionNo,
          title: questionTitle,
          answer: editor.getData()
        }
      ]
    }));

    // 내용이 변경되면 이미지 클릭 이벤트 다시 등록 (약간의 지연 후)
    setTimeout(() => {
      setupImageClickEvents();
    }, 300);
  };

  // 현재 선택된 이미지 크롭하기
  const handleCropSelectedImage = () => {
    if (!selectedImageUrl) {
      setAlertModal({
        isVisible: true,
        title: "먼저 이미지를 선택해주세요."
      });
      return;
    }

    // 서버 프록시 API를 통해 이미지 가져오기
    adminAPI.utils
      .proxyImage({
        imageUrl: selectedImageUrl
      })
      .then((response: { data: ArrayBuffer }) => {
        const blob = new Blob([response.data], { type: "image/jpeg" });
        const file = new File([blob], "image.jpg", { type: "image/jpeg" });
        setImageFile(file);
        setShowCropModal(true);
      })
      .catch((error: Error) => {
        console.error("이미지 가져오기 실패:", error);
        setAlertModal({
          isVisible: true,
          title: "이미지 가져오기에 실패했습니다."
        });
      });
  };

  function setClose() {
    close?.();
  }

  useEffect(() => {
    if (content_no) {
      getContentDetail();
    }
  }, [isVisible]);

  useEffect(() => {
    setContentData({
      no: contentDetail?.no,
      title: contentDetail?.title,
      contentList: contentDetail?.questions
    });
  }, [contentDetail]);

  // 이미지 선택 스타일 추가 useEffect
  useEffect(() => {
    // 이미지 선택 스타일 추가
    const style = document.createElement("style");
    style.innerHTML = `
      .selected-image-for-crop {
        outline: 3px solid #2196F3 !important;
        box-shadow: 0 0 10px rgba(33, 150, 243, 0.5) !important;
      }
    `;
    document.head.appendChild(style);

    return () => {
      // 컴포넌트 언마운트 시 스타일 제거
      document.head.removeChild(style);
    };
  }, []);

  const getActiveEditor = () => {
    return activeEditorInstance;
  };

  const replaceImage = (editor: any, oldSrc: string, newSrc: string) => {
    console.log("replaceImage 함수 호출:", oldSrc, "->", newSrc);
    const model = editor.model;
    const imageElements = findImageElementsByUrl(editor, oldSrc);
    console.log("찾은 이미지 요소 수:", imageElements.length);

    if (imageElements.length === 0) {
      // DOM 기반 접근 시도
      try {
        // 에디터 내부의 모든 iframe 찾기
        const editorElement = document.querySelectorAll(".ck-editor__editable")[
          selectedEditorIndex
        ];
        if (editorElement) {
          const images = editorElement.querySelectorAll(
            'img[src="' + oldSrc + '"]'
          );
          console.log("DOM에서 찾은 이미지:", images.length);
          images.forEach(img => {
            (img as HTMLImageElement).src = newSrc;
          });

          // 에디터 데이터 강제 업데이트
          const editorContent = editor.getData();
          editor.setData(
            editorContent.replace(
              new RegExp(oldSrc.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"), "g"),
              newSrc
            )
          );

          return images.length > 0;
        }
      } catch (e) {
        console.error("DOM 접근 방식 실패:", e);
      }
      return false;
    }

    try {
      model.change((writer: any) => {
        imageElements.forEach(imageElement => {
          console.log("이미지 요소 src 속성 변경");
          writer.setAttribute("src", newSrc, imageElement);
        });
      });
      return true;
    } catch (e) {
      console.error("모델 업데이트 실패:", e);
      return false;
    }
  };

  const findImageElementsByUrl = (editor: any, url: string) => {
    console.log("findImageElementsByUrl 호출:", url);
    const results: any[] = [];
    const model = editor.model;

    try {
      // 모델 내의 모든 이미지 요소를 검색
      for (const item of model.document.getRoot().getChildren()) {
        item.getChildren().forEach((child: any) => {
          try {
            if (child.name === "image" && child.getAttribute("src") === url) {
              console.log("모델에서 일치하는 이미지 찾음:", child);
              results.push(child);
            }

            // 이미지가 다른 요소 내에 중첩되어 있는 경우도 확인
            if (child.getChildren) {
              child.getChildren().forEach((nestedChild: any) => {
                if (
                  nestedChild.name === "image" &&
                  nestedChild.getAttribute("src") === url
                ) {
                  console.log("중첩된 이미지 찾음:", nestedChild);
                  results.push(nestedChild);
                }
              });
            }
          } catch (e) {
            console.error("이미지 검색 중 오류:", e);
          }
        });
      }

      // 결과가 없으면 부분 URL 비교 시도
      if (results.length === 0) {
        const urlWithoutQuery = url.split("?")[0];

        for (const item of model.document.getRoot().getChildren()) {
          item.getChildren().forEach((child: any) => {
            try {
              if (child.name === "image") {
                const childSrc = child.getAttribute("src");
                if (childSrc && childSrc.includes(urlWithoutQuery)) {
                  console.log("부분 URL 일치하는 이미지 찾음:", child);
                  results.push(child);
                }
              }
            } catch (e) {
              console.error("부분 URL 비교 중 오류:", e);
            }
          });
        }
      }

      return results;
    } catch (e) {
      console.error("이미지 요소 검색 중 오류 발생:", e);
      return [];
    }
  };

  // 컴포넌트 마운트 후 이미지 클릭 이벤트 설정
  useEffect(() => {
    // 약간의 지연 후 이미지 클릭 이벤트 설정 (컴포넌트 로드 후)
    const timer = setTimeout(() => {
      setupImageClickEvents();
    }, 1000);

    return () => clearTimeout(timer);
  }, [activeEditorInstance, contentDetail]);

  return (
    <>
      <Modal isVisible={isVisible} close={setClose} width={"800px"}>
        <ModalContent>
          <div style={{ marginBottom: 10 }}>
            <div
              className="row flex"
              style={{ justifyContent: "space-between" }}
            >
              <div className="col title">제목</div>
              <div className="col" style={{ justifyContent: "flex-end" }}>
                <img src={contentDetail?.creator_grade_img} width={20} />
                <div>
                  {contentDetail?.creator_name}(
                  {contentDetail?.creator_nickname})
                  {/* {contentDetail?.phone_number} */}
                </div>
              </div>
            </div>
            <div>
              <InputBox
                placeholder="제목을 입력해주세요."
                value={contentData?.title}
                onValue={value => {
                  setContentData({
                    ...contentData,
                    no: content_no,
                    title: value
                  });
                }}
              />
            </div>
          </div>
          <>
            {contentDetail?.questions && contentDetail.questions.length > 0 && (
              <>
                {contentDetail.questions.map((question, index) => {
                  return (
                    <div key={index} style={{ marginBottom: 10 }}>
                      <div
                        style={{
                          fontSize: 15,
                          fontWeight: 700,
                          marginBottom: 14
                        }}
                      >
                        Q{index + 1}. {question.title}
                      </div>
                      <div
                        style={{
                          position: "relative",
                          marginBottom: "10px"
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            marginBottom: "5px"
                          }}
                        >
                          <button
                            onClick={handleCropSelectedImage}
                            disabled={!selectedImageUrl}
                            style={{
                              padding: "4px 8px",
                              backgroundColor: selectedImageUrl
                                ? "#2196F3"
                                : "#f1f1f1",
                              color: selectedImageUrl ? "white" : "#888",
                              border: "1px solid #ddd",
                              borderRadius: "4px",
                              cursor: selectedImageUrl
                                ? "pointer"
                                : "not-allowed"
                            }}
                          >
                            {selectedImageUrl
                              ? "선택된 이미지 크롭"
                              : "이미지를 클릭하여 선택하세요"}
                          </button>
                        </div>
                        <CKEditorContext
                          context={Context}
                          contextWatchdog={ContextWatchdog}
                        >
                          <CKEditor
                            editor={ClassicEditor}
                            data={
                              contentData?.contentList?.find(
                                item => item.no === question.no
                              )?.answer ||
                              question.answer ||
                              ""
                            }
                            config={{
                              licenseKey: "GPL",
                              plugins: [
                                Essentials,
                                Paragraph,
                                Bold,
                                Italic,
                                Underline,
                                Alignment,
                                BlockQuote,
                                Image,
                                ImageUpload,
                                ImageInsertUI,
                                ImageToolbar,
                                ImageCaption,
                                ImageStyle,
                                ImageToolbar,
                                ImageUploadUI,
                                ImageResize,
                                Indent,
                                Link,
                                List,
                                ListProperties,
                                Markdown,
                                ImageResizeEditing,
                                ImageResizeHandles
                              ],
                              toolbar: [
                                "heading",
                                "|",
                                "bold",
                                "italic",
                                "link",
                                "bulletedList",
                                "numberedList",
                                "|",
                                "outdent",
                                "indent",
                                "imageUpload",
                                "blockQuote"
                              ],
                              language: "ko",
                              image: {
                                resizeOptions: [
                                  {
                                    name: "resizeImage:original",
                                    value: null,
                                    label: "Original"
                                  },
                                  {
                                    name: "resizeImage:custom",
                                    label: "Custom",
                                    value: "custom"
                                  },
                                  {
                                    name: "resizeImage:40",
                                    value: "40",
                                    label: "40%"
                                  },
                                  {
                                    name: "resizeImage:60",
                                    value: "60",
                                    label: "60%"
                                  }
                                ],
                                toolbar: [
                                  "imageTextAlternative",
                                  "toggleImageCaption",
                                  "imageStyle:inline",
                                  "imageStyle:block",
                                  "imageStyle:side",
                                  "linkImage",
                                  "resizeImage"
                                ]
                              },
                              extraPlugins: [uploadPlugin]
                            }}
                            onReady={handleEditorReady}
                            onChange={(event, editor) => {
                              handleEditorChange(
                                event,
                                editor,
                                question.no,
                                question.title
                              );
                            }}
                            onBlur={(event, editor) => {
                              console.log("Blur.", editor);
                            }}
                            onFocus={(event, editor) => {
                              console.log("Focus.", editor);
                            }}
                          />
                        </CKEditorContext>
                      </div>
                    </div>
                  );
                })}
              </>
            )}
          </>
        </ModalContent>
        <ModalFooter>
          <div className="row">
            <Button
              text="미리보기"
              onClick={() => setIsOpenPreviewModal(true)}
            />
          </div>
          <div className="row">
            <Button text="저장" onClick={updateContentData} />
            <Button text="취소" onClick={setClose} />
          </div>
        </ModalFooter>
      </Modal>
      <ContentPreviewModal
        isVisible={isOpenPreviewModal}
        onClose={() => setIsOpenPreviewModal(false)}
        contentData={contentData}
      />
      <ImageCropModal
        isVisible={showCropModal}
        imageFile={imageFile}
        onCropComplete={handleCroppedImage}
        onClose={handleCloseCropModal}
      />
    </>
  );
}
