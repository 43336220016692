
import {
  Button,
  ListTable,
  Modal,
  ModalContent,
  ModalFooter,
  ModalTitle,
  InputBox,
  SelectBox
} from "@components";
import { IContentMemberResponse, IDBTemplate, IDBTemplateDetail } from "@data";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { states } from "@recoils";
import { adminAPI, cookieUtil } from "@utils";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import {formatPhoneNumber} from "../../utils/utils";

interface IProps {
  isVisible: boolean;
  close?: () => void;
  refresh?: () => void;
  templateData?: {
    template_no?: number;
    member_no_list?: number[];
  };
  setTemplateData?: (data: {
    template_no?: number;
    member_no_list?: number[];
  }) => void;
}

export function ContentCreateModal({
  isVisible,
  close,
  refresh,
  templateData,
  setTemplateData
}: IProps) {
  const setAlertModal = useSetRecoilState(states.alertState);
  //템플릿 리스트 검색, 리스트, 상세
  const [searchText, setSearchText] = useState("");
  const [templateList, setTemplateList] = useState<IDBTemplate[]>();
  const [templateDetail, setTemplateDetail] = useState<IDBTemplateDetail[]>();

  //작성자 리스트 검색, 리스트
  const [searchCreator, setSearchCreator] = useState("");
  const [memberList, setMemberList] = useState<IContentMemberResponse[]>();
  const [selectMemberList, setSelectMemberList] =
    useState<IContentMemberResponse[]>();

  async function getTemplateList() {
    try {
      const { data, success, message } = await adminAPI.content.getTemplateList(
        {
          page: 1,
          limit: 20,
          searchtxt: searchText
        }
      );
      if (success) {
        setTemplateList(data.templateList);
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
          button: [
            {
              text: "닫기",
              onClick: () => setAlertModal({ isVisible: false })
            }
          ]
        });
      }
    } catch (error) {
      console.error("getData error", error);
    }
  }

  async function getCreatorList() {
    try {
      const { data, success, message } = await adminAPI.content.getCreatorList({
        page: 1,
        limit: 20,
        searchtxt: searchCreator
      });
      if (success) {
        setMemberList(data.memberList);
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다"
        });
      }
    } catch (error) {
      console.error("getData error", error);
    }
  }

  async function getTemplateDetailList(no: number) {
    try {
      const { data, success, message } =
        await adminAPI.content.getTemplateDetailList({
          no
        });
      if (success) {
        setTemplateDetail(data.questions);
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
          button: [
            {
              text: "닫기",
              onClick: () => setAlertModal({ isVisible: false })
            }
          ]
        });
      }
    } catch (error) {
      console.error("getData error", error); // error
    }
  }

  async function createContent() {
    const { data, success, message } = await adminAPI.content.createContent({
      template_no: templateData?.template_no,
      member_no_list: templateData?.member_no_list
    });
    if (success) {
      setClose?.();
      setSelectMemberList(undefined);
      setMemberList(undefined);
      setTemplateData?.({
        template_no: undefined,
        member_no_list: undefined
      });
      refresh?.();
      setAlertModal({
        isVisible: true,
        title: "작성이 완료되었습니다",
        button: [
          {
            text: "닫기",
            onClick: () => setAlertModal({ isVisible: false })
          }
        ]
      });
    } else {
      setAlertModal({
        isVisible: true,
        title: message || "오류가 발생했습니다",
        button: [
          {
            text: "닫기",
            onClick: () => setAlertModal({ isVisible: false })
          }
        ]
      });
    }
  }

  function setClose() {
    close?.();
  }

  useEffect(() => {
    getTemplateList();
  }, []);

  useEffect(() => {
    getTemplateList();
  }, [searchText]);

  useEffect(() => {
    if (templateData?.template_no) {
      getTemplateDetailList(templateData.template_no);
    }
  }, [templateData?.template_no]);

  return (
    <>
      <Modal isVisible={isVisible} close={setClose} width={"800px"}>
        <ModalTitle title={"템플릿선택"} close={setClose} isClose />
        <ModalContent>
          <div className="row">
            <div className="col title">검색</div>
            <div className="col">
              <InputBox
                placeholder="검색"
                rightContent={
                  <div>
                    <FontAwesomeIcon icon={faSearch} />
                  </div>
                }
                value={searchText}
                onValue={setSearchText}
              />
            </div>
          </div>
          <div className="row">
            <div className="col title">선택</div>
            <div className="col">
              <SelectBox
                list={(templateList || []).map(item => ({
                  value: String(item.no),
                  label: String(item.title || "")
                }))}
                value={String(templateData?.template_no)}
                singleSelect={e =>
                  setTemplateData?.({
                    ...templateData,
                    template_no: Number(e)
                  })
                }
              />
            </div>
          </div>
          <div className="row top">
            <div className="col title">내용</div>
            <div>
              {templateDetail?.map((item, index) => {
                return (
                  <div key={index} style={{ paddingBottom: 10 }}>
                    <div className="col">
                      <div style={{ fontWeight: 700 }}>{`Q${index + 1}. `}</div>
                      <div>{item.title}</div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <ModalTitle title={"작성자등록"} close={setClose} isClose />
          <div className="row">
            <div className="col title">검색</div>
            <div className="col">
              <InputBox
                placeholder="닉네임, 이름, 핸드폰 번호를 입력하세요."
                rightContent={
                  <div onClick={() => getCreatorList()}>
                    <FontAwesomeIcon icon={faSearch} />
                  </div>
                }
                value={searchCreator}
                onValue={setSearchCreator}
                onEnter={getCreatorList}
              />
            </div>
          </div>
          <div className="row">
            <div className="col title">선택</div>
            <div className="col">
              <SelectBox
                list={(memberList || []).map(item => ({
                  value: String(item.no),
                  label: String(`${item.name}(${item.nickname})` || "")
                }))}
                singleSelect={e => {
                  const selectedMemberNo = Number(e);
                  const isSelected =
                    templateData?.member_no_list?.includes(selectedMemberNo);

                  if (isSelected) {
                    const updatedMemberNoList =
                      templateData?.member_no_list?.filter(
                        no => no !== selectedMemberNo
                      );
                    setTemplateData?.({
                      ...templateData,
                      member_no_list: updatedMemberNoList
                    });

                    setSelectMemberList(prevList =>
                      prevList?.filter(item => item.no !== selectedMemberNo)
                    );
                  } else {
                    const updatedMemberNoList = [
                      ...(templateData?.member_no_list || []),
                      selectedMemberNo
                    ];
                    setTemplateData?.({
                      ...templateData,
                      member_no_list: updatedMemberNoList
                    });

                    const newSelectedMembers = memberList?.filter(
                      item => item.no === selectedMemberNo
                    );
                    if (newSelectedMembers) {
                      setSelectMemberList(prevList => [
                        ...(prevList || []),
                        ...newSelectedMembers
                      ]);
                    }
                  }
                }}
              />
            </div>
          </div>
          <ListTable paging={undefined}>
            <thead>
              <tr>
                <th>번호</th>
                <th>이름</th>
                <th>닉네임</th>
                <th>핸드폰번호</th>
                <th>회원등급</th>
                <th>가입일자</th>
              </tr>
            </thead>
            <tbody>
              {(selectMemberList || []).map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{item.no}</td>
                    <td>{item.name}</td>
                    <td>{item.nickname}</td>
                    <td>
                      {item.phone_number &&
                        formatPhoneNumber(item.phone_number)}
                    </td>
                    <td
                      className="row"
                      style={{
                        alignItems: "center",
                        justifyContent: "center"
                      }}
                    >
                      {item.grade}
                      <img src={item.grade_img} width={20} />
                    </td>
                    <td>{moment(item.createdAt).format("YYYY-MM-DD HH:mm")}</td>
                  </tr>
                );
              })}
            </tbody>
          </ListTable>
        </ModalContent>
        <ModalFooter>
          <div className="row"></div>
          <div className="row">
            <Button text="저장" onClick={createContent} />
            <Button text="취소" onClick={setClose} />
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
}
