import { CKEditor, CKEditorContext } from "@ckeditor/ckeditor5-react";
import {
  ClassicEditor,
  Essentials,
  Paragraph,
  Bold,
  Italic,
  Context,
  ContextWatchdog,
  Image,
  ImageCaption,
  ImageStyle,
  ImageToolbar,
  ImageUpload,
  ImageInsertUI,
  ImageUploadUI,
  Markdown
} from "ckeditor5";
import { Button, Content, ContentLayout, InputBox } from "@components";
import { IContentDetailResponse } from "@data";
import { states } from "@recoils";
import { adminAPI, axiosUtil, styleUtils } from "@utils";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { ContentPreviewModal } from "../ContentsPage/ContentPreviewModal";
import parse from "html-react-parser";
import { marked } from "marked";
import "./style.modules.scss";

// 가이드라인 모달 컴포넌트
interface GuidelineModalProps {
  isVisible: boolean;
  onClose: () => void;
  guideline: string | null;
  questionTitle: string;
}

const GuidelineModal: React.FC<GuidelineModalProps> = ({
  isVisible,
  onClose,
  guideline,
  questionTitle
}) => {
  if (!isVisible) return null;

  // ESC 키 이벤트 핸들러 추가
  React.useEffect(() => {
    const handleEscKey = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        onClose();
      }
    };

    // 모달이 열려있을 때만 이벤트 리스너 추가
    if (isVisible) {
      window.addEventListener("keydown", handleEscKey);
    }

    // 컴포넌트 언마운트 시 이벤트 리스너 제거
    return () => {
      window.removeEventListener("keydown", handleEscKey);
    };
  }, [isVisible, onClose]);

  const convertMarkdownToHtml = (markdown: string) => {
    try {
      // marked 옵션 설정
      marked.setOptions({
        breaks: true, // 단일 줄바꿈도 <br>로 변환
        gfm: true // GitHub Flavored Markdown 활성화
      });

      return marked(markdown);
    } catch (error) {
      console.error("마크다운 변환 오류:", error);
      return markdown;
    }
  };

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-[1000]"
      onClick={onClose}
    >
      <div
        className="bg-white p-5 rounded-lg w-3/5 max-h-[80%] overflow-auto"
        onClick={e => e.stopPropagation()}
      >
        <div className="flex justify-between items-center mb-4">
          <h3 className="m-0">작성 예시</h3>
          <button
            onClick={onClose}
            className="bg-transparent border-none text-xl cursor-pointer"
          >
            ✕
          </button>
        </div>
        <div className="mb-10">
          <strong>질문: {questionTitle}</strong>
        </div>
        <div
          className="leading-relaxed markdown-content"
          style={{ whiteSpace: "pre-line" }}
        >
          {guideline
            ? parse(convertMarkdownToHtml(guideline))
            : "예시가 없습니다."}
        </div>
        <div className="mt-5 flex justify-end">
          <Button text="닫기" onClick={onClose} />
        </div>
      </div>
    </div>
  );
};

export default function ContentsUserPage() {
  const params = useParams();
  const setAlertModal = useSetRecoilState(states.alertState);
  //템플릿 리스트 검색, 리스트, 상세
  const [contentDetail, setContentDetail] = useState<IContentDetailResponse>();
  const [isOpenPreviewModal, setIsOpenPreviewModal] = useState(false);
  // 가이드라인 모달 상태 관리
  const [guidelineModal, setGuidelineModal] = useState<{
    isVisible: boolean;
    guideline: string | null;
    questionTitle: string;
  }>({
    isVisible: false,
    guideline: null,
    questionTitle: ""
  });
  const [contentData, setContentData] = useState<{
    no?: number;
    title?: string;
    contentList?: {
      no?: number;
      title?: string;
      answer?: string;
    }[];
  }>();

  // 가이드라인 모달 열기 함수
  const openGuidelineModal = (
    guideline: string | null,
    questionTitle: string
  ) => {
    setGuidelineModal({
      isVisible: true,
      guideline,
      questionTitle
    });
  };

  // 가이드라인 모달 닫기 함수
  const closeGuidelineModal = () => {
    setGuidelineModal({
      ...guidelineModal,
      isVisible: false
    });
  };

  async function updateContentData({ status }: { status: string }) {
    if (!contentData) return;

    try {
      const { data, success, message } =
        status === "editing"
          ? await adminAPI.content.updateContentByUser(contentData)
          : await adminAPI.content.submitContentByUser(contentData);
      if (success) {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다"
        });
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다"
        });
      }
    } catch (error) {
      console.error("updateContentData error", error);
      setAlertModal({
        isVisible: true,
        title: "오류가 발생했습니다"
      });
    }
  }

  async function getContentDetail() {
    await setContentData(undefined);
    try {
      const { data, success, message } =
        await adminAPI.content.getContentUserDetail({
          id: params.id
        });
      if (success) {
        setContentDetail(data);
      }
    } catch (error) {
      console.error("getData error", error);
    }
  }

  const customUploadAdapter = (loader: any) => {
    return {
      upload() {
        return new Promise((resolve, reject) => {
          loader.file.then((file: any) => {
            const files = [file];
            axiosUtil
              .imageUpload({
                files,
                endPoint: "/admin/utils/uploadContentsImg"
              })
              .then((response: any) => {
                resolve({
                  default: response[0]
                });
              })
              .catch((error: any) => {
                console.error("Image upload error", error);
                reject(error);
              });
          });
        });
      },
      abort() {
        console.log("Upload aborted");
      }
    };
  };

  function uploadPlugin(editor: any) {
    editor.plugins.get("FileRepository").createUploadAdapter = (
      loader: any
    ) => {
      return customUploadAdapter(loader);
    };
  }

  useEffect(() => {
    setContentData({
      no: contentDetail?.no,
      title: contentDetail?.title,
      contentList: contentDetail?.questions
    });
  }, [contentDetail]);

  useEffect(() => {
    getContentDetail();
  }, []);

  return (
    <>
      <ContentLayout buttons={[]}>
        <Content>
          <div className="tailwind md:px-[20%] flex flex-col w-full relative overflow-scroll">
            <div className="tailwind mb-2.5">
              <div className="tailwind flex justify-between">
                <div className="tailwind col title">제목</div>
                <div className="tailwind col flex justify-end">
                  <img src={contentDetail?.creator_grade_img} width={20} />
                  <div>
                    {contentDetail?.creator_name}(
                    {contentDetail?.creator_nickname})
                    {/* {contentDetail?.phone_number} */}
                  </div>
                </div>
              </div>
              <div>
                <InputBox
                  placeholder="제목을 입력해주세요."
                  value={contentData?.title}
                  onValue={value => {
                    setContentData({
                      ...contentData,
                      title: value
                    });
                  }}
                />
              </div>
            </div>
            {contentDetail?.questions?.map((question, index) => {
              // 타입 안전을 위해 guideline 속성 확인
              const guideline = (question as any).guideline || null;

              return (
                <div key={index} className="mb-2.5">
                  <div className="text-base font-bold mb-3.5 flex flex-col md:flex-row justify-between items-center">
                    <div>
                      Q{index + 1}. {question.title}
                    </div>
                    <div className="mt-2 md:mt-0">
                      <Button
                        text="예시보기"
                        backgroundColor={styleUtils.COLOR.white}
                        fontColor={styleUtils.COLOR.black}
                        onClick={() =>
                          openGuidelineModal(guideline, question.title)
                        }
                      />
                    </div>
                  </div>
                  <CKEditorContext
                    context={Context}
                    contextWatchdog={ContextWatchdog}
                  >
                    <CKEditor
                      editor={ClassicEditor}
                      data={
                        contentData?.contentList?.find(
                          item => item.no === question.no
                        )?.answer ||
                        question.answer ||
                        ""
                      }
                      config={{
                        licenseKey: "GPL", // Or 'GPL'.
                        plugins: [
                          Essentials,
                          Paragraph,
                          Bold,
                          Italic,
                          Image,
                          ImageUpload,
                          ImageInsertUI,
                          ImageCaption,
                          ImageStyle,
                          ImageToolbar,
                          ImageUploadUI,
                          Markdown
                        ],
                        // toolbar: ["bold", "italic", "|", "formatPainter"],
                        toolbar: [
                          "alignment",
                          "heading",
                          "|",
                          "bold",
                          "italic",
                          "link",
                          "bulletedList",
                          "numberedList",
                          "|",
                          "indent",
                          "outdent",
                          "|",
                          "imageUpload",
                          "blockQuote"
                        ],
                        language: "ko",
                        image: {
                          toolbar: [
                            "imageTextAlternative",
                            "toggleImageCaption",
                            "imageStyle:inline",
                            "imageStyle:block",
                            "imageStyle:side",
                            "linkImage"
                          ]
                        },
                        extraPlugins: [uploadPlugin]
                      }}
                      onReady={editor => {
                        // You can store the "editor" and use when it is needed.
                        console.log("Editor is ready to use!", editor);
                      }}
                      onChange={(event, editor) => {
                        setContentData(prevData => ({
                          ...prevData,
                          contentList: [
                            ...(prevData?.contentList?.filter(
                              item => item.no !== question.no
                            ) || []),
                            {
                              no: question.no,
                              title: question.title,
                              answer: editor.getData()
                            }
                          ]
                        }));
                      }}
                      onBlur={(event, editor) => {
                        console.log("Blur.", editor);
                      }}
                      onFocus={(event, editor) => {
                        console.log("Focus.", editor);
                      }}
                    />
                  </CKEditorContext>
                </div>
              );
            })}
            <div className="flex justify-between">
              <div>
                <Button
                  text="미리보기"
                  onClick={() => setIsOpenPreviewModal(true)}
                />
              </div>
              <div>
                <div className="flex">
                  <Button
                    text="임시저장"
                    onClick={() => updateContentData({ status: "editing" })}
                  />

                  <Button
                    text="제출"
                    backgroundColor={styleUtils.COLOR.blue}
                    fontColor={styleUtils.COLOR.white}
                    onClick={() => updateContentData({ status: "done" })}
                  />
                </div>
              </div>
            </div>
          </div>
          <ContentPreviewModal
            isVisible={isOpenPreviewModal}
            onClose={() => setIsOpenPreviewModal(false)}
            contentData={contentData}
          />
          <GuidelineModal
            isVisible={guidelineModal.isVisible}
            onClose={closeGuidelineModal}
            guideline={guidelineModal.guideline}
            questionTitle={guidelineModal.questionTitle}
          />
        </Content>
      </ContentLayout>
    </>
  );
}
